<template>
  <div class="box3 xiangqing messageInfo information scroll">
    <div class="box3-div1" ref="top_box" v-if="topicType != 2">
      <div class="box3-div1-top">
        <div style="display: flex">
          <img src="../../assets/img/views/monitoringScheme/quanquan.png" alt />
          事件概况
          <div class="bianji" @click="editText" v-if="textShow == true">
            <img src="../../assets/img/views/monitoringScheme/bianji.png" alt />
            编辑
          </div>
        </div>
        <div style="float: right" v-if="!textShow">
          <el-button style="width: 80px; height: 36px" type="primary" plain @click="cancel">取消</el-button>
          <el-button style="width: 80px; height: 36px" type="primary" @click="addPrese">保存</el-button>
        </div>
      </div>
      <div class="box3-div1-center">
        <div v-if="textShow == true" v-html="incidentOverview"></div>
        <el-input v-if="textShow == false" type="textarea" :rows="6" placeholder="请输入内容" v-model="textarea"></el-input>
      </div>
    </div>
    <ul class="box3-tag">
      <li v-for="(item, inx) in tag" :key="inx" @click="jump(item)" :class="num == item ? 'Selectli' : 'NoSelect'">
        {{ item }}
      </li>
    </ul>
    <div v-if="num == '全部信息'" class="box3-div4">
      <fullDetail :id="id" ref="fullDetail" :divHeight="divHeight" :topicData="topicData" :publicType="publicType">
      </fullDetail>
    </div>
    <div v-else class="box3-div5">
      <div class="box3-div5-media" ref="viewPoint_div">
        <div class="box3-div5-media-top">
          <img src="../../assets/img/views/monitoringScheme/quanquan.png" alt />
          全部评论
        </div>
        <div style="padding: 0 40px 0 40px">
          <div class="box3-div5-media-top-tag">
            <div style="width: 100%">
              <div class="box3-div4-infor-checkbox">
                <span>抽样选择</span>
                <el-radio-group v-model="radio" style="margin-top: 10px; line-height: 20px"
                  v-if="oldSampling.length != 0" @change="handelChange">
                  <el-radio label="全部">全部</el-radio>
                  <el-radio v-for="item in cities4" :label="item" :key="item.sampleId">
                    {{
      item.createTime +
      " " +
      item.remark +
      "(" +
      item.sampleName +
      ")"
    }}
                  </el-radio>
                </el-radio-group>

                <span v-else style="color: #cccccc">暂未抽样</span>
              </div>
              <div v-loading="loadingLook" v-if="cities4.length != 0 && radio != '全部'" class="box3-div4-infor-checkbox">
                <span>网民观点</span>
                <el-checkbox v-model="checkAll6" @change="handleCheckAllChange6">全部数据</el-checkbox>
                <el-checkbox-group style="max-width: 1240px" v-if="oldSampling.length != 0" v-model="checkedCities4"
                  @change="handleCheckedCitiesChange4">
                  <el-checkbox v-for="item in cities6" :label="item" :key="item.viewpointId">{{ item.viewName
                    }}</el-checkbox>
                </el-checkbox-group>
                <span v-else style="color: #cccccc; margin-right: 0">暂未设置网民观点，</span>
                <span @click="settingLook" class="setting-look" v-if="publicType != 1">新增网民观点</span>
              </div>
              <div class="box3-div4-infor-checkbox">
                <span>媒体环节</span>
                <el-checkbox v-model="checkAll5" @change="handleCheckAllChange5">全选</el-checkbox>
                <el-checkbox-group v-model="checkedCities5" @change="handleCheckedCitiesChange5">
                  <el-checkbox v-for="city in cities5" :label="city" :key="city">{{ city }}</el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="box3-div4-infor-checkbox">
                <span style="letter-spacing: 9px; margin-right: 30px">功 能</span>
                <el-radio-group v-model="radio2">
                  <el-radio label="全部数据">全部数据</el-radio>
                  <el-radio v-for="item in redioList" :key="item.name" :label="item">{{ item.name }}</el-radio>
                </el-radio-group>
              </div>
              <div class="box3-div5-infor-data">
                <div class="box3-div5-infor-seach">
                  <div class="box3-div5-infor-seach-left box3-div5-input">
                    <el-input clearable style="width: 600px" placeholder="关键词模糊搜索" prefix-icon="el-icon-search"
                      v-model="value3"></el-input>
                    <span class="el_font">时间</span>
                    <el-date-picker v-model="timer[0]" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                      placeholder="开始日期">
                    </el-date-picker>
                    -
                    <el-date-picker v-model="timer[1]" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                      placeholder="结束日期">
                    </el-date-picker>
                  </div>
                  <div class="box3-div5-infor-seach-right">
                    <el-button @click="search" type="primary">搜索</el-button>
                    <el-button @click="reset" plain>重置</el-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="chouyangDialog" @click="handelDialog" v-if="publicType != 1">
              <span>
                <img src="../../assets/img/views/add.png" alt />
              </span>
              <span style="margin-left: 5px; font-size: 16px">新增抽样</span>
            </div>
          </div>
        </div>
        <!-- 全部观点 -->
      </div>
      <div class="box3-div5-center">
        <div class="right-contnet-content">
          <div class="right-contnet-content-top" :class="navBarFixed == true ? 'navBarWrap2' : ''">
            <div class="right-contnet-content-top-left">
              <el-checkbox v-model="checkAll8" @change="handleCheckAllChange8">
                <span style="font-size: 16px">全部选中</span>
              </el-checkbox>
              <span class="dataNum">数据量:{{ tablePage.total }}</span>
            </div>
            <div class="right-contnet-content-top-right">
              <el-button @click="exportExcli" class="button-setting export" size="small">
                <span class="button-img">
                  <img style="width: 15px; height: 15px" src="../../assets/img/views/download.png" alt />
                </span>
                <span style="margin-left: 5px">数据导出</span>
              </el-button>
              <el-button :disabled="radio == '全部'" class="button-setting guandian" size="small" @click="netizenLook"
                v-if="publicType != 1">
                <span class="button-img">
                  <img style="width: 15px; height: 15px" src="../../assets/img/views/baogao.png" alt />
                </span>
                <span style="margin-left: 5px">匹配网民观点</span>
              </el-button>
              <el-button @click="samples" :disabled="radio == '全部'" class="button-setting hebing" size="small"
                v-if="publicType != 1">
                <span class="button-img" style="left: 14px">
                  <img style="width: 15px; height: 15px" src="../../assets/img/views/blue.png" alt />
                </span>
                <span>设为突出样本</span>
              </el-button>
              <!-- <el-button class="button-setting hebing" size="small">
                <span class="button-img">
                  <img style="width:15px;height:15px" src="../../assets/img/views/hebing.png" alt />
                </span>
                <span>合并数据</span>
              </el-button>-->
              <el-button @click="deleteList" class="button-setting delete" size="small" v-if="publicType != 1">
                <span class="button-img">
                  <img style="width: 15px; height: 15px" src="../../assets/img/views/shanchu.png" alt />
                </span>
                <span>删除数据</span>
              </el-button>
            </div>
          </div>
          <div v-loading="loadingList">
            <div v-if="cities8.length != 0">
              <div v-for="(item, inx) in cities8" :key="inx" class="right-contnet-content-div">
                <div class="right-contnet-content-div-right">
                  <div class="right-contnet-content-div-title">
                    <div class="right-contnet-content-div-title-left">
                      <div class="checked-div">
                        <el-checkbox-group @change="handleCheckedCitiesChange8($event, item)" v-model="checkedCities8">
                          <el-checkbox :label="item">{{ "" }}</el-checkbox>
                        </el-checkbox-group>
                      </div>
                      <span class="right-contnet-content-div-title-left-span">
                        {{ item.source }}
                      </span>
                      <h1>
                        @{{ item.nickname }}：
                        <span style="font-weight: normal">
                          {{ item.content }}
                        </span>
                      </h1>
                    </div>
                    <div class="right-contnet-content-div-title-right"></div>
                  </div>
                  <div class="right-contnet-contnet-div-contnet">
                    {{ item.mediaLink == null ? "其他" : item.mediaLink }} |
                    <a :href="item.website" target="_blank">{{ item.title }}</a>
                  </div>
                  <div class="right-contnet-content-div-tag">
                    <div>
                      <div>网民观点: {{ item.viewName }}</div>
                    </div>
                    <div>{{ item.commentTime }}</div>
                  </div>
                  <div class="right-contnet-content-div-operation">
                    <div class="right-contnet-content-div-operation-left">
                      <!-- <el-button
                        :disabled="radio == '全部'"
                        style="margin-right: 24px"
                        size="mini"
                        type="primary"
                        @click="settingLook(item)"
                      >
                        <img src="../../assets/img/views/white.png" alt />
                        设为网民观点
                      </el-button>-->
                      <el-button :disabled="radio == '全部' || cities6.length == 0" size="mini" type="primary" plain
                        @click="sample(item)" v-if="publicType != 1">
                        <img src="../../assets/img/views/blue.png" alt />
                        {{ item.isSetSample == 0 ? "设为突出样本" : "已设为突出样本" }}
                      </el-button>
                    </div>
                    <div class="right-contnet-content-div-operation-right">
                      <div @click="copy(item)">
                        <img src="../../assets/img/views/monitoringScheme/fuzhi.png" alt />
                        复制
                      </div>
                      <div @click="deletes(item)" v-if="publicType != 1">
                        <img src="../../assets/img/views/monitoringScheme/shanchu3.png" alt />
                        删除
                      </div>
                    </div>
                  </div>
                  <!-- 跟评列表 -->
                  <div v-for="(it, ind) in item.childList" :key="ind"
                    style="display: flex; width: 100%; margin-top: 4px">
                    <div class="xiangqing-main-left-two">
                      <div style="margin-bottom: 16px">
                        <div class="main-count-box">
                          <span class="main-count-fabu">{{ it.nickname }}</span>
                          <span class="main-count-time">{{ it.commentTime }}</span>
                        </div>
                      </div>
                      <div class="main-count-text-box">
                        <p class="main-count-text">{{ it.content }}</p>
                        <div class="main-count-text-option">
                          <span @click="copy(it)" class="main-count-copy">
                            <img src="../../assets/img/views/fuzhi.png" alt />
                            <span style="margin-left: 4px">复制</span>
                          </span>
                          <span @click="deletes(it)" class="main-count-copy" style="margin-left: 40px"
                            v-if="publicType != 1">
                            <img src="../../assets/img/views/monitoringScheme/shanchu3.png" alt />
                            <span style="margin-left: 4px">删除</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="analyse-container" v-else>
              <div class="analyse-container-img">
                <img src="../../assets/img/views/analyse-img.png" />
                <p>暂无数据</p>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <div class="block">
            <span class="total">
              共{{ tablePage.total }}条, 当前显示第{{
      tablePage.page * tablePage.pageSize - tablePage.pageSize + 1
    }}-{{
        tablePage.page * tablePage.pageSize > tablePage.total
          ? tablePage.total
          : tablePage.page * tablePage.pageSize
      }}条
            </span>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tablePage.page" :page-sizes="tablePage.pageSizes" :page-size="tablePage.pageSize"
              layout="sizes, prev, pager, next,jumper" :total="tablePage.total"></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :before-close="colseData" class="my-dialog" :visible.sync="dialogVisible" :close-on-click-modal="false"
      :close-on-press-escape="false" width="700px">
      <div slot="title" class="dialog-header">
        <span class="chouyang">数据抽样</span>
        <span class="zongNum">评论总数{{ total }}</span>
      </div>
      <div class="dialog-body">
        <div class="dialog-body-cont">
          <div class="dialog-body-cont-left">
            <div style="font-size: 16px; color: #333333">点赞排行前</div>
            <div>
              <!-- <el-select class="seleteNum" v-model="numPaiMing" placeholder="请选择抽样数量"> -->
              <!-- <el-option
                  :label="item.label"
                  :value="item.label"
                  v-for="item in option"
                  :key="item.label"
              ></el-option>-->
              <!-- </el-select> -->
              <el-input clearable v-model="numPaiMing" placeholder="请输入抽样数量"></el-input>
            </div>
            <div style="font-size: 16px; color: #333333">条</div>
          </div>

          <el-button size="medium" type="primary" @click="startData">开始抽样</el-button>
        </div>
        <ul v-loading="loading" v-if="oldSampling.length != 0" class="dialog-ul">
          <li :class="['dialog-ul-li', (index + 1) % 2 != 0 ? 'ul-color' : '']" v-for="(item, index) in oldSampling"
            :key="index">
            <div class="dialog-ul-li-div1">
              {{ item.createTime + item.remark }}
            </div>
            <div class="dialog-ul-li-div2">{{ item.sampleName }}</div>
            <div class="dialog-ul-li-div3" @click="deleteSamp(item)" v-if="publicType != 1">
              删除
            </div>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" plain type="primary" @click="colseData">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 全部观点弹窗 -->
    <allViews ref="allView" @getList="getList"></allViews>
    <!-- 网民观点弹框 -->
    <netizenView ref="netizenView" @newGetList="newGetList"></netizenView>
    <!-- 突出样本 -->
    <highlightSample ref="highlightSample" @getListHight="getListHight"></highlightSample>
    <deleteDialog ref="openDialog" @deleteFn="deleteFn" />
  </div>
</template>

<script>
import deleteDialog from "../../components/deleteDialog";
import fullDetail from "../topics/component/fullDetail";
import allViews from "../topics/component/allViews";
import netizenView from "../topics/component/netizenView";
import highlightSample from "../topics/component/highlightSample";
import {
  getInfo,
  topicSample,
  topicSampleList,
  deleteSamp,
  commentList,
  deleteCommentList,
  addViewList,
  editComment,
  eventOverview,
} from "../../api/topic/topic";
export default {
  name: "information",
  data() {
    return {
      // 控制删除不刷新
      deleteFlag: true,
      // 事件概况盒子高度
      divHeight: "",
      // 控制用户是否点击查询按钮
      isSearch: false,
      loadingLook: false,
      loading: false,
      loadingList: false,
      // 抽样总数
      total: 0,
      radio: "全部",
      redioList: [
        {
          name: "已匹配观点数据",
        },
        {
          name: "未匹配观点数据",
        },
      ],
      //  抽样计数
      numCount: 0,
      dataInfo: [],
      textarea: "",
      numPaiMing: null,
      dialogVisible: false,
      timer: [],
      startTime: "",
      endTime: "",
      viewpointIds: [],
      // surveyText: false
      // 概况显示
      textShow: true,
      tag: ["全部信息"],
      num: sessionStorage.getItem("inforName")
        ? sessionStorage.getItem("inforName")
        : "全部信息",
      checkAll5: false,
      // 关键词模糊搜索
      value3: "",
      checkAll8: false,
      checkedCities8: [],
      // 列表数组
      cities8: [],
      checkedCities4: [],
      cities4: [],
      checkAll6: false,
      checkedCities6: [],
      cities6: [],
      //   全部网民观点
      allcities6: [],
      checkedCities5: [],
      cities5: [
        "电视",
        "纸媒",
        "网站",
        "微博",
        "微信公众号",
        "移动客户端",
        "互动栏目",
        "视频",
      ],
      // 功能
      radio2: "全部数据",
      // 存放抽样过的数据
      oldSampling: [],
      //页数
      tablePage: {
        pageSizes: [10, 20, 30, 40, 50],
        page: 1,
        pageSize: 50,
        total: 0,
      },
      searchList: {},
      navBarFixed: false,
      topicName: sessionStorage.getItem("topicName"),
      checkAgain: false,
      incidentOverview: "",
    };
  },
  props: ["topicId", "id", "topicType", "publicType", "topicData"],
  components: {
    deleteDialog,
    fullDetail,
    allViews,
    netizenView,
    highlightSample,
  },
  methods: {
    // 数据导出
    exportExcli() {
      if (!this.searchList.startTime && this.tablePage.total > 300000) {
        return this.$message.warning("请进行时间筛选后再导出!");
      } else {
        this.searchList.topicId = this.id;
        this.$message.success("正在导出数据！");
        let obj = {
          url: "api/topic/export/comment",
          responseType: "blob",
          data: this.searchList,
          method: "post",
        };
        let textName = `${this.topicName}数据-${this.$store.state.user.name
          }-${this.$calcu.timeCycle(new Date(), "HH：mm")}.xlsx`;
        this.$parent.$parent.downloadFun(obj, textName);
      }
    },
    getList() {
      this.radio = "全部";
      this.addViewList();
    },
    // 搜索
    search() {
      if (this.timer[0] && this.timer[1] && this.timer[0] >= this.timer[1]) {
        this.$message.warning("请选择正确的时间段");
        return;
      }
      if (!this.timer[0] || !this.timer[1]) {
        this.timer = [];
      }
      let obj = {};
      this.tablePage.page = 1;
      let commentMedia = []; //评论媒体
      if (this.radio != "全部") {
        obj.sampleId = this.radio.sampleId;
      }
      //网民观点
      if (this.checkedCities4.length != 0 && this.radio != "全部") {
        this.checkedCities4.forEach((item) => {
          this.viewpointIds.push(item.viewpointId);
        });
        obj.viewpointIds = this.viewpointIds.join(",");
      }
      if (this.value3) {
        obj.content = this.value3;
      }
      if (this.checkedCities5.length != 0 && !this.checkAll5) {
        this.checkedCities5.forEach((item) => {
          commentMedia.push(item);
        });
        commentMedia = commentMedia.join(",");
        obj.mediaLink = commentMedia;
      }
      if (this.radio2 != "全部数据") {
        obj.isSetView = this.radio2.name == "已匹配观点数据" ? 1 : 0;
      }
      if (this.timer != false && this.timer[0] && this.timer[1]) {
        this.startTime = this.timer[0];
        this.endTime = this.timer[1];
        obj.startTime = this.startTime;
        obj.endTime = this.endTime;
      }
      this.commentList(obj);
    },
    // 重置
    reset() {
      this.checkAll5 = false;
      this.tablePage.page = 1;
      this.radio = "全部";
      this.checkedCities5 = [];
      this.radio2 = "全部数据";
      this.viewpointIds = [];
      this.checkedCities4 = [];
      this.value3 = "";
      this.timer = [];
      this.startTime = "";
      this.endTime = "";
      this.searchList = {};
      this.commentList();
    },
    // 获取话题分析详情
    async getInfo() {
      const res = await getInfo({
        topicId: this.id,
      });
      if (res != undefined) {
        this.dataInfo = res.data.data;
      }
      setTimeout(() => {
        this.divHeight = this.$refs.top_box.offsetHeight;
      }, 500);
    },
    // 分页
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.tablePage.page = 1;
      this.commentList();
      let height = Number(this.divHeight) + 140;
      window.scrollTo(0, height);
    },
    handleCurrentChange(val) {
      this.tablePage.page = val;
      this.commentList();
      let height = Number(this.divHeight) + 140;
      window.scrollTo(0, height);
    },
    handleCheckAllChange5(val) {
      this.checkedCities5 = val ? this.cities5 : [];
    },

    handleCheckedCitiesChange5(value) {
      let checkedCount = value.length;
      this.checkAll5 = checkedCount === this.cities5.length;
    },
    // 列表
    async commentList(data) {
      this.viewpointIds = [];
      let obj = {
        topicId: this.id,
      };
      if (data) {
        this.searchList = data;
        this.searchList.topicId = this.id;
        obj = this.searchList;
      } else if (Object.values(this.searchList).length > 0) {
        obj = this.searchList;
      }
      let url = `?pageSize=${this.tablePage.pageSize}&pageNum=${this.tablePage.page}`;

      if (this.deleteFlag) {
        this.loadingList = true;
      }
      this.checkAll8 = false;
      this.checkedCities8 = [];
      const res = await commentList(obj, url);
      if (res.data.rows) {
        res.data.rows.forEach((item) => {
          this.cities6.forEach((it) => {
            if (item.viewpointId) {
              if (it.viewpointId == item.viewpointId) {
                item.viewName = it.viewName;
              }
            }
          });
        });
        this.cities8 = res.data.rows;
        this.tablePage.total = res.data.total;
      }
      this.loadingList = false;
      this.deleteFlag = true;
      if (this.checkAgain) {
        this.search();
        this.checkAgain = false;
      }
    },
    // 复制
    copy(msg) {
      this.$publicFun.copyFun(msg.copys);
    },
    handelChange() {
      this.checkedCities4 = [];
      this.checkAll6 = false;
      this.addViewList();
    },
    // 刷新页面  子传父
    // 网民观点列表
    async addViewList() {
      this.loadingLook = true;
      let data = {
        topicId: this.id,
      };
      if (this.radio !== "全部") {
        // data.totalNum = this.radio.sampleName;
        data.sampleId = this.radio.sampleId;
        const res = await addViewList(data);
        if (res.data.rows) {
          this.cities6 = res.data.rows;
          this.cities8.forEach((item) => {
            this.cities6.forEach((it) => {
              if (item.viewpointId) {
                if (it.viewpointId == item.viewpointId) {
                  item.viewName = it.viewName;
                }
              }
            });
          });
        }
      }
      if (this.radio == "全部") {
        const res = await addViewList(data);
        if (res.data.rows) {
          this.allcities6 = res.data.rows;
          this.cities8.forEach((item) => {
            this.allcities6.forEach((it) => {
              if (item.viewpointId) {
                if (it.viewpointId == item.viewpointId) {
                  item.viewName = it.viewName;
                }
              }
            });
          });
        }
      }
      this.loadingLook = false;
    },
    // 设为网民观点
    settingLook(item) {
      this.$refs.allView.openDialog(
        this.oldSampling,
        this.id,
        this.radio,
        item
      );
    },
    // 设置观点
    netizenLook() {
      if (this.checkedCities8.length == 0) {
        this.$message({
          message: "未选择要设置的评论！",
          type: "warning",
        });
      } else {
        this.$refs.netizenView.openDialog(
          this.oldSampling,
          this.checkedCities8,
          this.radio,
          this.id
        );
      }
    },
    // 单个设为突出样本
    sample(item) {
      if (item.isSetSample == 1) {
        this.$confirm("确认取消突出样本吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            await editComment({
              sampleId: this.radio.sampleId,
              commentId: item.commentId,
              isSetSample: 0,
              viewpointId: item.viewpointId,
            });
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            setTimeout(() => {
              this.commentList();
            }, 1000);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消修改",
            });
          });
      } else {
        this.$refs.highlightSample.openDialog(this.radio, item, 1);
      }
    },
    // 批量设为突出样本
    samples() {
      let list = [];
      let number = [];
      if (this.checkedCities8.length == 0) {
        this.$message({
          message: "请先选择需要设置样本的评论！",
          type: "warning",
        });
      } else {
        this.checkedCities8.forEach((item) => {
          number.push(item.isSetSample);
        });
        if (number.indexOf(1) != -1) {
          this.$message.warning("不能重复设置突出样本");
        } else {
          this.checkedCities8.forEach((item) => {
            list.push(item.commentId);
          });
          this.$refs.highlightSample.openDialog(this.radio, list, 2);
        }
      }
    },
    // 设样本后刷新页面
    getListHight() {
      setTimeout(() => {
        this.commentList();
      }, 1000);
    },
    // 设置观点后刷新
    newGetList() {
      this.addViewList();
      setTimeout(() => {
        this.commentList();
      }, 1000);
    },
    handleCheckedCitiesChange4(value) {
      let checkedCount = value.length;
      this.checkAll6 = checkedCount === this.cities6.length;
    },
    handleCheckAllChange6(val) {
      this.checkedCities4 = val ? this.cities6 : [];
      this.commentList();
    },
    jump(item) {
      this.num = item;
      sessionStorage.setItem("inforName", this.num);
    },
    handleCheckAllChange8(val) {
      this.checkedCities8 = val ? this.cities8 : [];
    },
    handleCheckedCitiesChange8(value) {
      let checkedCount = value.length;
      this.checkAll8 = checkedCount === this.cities8.length;
    },
    // 单个删除
    deletes(item) {
      item.message = "这条数据";
      this.$refs.openDialog.openDialog(item);
    },
    // 多选删除
    deleteList() {
      let dataList = [];
      let obj = {};
      if (this.cities8.length == 0) {
        this.$message.warning("没有可以删除的数据!");
      } else {
        if (this.checkedCities8.length != 0) {
          this.checkedCities8.forEach((item) => {
            dataList.push(item.commentId);
          });
          dataList = dataList.join(",");
          obj.commentId = dataList;
          obj.message = "选中数据";
          this.$refs.openDialog.openDialog(obj);
        } else {
          this.$message.warning("请选择要删除的数据!");
        }
      }
    },
    async deleteFn(item) {
      const res = await deleteCommentList(item.commentId);
      if (res.data.code == 200) {
        this.$message({
          message: "删除成功！",
          type: "success",
        });
      } else {
        this.$message.error("删除失败！");
      }
      this.deleteFlag = false;
      setTimeout(() => {
        this.commentList();
      }, 1000);
      if (this.cities8.length == 1 || this.checkAll8) {
        if (this.tablePage.page > 1) {
          this.tablePage.page = this.tablePage.page - 1;
        }
      }
    },
    // 事件概况编辑
    editText() {
      this.textarea = this.incidentOverview;
      this.textShow = false;
    },
    cancel() {
      this.textShow = true;
    },
    // 事件概况编辑
    async addPrese() {
      let data = {
        topicId: this.id,
      };
      if (this.textarea.trim() == this.incidentOverview) {
        data.updateId = 0;
      } else {
        data.updateId = 1;
        data.earlyWarnAlert = this.textarea.trim();
      }
      await eventOverview(data);
      this.textShow = true;
      this.eventSummary();
    },
    // 新增抽样弹窗
    async handelDialog() {
      this.topicSampleList();
      this.radio = "全部";
      let url = `?pageSize=${this.tablePage.pageSize}&pageNum=${this.tablePage.page}`;
      const datas = await commentList(
        {
          topicId: this.id,
        },
        url
      );
      if (datas.data.total) {
        this.total = datas.data.total;
      }
      this.dialogVisible = true;
    },
    // 取消
    colseData() {
      this.cities4 = this.oldSampling;
      this.numPaiMing = null;
      this.dialogVisible = false;
    },
    // 开始抽样
    async startData() {
      if (!this.numPaiMing) {
        this.$message({
          message: "请输入抽样数量！",
          type: "warning",
        });
        return false;
      }
      if (this.oldSampling.length >= 10) {
        this.$message({
          message: "抽样已上限！",
          type: "warning",
        });
        return false;
      }

      if (this.total < this.numPaiMing) {
        this.$message({
          message: "抽样数量大于评论数量，请重新抽样！",
          type: "warning",
        });
        return false;
      }
      this.loading = true;
      const res = await topicSample({
        remark: "抽样",
        sampleName: Number(this.numPaiMing),
        topicId: this.id,
      });
      if (res.data.code == 200) {
        this.topicSampleList();
        this.$message({
          message: "抽样成功！",
          type: "success",
        });
        this.numPaiMing = null;
      } else {
        this.$message.error("抽样失败！");
      }
      this.loading = false;
    },
    // 抽样列表
    async topicSampleList() {
      this.loading = true;
      const res = await topicSampleList({
        topicId: this.id,
      });
      if (res.data.rows) {
        this.oldSampling = res.data.rows;
        this.cities4 = this.oldSampling;
      }
      this.loading = false;
    },
    // 删除抽样
    async deleteSamp(item) {
      const res = await deleteSamp(item.sampleId);
      if (res.data.code == 200) {
        this.$message({
          message: "删除成功！",
          type: "success",
        });
      } else {
        this.$message.error("删除失败！");
      }
      this.topicSampleList();
    },
    //导航栏固定
    watchScroll() {
      if (this.$refs.viewPoint_div) {
        let height =
          Number(this.divHeight) + 240 + this.$refs.viewPoint_div.offsetHeight;
        var scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        //  当滚动超过 90 时，实现吸顶效果
        if (scrollTop > height) {
          this.navBarFixed = true;
        } else {
          this.navBarFixed = false;
        }
      }
    },
    eventSummary() {
      this.$http({
        url: "api/topic/viewPoint/eventSummary",
        method: "post",
        data: {
          topicId: this.id,
          organId: this.$store.state.user.organizationId,
        },
      }).then((res) => {
        this.incidentOverview = res.data.msg;
      });
    },
  },
  created() {
    if (this.$route.query.messageTap) {
      this.num = this.$route.query.messageTap;
    }
    // 网民观点列表
    this.addViewList();
    if (this.$store.state.user.scope[2] && this.topicType == 1) {
      this.tag.push("评论情况");
    }
    this.commentList();
    this.getInfo();
    this.topicSampleList();
    this.eventSummary();
  },
  mounted() {
    window.addEventListener("scroll", this.watchScroll);
  },
};
</script>

<style lang="scss" scoped>
.box3 {
  .dialog-body-cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dialog-body-cont-left {
      display: flex;
      align-items: center;
    }
  }

  .dialog-ul {
    border: 1px solid #e4e6ec;
    margin-top: 24px;

    .dialog-ul-li {
      padding-left: 24px;
      width: 100%;
      display: flex;

      .dialog-ul-li-div1 {
        padding: 8px 0;
        width: 70%;
      }

      .dialog-ul-li-div2 {
        padding: 8px 0;
        height: 100%;
        text-align: center;
        border-left: 1px solid #e4e6ec;
        border-right: 1px solid #e4e6ec;
        width: 15%;
      }

      .dialog-ul-li-div3 {
        cursor: pointer;
        padding: 8px 0;
        color: #2e59ec;
        text-align: center;
        width: 15%;
      }
    }

    .ul-color {
      background: #f5f6fa;
    }
  }

  .chouyang {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }

  .zongNum {
    margin-left: 8px;
    font-size: 14px;
    color: #666666;
  }

  .box3-div1 {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;

    .box3-div1-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 16px;

      .bianji {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        color: #2e59ec;
        margin-left: 24px;
        cursor: pointer;

        img {
          margin-right: 5px;
        }
      }
    }

    .box3-div1-center {
      padding: 0 44px 32px;

      div {
        width: 100%;
        background: #f9f9fc;
        border-radius: 8px;
        padding: 38px 40px;
        font-size: 14px;
        color: #333333;
        line-height: 28px;
      }
    }
  }

  .box3-tag {
    margin-top: 24px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    font-size: 16px;
    color: #333333;
    margin-bottom: 16px;
    padding: 0 16px;

    .Selectli {
      padding-bottom: 8px;
      margin-right: 40px;
      color: #2e59ec;
      border-bottom: 2px solid #2e59ec;
      cursor: pointer;
      font-weight: bold;
    }

    .NoSelect {
      margin-right: 40px;
      cursor: pointer;
    }
  }

  .box3-div4 {
    .box3-div4-media {
      width: 100%;
      padding-bottom: 25px;
      background: #ffffff;
      border-radius: 4px;
    }
  }

  .box3-div5 {
    .box3-div5-media {
      width: 100%;
      background: #ffffff;
      border-radius: 4px;

      .box3-div5-media-top {
        display: flex;
        align-items: center;
        padding: 24px 16px;
      }

      .box3-div5-media-top-tag {
        position: relative;
        width: 100%;
        border-radius: 4px;
        display: flex;
        font-size: 16px;
        color: #333333;
        margin-bottom: 24px;

        .box3-div4-infor-checkbox {
          border-top: 1px solid #e4e6ec;
          padding: 16px 0;

          .setting-look {
            width: 84px;
            margin-right: 0;
            margin-left: 10px;
            color: #2e59ec;
            cursor: pointer;
          }

          .mediaLook {
            cursor: pointer;
            position: absolute;
            right: 0;
          }

          span {
            width: 60px;
            font-size: 14px;
            color: #999999;
            margin-right: 28px;
          }

          display: flex;
          align-items: center;

          ::v-deep .el-checkbox {
            margin-right: 40px;
            max-width: 100%;

            .el-checkbox__label {
              display: inline-block;
              max-width: 100%;
              word-wrap: break-word;
              white-space: pre-wrap;
              vertical-align: middle;
            }
          }
        }

        .chouyangDialog {
          cursor: pointer;
          color: #2e59ec;
          position: absolute;
          right: 0;
          top: 7%;
        }

        .Selectli {
          line-height: 54px;
          margin-right: 40px;
          color: #2e59ec;
          border-bottom: 2px solid #2e59ec;
          cursor: pointer;
          font-weight: bold;
        }

        .NoSelect {
          line-height: 54px;
          margin-right: 40px;
          cursor: pointer;
        }
      }
    }

    .box3-div5-infor-data {
      border-top: 1px solid #e4e6ec;
      width: 100%;
      background: #ffffff;
      border-radius: 8px;
      padding: 16px 0;

      .el-input--prefix .el-input__inner {
        height: 36px;
      }

      .box3-div5-media-top-tag {
        width: 100%;
        border-radius: 4px;
        display: flex;
        font-size: 16px;
        color: #333333;
        margin-bottom: 24px;

        .Selectli {
          margin-top: 16px;
          margin-right: 40px;
          color: #2e59ec;
          border-bottom: 2px solid #2e59ec;
          cursor: pointer;
          font-weight: bold;
          padding-bottom: 14px;
        }

        .NoSelect {
          margin-top: 16px;
          margin-right: 40px;
          cursor: pointer;
          padding-bottom: 14px;
        }
      }

      .box3-div5-infor-seach {
        display: flex;
        justify-content: space-between;

        .box3-div5-infor-seach-left {
          display: flex;
          align-items: center;

          .el_font {
            margin-left: 32px;
            margin-right: 12px;
            font-size: 14px;
            font-weight: bold;
            color: #333333;
          }
        }

        .box3-div5-infor-seach-right {
          ::v-deep .el-button {
            width: 60px;
            height: 32px;
            border-radius: 4px;
            padding: 0;
          }
        }
      }
    }

    .box3-div5-center {
      margin-top: 16px;
      width: 100%;
      background: #ffffff;
      border-radius: 4px;
      padding-bottom: 20px;

      .right-contnet-content-top {
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e4e6ec;
        padding: 26.5px 40px;

        .dataNum {
          margin-left: 31px;
          font-size: 16px;
          color: #999999;
        }

        .right-contnet-content-top-right {
          display: flex;
          justify-content: center;
          align-items: center;

          .button-setting {
            position: relative;
            font-size: 14px;

            .button-img {
              position: absolute;
              top: 23%;
              left: 16%;
            }
          }

          .export {
            height: 32px;
            padding: 0 16px 0 35px;
            border: 1px solid #9cb0f6;
            color: #2e59ec;
          }

          .guandian {
            color: #333333;
            height: 32px;
            padding: 0 16px 0 35px;
            border: 1px solid #a3a3a3;
          }

          .guandian:focus,
          .guandian:hover {
            background-color: #cccccc;
          }

          .hebing {
            color: #2e59ec;
            height: 32px;
            padding: 0 16px 0 35px;
            border: 1px solid #2e59ec;
          }

          .hebing:focus,
          .hebing:hover {
            background-color: rgb(194, 205, 241);
          }

          .delete {
            color: #ea3342;
            height: 32px;
            padding: 0 16px 0 35px;
            border: 1px solid #f7969e;
          }

          .delete:focus,
          .delete:hover {
            background-color: rgb(233, 170, 176);
          }
        }
      }

      .right-contnet-content {
        .analyse-container {
          width: 100%;
          height: 240px;
          background: #ffffff;
          border-radius: 4px;
          margin-top: 16px;
          text-align: center;
        }

        .analyse-container-img {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }

        .analyse-container-img p {
          font-size: 14px;
          color: #999999;
        }

        .right-contnet-content-div {
          width: 100%;
          padding: 20px 40px;
          border-bottom: 1px solid #e4e6ec;
          display: flex;

          .right-contnet-content-div-right {
            width: 100%;

            .xiangqing-main-left-two {
              width: 100%;
              background: #f7f7f7;
              padding: 24px;
            }

            .main-count-text-option {
              display: flex;
            }

            .main-count-copy {
              color: #333333;
              font-size: 14px;
              display: flex;
              align-items: center;
              cursor: pointer;
            }

            .main-count-text-box {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .main-count-box {
              width: 100%;
              display: flex;
              justify-content: space-between;
            }

            .main-count-fabu {
              margin-right: 16px;
              font-size: 16px;
              font-weight: bold;
              color: #333333;
            }

            .main-count-time {
              font-size: 14px;
              color: #999999;
            }

            .main-count-text {
              width: 85%;
              font-size: 14px;
              color: #333333;
              line-height: 20px;
            }

            .right-contnet-content-div-title {
              display: flex;
              justify-content: space-between;

              .right-contnet-content-div-title-left {
                display: flex;
                align-items: center;

                .checked-div {
                  margin-right: 10px;
                }

                .right-contnet-content-div-title-left-span {
                  background: #36cbcb;
                  font-size: 14px;
                  font-weight: bold;
                  color: #ffffff;
                  text-align: center;
                  padding: 2px 8px;
                }

                p {
                  padding: 2px 8px;
                  font-weight: bold;
                  background: #ffedef;
                  font-size: 14px;
                  color: #ea3342;
                  margin-left: 8px;
                }

                h1 {
                  max-width: 1370px;
                  font-size: 14px;
                  font-weight: bold;
                  color: #333333;
                  margin-left: 16px;
                  line-height: 25px;
                }
              }

              .right-contnet-content-div-title-right {
                display: flex;
                align-items: center;
              }
            }

            .right-contnet-content-div-content {
              width: 100%;
              font-size: 14px;
              color: #666666;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              margin: 16px 0;
              line-height: 20px;
            }

            .right-contnet-content-div-tag {
              display: flex;
              align-items: center;
              justify-content: space-between;

              div {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #b8bcc3;

                div {
                  margin-right: 24px;
                }
              }
            }

            .right-contnet-content-div-operation {
              display: flex;
              justify-content: space-between;
              margin-top: 16px;
              margin-bottom: 24px;

              .right-contnet-content-div-operation-right {
                display: flex;
                align-items: center;

                div {
                  display: flex;
                  align-items: center;
                  margin-left: 40px;
                  font-size: 14px;
                  color: #333333;
                  cursor: pointer;

                  img {
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.right-contnet-contnet-div-contnet {
  margin: 16px 0;
  font-size: 14px;
  color: #666;
  line-height: 20px;
}

.el-radio-group {
  margin-right: 20px;
}

.navBarWrap2 {
  background-color: #fff;
  position: fixed;
  top: 136px;
  z-index: 111;
  width: 85.5%;
}
</style>
