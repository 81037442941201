<template>
  <div class="box3 xiangqing fullDetail scroll" ref="aMao">
    <div class="box3-div4-infor">
      <div class="box3-div4-infor-checkbox">
        <span style="margin-right: 5px; margin-top: 8px">
          <img src="../../../assets/img/views/topqq.png" alt />
        </span>
        <span style="font-size: 16px; color: #333333">全部信息</span>
      </div>
      <div v-if="$route.query.topicType != 2">
        <div class="box3-div4-infor-checkbox">
          <span>媒体观点</span>
          <el-checkbox v-model="checkAll2" @change="handleCheckAllChange2">全部数据</el-checkbox>
          <el-checkbox-group v-model="checkedCities2" @change="handleCheckedCitiesChange2">
            <el-checkbox v-for="city in cities2" :label="city" :key="city.mediaViewId">{{ city.viewName }}</el-checkbox>
          </el-checkbox-group>
          <div @click="addMedia" class="mediaLook" v-if="publicType != 1">
            <span style="margin-right: 10px">
              <img src="../../../assets/img/views/add.png" alt />
            </span>
            <span style="font-size: 14px; color: #2e59ec">添加媒体观点</span>
          </div>
        </div>
        <div class="box3-div4-infor-checkbox">
          <span>媒体环节</span>
          <el-checkbox v-model="checkAll3" @change="handleCheckAllChange3">全选</el-checkbox>
          <el-checkbox-group v-model="checkedCities3" @change="handleCheckedCitiesChange3">
            <el-checkbox v-for="city in cities3" :label="city" :key="city">
              {{ city }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="box3-div4-infor-checkbox">
          <span>媒体属性</span>
          <el-checkbox v-model="checkAll4" @change="handleCheckAllChange4">全选</el-checkbox>
          <el-checkbox-group v-model="checkedCities4" @change="handleCheckedCitiesChange4">
            <el-checkbox v-for="city in cities4" :label="city" :key="city">
              {{ city }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="box3-div4-infor-checkbox">
          <span>媒体级别</span>
          <el-checkbox v-model="checkAll5" @change="handleCheckAllChange5">全选</el-checkbox>
          <el-checkbox-group v-model="checkedCities5" @change="handleCheckedCitiesChange5">
            <el-checkbox v-for="city in cities5" :label="city" :key="city">
              {{ city }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div v-if="$route.query.topicType == 2">
        <div class="box3-div4-infor-checkbox">
          <span>媒体类型</span>
          <el-checkbox v-model="mediaTypeA" @change="allSelect($event, 0)">全选</el-checkbox>
          <el-checkbox-group v-model="mediaTypeS" @change="selectChange(0)">
            <el-checkbox v-for="city in mediaType" :label="city" :key="city">
              {{ city }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="box3-div4-infor-checkbox">
          <span>媒体归属</span>
          <el-checkbox v-model="mediaOwnershipA" @change="allSelect($event, 1)">全选</el-checkbox>
          <el-checkbox-group v-model="mediaOwnershipS" @change="selectChange(1)">
            <el-checkbox v-for="city in mediaOwnership" :label="city" :key="city">
              {{ city }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="box3-div4-infor-checkbox">
          <span>媒体阵营</span>
          <el-checkbox v-model="mediaCampA" @change="allSelect($event, 2)">全选</el-checkbox>
          <el-checkbox-group v-model="mediaCampS" @change="selectChange(2)">
            <el-checkbox v-for="city in mediaCamp" :label="city" :key="city">
              {{ city }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="box3-div4-infor-checkbox">
          <span>语言类型</span>
          <el-checkbox v-model="languageTypeA" @change="allSelect($event, 3)">全选</el-checkbox>
          <el-checkbox-group v-model="languageTypeS" @change="selectChange(3)">
            <el-checkbox v-for="city in languageType" :label="city" :key="city">
              {{ city }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <!-- 微博 -->
      <div class="box3-div4-infor-checkbox" v-if="$route.query.topicType != 2">
        <span style="letter-spacing: 12px; margin-right: 30px">功 能</span>
        <el-radio-group v-model="radio2" @change="redioChange">
          <el-radio v-for="item in redioList" :label="item" :key="item.name">
            {{ item.name }}
          </el-radio>
          <el-select v-model="weiboData" size="mini">
            <el-option label="全部微博" value="0"></el-option>
            <el-option label="原创微博" value="1"></el-option>
            <el-option label="转发微博" value="2"></el-option>
          </el-select>
          <el-select v-model="deletData" size="mini">
            <el-option label="全部数据" value="0"></el-option>
            <el-option label="原文已删除数据" value="1"></el-option>
            <el-option label="原文未删除数据" value="2"></el-option>
          </el-select>
        </el-radio-group>
      </div>
      <div class="box3-div4-infor-seach">
        <div class="box3-div4-infor-seach-left">
          <el-select v-model="titleValue" placeholder="请选择" style="width: 80px; height: 36px; margin-right: 32px">
            <el-option v-for="item in titleOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input clearable style="width: 300px; margin-right: 32px" placeholder="关键词模糊搜索"
            prefix-icon="el-icon-search" v-model="value3"></el-input>
          <el-date-picker v-model="reportTime[0]" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="开始日期"></el-date-picker>
          -
          <el-date-picker v-model="reportTime[1]" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="结束日期"></el-date-picker>
          <el-cascader style="margin-left: 32px; width: 280px" v-model="regionData" :props="{
        multiple: true,
        lazyLoad: lazyFun,
        lazy: true,
        checkStrictly: true,
      }" clearable placeholder="信息发布地域" filterable v-if="$route.query.topicType != 2" collapse-tags
            :show-all-levels="false">
            <template slot-scope="{ node, data }">
              <p class="zb_topics_full_alls" ref="alls" v-if="node.level == 2 && data.value == '全选'">
                <span @click.stop="cascaderAll(node)">全选</span><span @click.stop="cascaderUnall(node)">全不选</span>
              </p>
              <span v-else>{{ data.value }}</span>
            </template>
          </el-cascader>
          <el-checkbox v-model="sign" style="margin-left: 40px"
            v-if="publicType != 1 && $route.query.topicType != 2">手动匹配观点数据</el-checkbox>
        </div>
        <div class="box3-div4-infor-seach-right">
          <el-button @click="search" type="primary">搜索</el-button>
          <el-button @click="resetData" plain>重置</el-button>
        </div>
      </div>
    </div>
    <div class="box3-div4-center">
      <div class="right-contnet-content">
        <div class="right-contnet-content-top" :class="navBarFixed == true ? 'navBarWrap2' : ''">
          <div class="right-contnet-content-top-left">
            <el-checkbox v-model="checkAll6" @change="handleCheckAllChange6">
              <span style="font-size: 16px">全部选中</span>
            </el-checkbox>
            <span class="dataNum">数据量:{{ tablePage.total }}</span>
          </div>
          <div class="right-contnet-content-top-right">
            <el-button @click="openExport" class="button-setting export" size="small"
              v-if="$route.query.topicType != 2">
              <span class="button-img">
                <img style="width: 15px; height: 15px" src="../../../assets/img/views/download.png" alt />
              </span>
              <span style="margin-left: 5px">数据导出</span>
            </el-button>

            <el-button @click="settingLook" class="button-setting guandian" size="small"
              v-if="publicType != 1 && $route.query.topicType != 2">
              <span class="button-img">
                <img style="width: 15px; height: 15px" src="../../../assets/img/views/baogao.png" alt />
              </span>
              <span style="margin-left: 5px">关联观点</span>
            </el-button>
            <el-button @click="deleteList" class="button-setting delete" size="small" v-if="publicType != 1">
              <span class="button-img">
                <img style="width: 15px; height: 15px" src="../../../assets/img/views/shanchu.png" alt />
              </span>
              <span>删除</span>
            </el-button>
            <!-- 精简按钮 || 列表按钮  -->
            <el-radio-group class="M-L" v-model="radioValue" size="medium" @change="changeradio"
              v-if="$route.query.topicType != 2">
              <el-radio-button label="1">
                <img v-if="radioValue == '2'" height="13px" src="../../../assets/img/views/jinjianBlack.png" alt="" />
                <img v-else height="13px" src="../../../assets/img/views/jinjian.png" alt="" />
                <span v-if="radioValue == '2'" style="color: #666; font-size: 15px; margin-left: 4px">精简</span>
                <span v-else style="color: #fff; font-size: 15px; margin-left: 4px">精简</span>
              </el-radio-button>
              <el-radio-button label="2">
                <img v-if="radioValue == 2" height="13px" src="../../../assets/img/views/liebiao.png" alt="" />
                <img v-else height="13px" src="../../../assets/img/views/liebiaoBlack.png" alt="" />
                <span v-if="radioValue == '1'" style="color: #666; font-size: 15px; margin-left: 4px">列表</span>
                <span v-else style="color: #fff; font-size: 15px; margin-left: 4px">列表</span>
              </el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div v-loading="loadingList">
          <div v-if="cities6.length != 0">
            <div v-for="(item, inx) in cities6" :key="inx" class="right-contnet-content-div"
              :class="item.judgeDelet ? 'deleted_info' : ''">
              <div class="right-contnet-content-div-title">
                <div class="right-contnet-content-div-title-left">
                  <el-checkbox-group @change="handleCheckedCitiesChange6($event, item)" v-model="checkedCities6">
                    <el-checkbox :label="item">{{ "" }}</el-checkbox>
                  </el-checkbox-group>
                  <span class="right-contnet-content-div-title-left-span" v-show="item.mediaType || item.mediaLink">
                    {{ $route.query.topicType == 2 ? item.mediaType : item.mediaLink }}
                  </span>
                  <span class="right-contnet-content-div-title-left-span" style="background-color: #2e59ec"
                    v-show="$route.query.topicType == 2 && item.languageType">
                    {{ item.languageType }}
                  </span>
                  <span class="right-contnet-content-div-title-left-span" v-if="item.tonalState == 0"
                    style="background-color: #07c1ae">正面</span>
                  <span class="right-contnet-content-div-title-left-span" v-if="item.tonalState == 1"
                    style="background-color: #3e82ff">中性</span>
                  <span class="right-contnet-content-div-title-left-span" v-if="item.tonalState == 2"
                    style="background-color: #ea3342">负面</span>
                  <h1 style="cursor: pointer" @click="hander(item)" v-html="item.title ? item.title : ''"></h1>
                  <el-link target="_blank" style="margin-left: 10px" type="primary" :underline="false"
                    :href="item.website">原文链接<i class="el-icon-document-copy el-icon--right"></i></el-link>
                </div>
                <div v-if="radioValue == 1">{{ item.releaseTime }}</div>
              </div>
              <template v-if="radioValue == 2">
                <div @click="hander(item)" v-html="item.content ? item.content : ''"
                  class="right-contnet-content-div-content"></div>
                <div class="right-contnet-content-div-tag" v-show="$route.query.topicType != 2">
                  <div>
                    <div>
                      作者ID：
                      {{ item.authorId ? item.authorId : "无" }}
                    </div>
                    <div>
                      媒体性质：
                      {{
        (item.mediaLink ? item.mediaLink : "无") +
        "-" +
        (item.types ? item.types : "无") +
        "-" +
        (item.levels ? item.levels : "无")
      }}
                    </div>
                    <div>
                      来源/作者：
                      {{
          item.source + "/" + (item.author ? item.author : "无")
        }}
                    </div>
                    <div>
                      关键字：
                      {{ item.keyword ? item.keyword : "无" }}
                    </div>
                  </div>
                  <div>{{ item.releaseTime }}</div>
                </div>
                <div class="right-contnet-content-div-tag" v-show="$route.query.topicType == 2">
                  <div>
                    <div>
                      媒体类型：{{ item.mediaType ? item.mediaType : "无" }}
                    </div>
                    <div>
                      媒体归属：{{
        item.mediaOwnership ? item.mediaOwnership : "无"
      }}
                    </div>
                    <div>
                      媒体阵营：{{ item.mediaCamp ? item.mediaCamp : "无" }}
                    </div>
                    <div>
                      来源/作者：{{
        item.source +
        "/" +
        (item.author ? item.sourceAuthor : "无")
      }}
                    </div>
                    <div>关键字：{{ item.keyword ? item.keyword : "无" }}</div>
                  </div>
                  <div>{{ item.releaseTime }}</div>
                </div>
                <div class="right-contnet-content-div-operation" v-show="$route.query.topicType != 2">
                  <div style="color: #888; font-size: 14px; width: 50%" v-show="publicType != 1">
                    媒体观点:
                    <el-select @change="changeViewId(item)" v-model="item.mediaViewId" multiple collapse-tags
                      style="margin-left: 20px; width: 320px" placeholder="请选择">
                      <el-option v-for="ite in cities2" :key="ite.mediaViewId" :label="ite.viewName"
                        :value="ite.mediaViewId"></el-option>
                    </el-select>
                  </div>
                  <div v-show="publicType == 1 && item.mediaViewId" style="color: #888; font-size: 14px">
                    媒体观点：{{
        cities2
          .filter((x) => {
            if (
              typeof item.mediaViewId == "string" &&
              item.mediaViewId.indexOf(x.mediaViewId) != -1
            )
              return x;
          })
          .map((x) => {
            return x.viewName;
          })
          .join("/")
      }}
                  </div>
                  <div></div>
                  <div class="right-contnet-content-div-operation-right">
                    <div @click="copy(item)">
                      <img src="../../../assets/img/views/monitoringScheme/fuzhi.png" alt />
                      复制全部
                    </div>
                    <div @click="quickCopy(item)" class="my-caozuo">
                      <span style="margin: 0; color: black; font-size: 16px" class="el-icon-link"></span>
                      复制链接和标题
                    </div>
                    <div @click="settingSample(item)" v-if="publicType != 1">
                      <span v-if="item.isSetSample == 0">
                        <img src="../../../assets/img/views/yangben.png" alt />设为样本
                      </span>
                      <span v-else style="color: blue">
                        <img src="../../../assets/img/views/blue.png" alt />
                        已设为样本
                      </span>
                    </div>
                    <div @click="deletes(item)" v-if="publicType != 1 && topicData.childParentType != 1">
                      <img src="../../../assets/img/views/monitoringScheme/shanchu3.png" alt />
                      删除
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="analyse-container" v-else>
            <div class="analyse-container-img">
              <img src="../../../assets/img/views/analyse-img.png" />
              <p>暂无数据</p>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="block">
          <span class="total">
            共
            {{ tablePage.total }}
            条, 当前显示第
            {{ tablePage.page * tablePage.pageSize - tablePage.pageSize + 1 }}
            -
            {{
        tablePage.page * tablePage.pageSize > tablePage.total
          ? tablePage.total
          : tablePage.page * tablePage.pageSize
      }}
            条
          </span>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="tablePage.page" :page-sizes="tablePage.pageSizes" :page-size="tablePage.pageSize"
            layout="sizes, prev, pager, next,jumper" :total="tablePage.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 新增媒体观点的弹窗 -->
    <el-dialog :before-close="mediaClose" class="my-dialog" :visible.sync="mediaShow" :close-on-click-modal="false"
      :close-on-press-escape="false" width="1400px">
      <div slot="title" class="dialog-header">
        <span class="el-dialog__title">媒体观点汇总</span>
      </div>
      <div class="dialog-body">
        <!-- 中间内容部分 -->
        <div class="box3-div4-media">
          <div class="box3-div4-media-top">
            <span>媒体观点</span>
            <span>观点关键字设置</span>
          </div>
          <div class="box3-div4-media-seach">
            <el-input clearable maxlength="15" show-word-limit style="width: 392px" v-model="value1"
              placeholder="请输入媒体观点"></el-input>
            <el-input clearable style="width: 800px; margin: 0 24px" v-model="value2" placeholder="请输入关键字"></el-input>
            <el-button @click="pushData" type="primary">新增观点</el-button>
          </div>
          <div style="min-height: 200px" v-loading="loadingDialog">
            <div v-if="checkedCitiesTable.length > 0" class="box3-div4-media-btn">
              <!-- 全部选中 -->
              <el-checkbox v-model="allChecked" @change="allChoice">全选</el-checkbox>
              <div class="box3-div4-media-btn-left" @click="mergeLook">
                <img src="../../../assets/img/views/monitoringScheme/bianji1.png" alt />
                合并观点
              </div>
              <!-- <div class="box3-div4-media-btn-right" @click="deleteMedias">
                <img src="../../../assets/img/views/monitoringScheme/shanchu.png" alt />
                删除观点
              </div>-->
            </div>
            <div v-if="checkedCitiesTable.length > 0" style="margin: 0 0px 25px">
              <ul class="box3-div4-media-table">
                <li class="media-table-title">
                  <span>媒体观点</span>
                  <span>匹配状态</span>
                  <span>操作</span>
                </li>
                <li v-for="(item, inx) in checkedCitiesTable" :key="inx">
                  <!-- <div class="liDiv1"></div> -->
                  <div class="liDiv2 liDiv2-box">
                    <el-checkbox-group @change="handleCheckedCitiesChange($event, item)" v-model="checkedCities">
                      <el-checkbox :disabled="item.updateState == 0" :label="item">&nbsp;</el-checkbox>
                    </el-checkbox-group>
                    <div style="margin-left: 20px">
                      <div>
                        <div>
                          <span style="margin-right: 81px">观点名称</span>
                          <span style="font-weight: 600" v-if="item.editShow == false">{{ item.viewName }}</span>
                          <el-input clearable v-model="editName" v-else class="inputs" size="small" placeholder="请输入内容">
                          </el-input>
                        </div>
                      </div>

                      <div>
                        <span style="margin-right: 81px">关键字组</span>
                        <span v-if="item.editShow == false">
                          {{ item.keyword }}
                        </span>
                        <el-input clearable class="inputs" v-model="editKey" v-else size="small" placeholder="请输入内容">
                        </el-input>
                      </div>
                    </div>
                  </div>

                  <div class="liDiv3 liDiv3-box">
                    <div v-if="item.updateState == 1" class="liDiv3-box-text">
                      <div class="liDiv3-box-text-mediaStop">
                        已停止观点匹配
                      </div>
                      <div class="liDiv3-box-text-mediaStop">
                        （截止：{{ item.ends }}）
                      </div>
                    </div>
                    <div v-if="item.updateState == 0" class="liDiv3-box-text">
                      <div class="liDiv3-box-text-mediaing">观点匹配中…</div>
                      <div>（开始：{{ item.ends }}）</div>
                    </div>
                    <div v-if="item.updateState == 2" class="liDiv3-box-text">
                      <div class="liDiv3-box-text-delete">观点删除中…</div>
                    </div>
                  </div>
                  <div class="liDiv4">
                    <span @click="matchData(item)"
                      :class="[item.updateState == 0 || item.updateState == 2 ? 'disableding' : 'matching',]">匹配</span>
                    <!-- matching -->
                    <!-- 匹配中禁止操作 disbalde 类名 -->
                    <span @click="deleteMedia(item)"
                      :class="[item.updateState == 0 || item.updateState == 2 ? 'disableding' : 'shanchu',]">删除</span>
                    <span v-if="item.editShow == false" @click="edit(item)"
                      :class="[item.updateState == 0 || item.updateState == 2 ? 'disableding' : 'editData',]">编辑</span>
                    <span @click="addData(item)" v-else class="addBc">保存</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" type="primary" plain @click="mediaClose">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog :before-close="settingClose" class="my-dialog" :visible.sync="settingMedia" :close-on-click-modal="false"
      :close-on-press-escape="false" width="1400px">
      <div slot="title" class="dialog-header">
        <span class="el-dialog__title">设置媒体观点</span>
      </div>
      <div class="dialog-bodydialog-body">
        <!-- 中间内容部分 -->
        <div class="box3-div4-media">
          <div v-loading="loadingDialog">
            <div v-if="checkedCitiesTable.length > 0" style="margin: 0 0px 25px">
              <ul class="box3-div4-media-table">
                <li v-for="(item, inx) in checkedCitiesTable" :key="inx">
                  <div class="liDiv1">
                    <el-checkbox-group v-model="checkedCities">
                      <el-checkbox :label="item">{{ "" }}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div class="liDiv2">
                    <span>{{ item.viewName }}</span>
                  </div>
                  <div class="liDiv3">
                    <span>{{ item.keyword }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" type="primary" @click="shureMedia">确定</el-button>
        <el-button style="margin-left: 8px" size="medium" type="primary" plain @click="settingClose">关闭</el-button>
      </span>
    </el-dialog>
    <deleteDialog ref="openDialog" @deleteMediaList="deleteMediaList" @deleteFn="deleteFn"></deleteDialog>
    <el-dialog title="请选择导出方式" :visible.sync="dialogVisible" width="600px" top="300px"
      v-if="$route.query.topicType != 2">
      <div class="radioWay">
        <el-radio v-model="radioWay" label="0">发布时间正序</el-radio>
        <el-radio v-model="radioWay" label="1">发布时间倒序</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="exportExcli" :disabled="ifDown">导出数据</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import deleteDialog from "../../../components/deleteDialog";
import {
  allMessageList,
  deleteMessageList,
  mediaList,
  addmediaList,
  mergeLook,
  deleteMedia,
  settingLook,
  editMedia,
  matchData,
  topicOverList,
  deleteId,
  list,
} from "../../../api/topic/topic";
import { topList } from "../../../api/abroad/list";
export default {
  data() {
    return {
      radioValue: 2, // 精简1 || 列表2
      //选择导出方式
      radioWay: "1",
      //弹窗控制
      dialogVisible: false,
      topicName: sessionStorage.getItem("topicName"),
      // 标题 正文 作者  来源 下拉框
      titleOptions: [
        {
          value: "title",
          label: "标题",
        },
        {
          value: "content",
          label: "正文",
        },
        {
          value: this.$route.query.topicType == 2 ? "sourceAuthor" : "author",
          label: "作者",
        },
        {
          value: "source",
          label: "来源",
        },
      ],
      titleValue: "title",
      // 是否人工标记
      sign: false,
      // 控制用户是否点击查询按钮
      isSearch: false,
      // 设置观点弹出框
      settingMedia: false,
      // 弹出框loading
      loadingDialog: false,
      loadingList: false,
      // 编辑input
      editShow: false,
      // input数据
      editName: "",
      editKey: "",
      checkAll2: false,
      checkedCities2: [],
      cities2: [],
      checkAll3: false,
      checkedCities3: [],
      cities3: [
        "电视",
        "纸媒",
        "网站",
        "微博",
        "微信公众号",
        "移动客户端",
        "互动栏目",
        "视频",
      ],
      checkAll4: false,
      checkedCities4: [],
      cities4: ["官媒", "商媒", "政务", "个人/机构", "其他"],
      checkAll5: false,
      checkedCities5: [],
      cities5: ["全国", "省级", "市级", "区县", "其他"],
      radio2: "",
      redioList: [
        {
          name: "全部",
        },
        {
          name: "匹配多个观点",
        },
        {
          name: "未匹配观点",
        },
        {
          name: "标题未匹配任何关键词",
        },
        {
          name: "标题只匹配单个关键词",
        },

        // {
        //   name: "已标记数据"
        // },
        // {
        //   name: "未标记数据"
        // }
      ],
      value3: "",
      checkAll6: false,
      cities6: [],
      checkedCities6: [],
      mediaShow: false,
      value1: "",
      value2: "",
      checkedCitiesTable: [],
      // 新增媒体观点新增全选
      allChecked: false,
      checkedCities: [],
      //页数
      tablePage: {
        pageSizes: [10, 20, 30, 40, 50],
        page: 1,
        pageSize: 50,
        total: 0,
      },
      searchList: {},
      navBarFixed: false,
      reportTime: [], //时间筛选
      pickerOptions: {
        disabledDate(time) {
          let curDate = new Date().getTime();
          let three = 90 * 24 * 3600 * 1000;
          let threeMonths = curDate - three;
          return time.getTime() > Date.now() || time.getTime() < threeMonths;
        },
      },
      deleteFlag: true,
      checkAgain: false, //判断是否要在第一次加载后再执行搜索
      //查询原文是否删除
      queryDelete: {
        show: false,
        title: "检查中···",
        type: "info", //info-检查中状态 success-成功时状态
      },
      weiboData: "0",
      deletData: "0",
      ifDown: false,
      mediaType: [], //媒体类型
      mediaTypeS: [],
      mediaTypeA: false,
      mediaOwnership: [], //媒体归属
      mediaOwnershipS: [],
      mediaOwnershipA: false,
      mediaCamp: [], //媒体阵营
      mediaCampS: [],
      mediaCampA: false,
      languageType: [], //语言类型
      languageTypeS: [],
      languageTypeA: false,
      regionData: null,
    };
  },
  props: ["id", "divHeight", "publicType", "topicData"],
  components: {
    deleteDialog,
  },
  methods: {
    // 用Promise 封装setTimeout
    setTimeout: (time) => new Promise((res) => setTimeout(res, time)),
    // 精简 || 列表
    async changeradio() {
      this.loading = true;
      await this.setTimeout(300);
      this.loading = false;
    },
    //打开弹窗
    openExport() {
      this.dialogVisible = true;
      this.ifDown = false;
    },
    exportExcli() {
      this.ifDown = true;
      this.dialogVisible = false;
      this.searchList.topicId = this.id;
      this.searchList.reportType = Number(this.radioWay);
      this.$message.success("正在导出数据！");
      let data = JSON.parse(JSON.stringify(this.searchList));
      data.operator = this.$store.state.user.name;
      data.token = sessionStorage.getItem("token");
      //新加参数
      data.userId = Number(JSON.parse(sessionStorage.getItem("user")).userId);
      this.$http({
        url: "api/topic/export/data",
        data: data,
        method: "post",
      });
      let textName = `${this.topicName}数据-${this.$store.state.user.name
        }-${this.$calcu.timeCycle(new Date(), "HH：mm")}.xlsx`;
      //新加
      this.$parent.$parent.$parent.timeDreFresh({
        exportName: textName,
        state: 0,
      });
      this.radioWay = "1";
    },
    // 功能按钮
    redioChange() {
      this.checkAll2 = false;
      this.checkedCities2 = [];
    },
    // 关闭设置观点弹框
    settingClose() {
      this.settingMedia = false;
      this.checkedCities = [];
    },
    // 全选媒体观点
    allChoice() {
      if (this.allChecked) {
        this.checkedCities = this.cities2;
      } else {
        this.checkedCities = [];
      }
    },
    // 观点匹配按钮
    async matchData(item) {
      const res = await matchData(item);
      if (res.data.code == 200) {
        this.$message.success("匹配成功!");
      } else {
        this.$message.error("匹配失败!");
      }
      this.mediaList();
    },
    // 搜索
    search() {
      this.tablePage.page = 1;
      let obj = {};
      let mediaLook = []; // 媒体观点
      let mediaLink = []; // 媒体环节
      let mediaAttribute = []; // 媒体属性
      let mediaLevel = []; //媒体级别
      if (
        this.reportTime[0] &&
        this.reportTime[1] &&
        this.reportTime[0] >= this.reportTime[1]
      ) {
        this.$message.warning("请选择正确的时间段");
        return;
      }
      if (!this.reportTime[0] || !this.reportTime[1]) {
        this.reportTime = [];
      }
      if (this.$route.query.topicType == 2) {
        if (this.mediaTypeS.length > 0) {
          //媒体类型
          obj.mediaType = this.mediaTypeS.join(",");
        }
        if (this.mediaOwnershipS.length > 0) {
          //媒体归属
          obj.mediaOwnership = this.mediaOwnershipS.join(",");
        }
        if (this.mediaCampS.length > 0) {
          //媒体阵营
          obj.mediaCamp = this.mediaCampS.join(",");
        }
        if (this.languageTypeS.length > 0) {
          //语言类型
          obj.languageType = this.languageTypeS.join(",");
        }
      } else {
        if (this.checkedCities2.length != 0) {
          //媒体观点
          this.checkedCities2.forEach((item) => {
            mediaLook.push(item.mediaViewId);
          });
          mediaLook = mediaLook.join(",");
          obj.mediaViewIds = mediaLook;
        }
        if (this.sign) {
          obj.manMade = 1;
        }
        if (!this.checkAll3) {
          if (this.checkedCities3.length != 0) {
            //媒体环节
            this.checkedCities3.forEach((item) => {
              mediaLink.push(item);
            });
            mediaLink = mediaLink.join(",");
            obj.mediaLink = mediaLink;
          }
        }
        if (!this.checkAll4) {
          if (this.checkedCities4.length != 0) {
            //媒体属性
            this.checkedCities4.forEach((item) => {
              mediaAttribute.push(item);
            });
            mediaAttribute = mediaAttribute.join(",");
            obj.types = mediaAttribute;
          }
        }
        if (!this.checkAll5) {
          if (this.checkedCities5.length != 0) {
            //媒体级别
            mediaLevel = this.checkedCities5;
            mediaLevel = mediaLevel.join(",");
            obj.levels = mediaLevel;
          }
        }
        if (this.radio2 != "" && this.radio2.name != "全部") {
          if (this.radio2.name == "匹配多个观点") {
            obj.mediews = 1;
          } else if (this.radio2.name == "未匹配观点") {
            obj.isView = 0;
          } else if (this.radio2.name == "已标记数据") {
            obj.isView = 1;
          } else if (this.radio2.name == "标题未匹配任何关键词") {
            obj.matchTitleResult = 0;
          } else if (this.radio2.name == "标题只匹配单个关键词") {
            obj.matchTitleResult = 1;
          } else if (this.radio2.name == "未标记数据") {
            obj.isView = 0;
          }
        }
        //判断微博类型
        if (this.weiboData == "1") {
          obj.weiboType = 0;
          obj.mediaLink = "微博";
        } else if (this.weiboData == "2") {
          obj.weiboType = 1;
          obj.mediaLink = "微博";
        }
        //判断原文已删除
        if (this.deletData == "1") {
          obj.originalType = 1;
        } else if (this.deletData == "2") {
          obj.originalType = 0;
        }
      }
      //标题 正文  作者 region
      if (this.value3 != "") {
        obj[this.titleValue] = this.value3;
      }
      if (this.regionData && this.regionData.length > 0) {
        obj.location = this.regionData.map((x) => x[x.length - 1]).join(",");
      }
      if (
        this.reportTime != false &&
        this.reportTime[0] &&
        this.reportTime[1]
      ) {
        obj.startTime = this.reportTime[0];
        obj.endTime = this.reportTime[1];
      }
      this.searchList = JSON.stringify(obj);
      this.allMessageList(obj);
    },
    // 重置
    resetData() {
      this.reportTime = [];
      this.tablePage.page = 1;
      this.checkedCities2 = [];
      this.checkedCities3 = [];
      this.checkedCities4 = [];
      this.checkedCities5 = [];
      this.radio2 = "";
      this.checkAll6 = false;
      this.checkAll2 = false;
      this.checkAll3 = false;
      this.checkAll4 = false;
      this.checkAll5 = false;
      this.value3 = "";
      this.searchList = {};
      this.sign = false;
      this.weiboData = "0";
      this.deletData = "0";
      this.mediaTypeS = [];
      this.mediaTypeA = false;
      this.mediaOwnershipS = [];
      this.mediaOwnershipA = false;
      this.mediaCampS = [];
      this.mediaCampA = false;
      this.languageTypeS = [];
      this.languageTypeA = false;
      this.regionData = null;
      this.allMessageList();
    },
    // 分页
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.tablePage.page = 1;
      this.allMessageList();
      let height = Number(this.divHeight) + 140;
      window.scrollTo(0, height);
    },
    handleCurrentChange(val) {
      this.tablePage.page = val;
      this.allMessageList();
      let height = Number(this.divHeight) + 140;
      window.scrollTo(0, height);
    },

    // 关联观点
    settingLook() {
      if (this.checkedCities6.length < 1) {
        this.$message.warning("请选择需要设置观点的文章！");
      } else {
        this.settingMedia = true;
      }
    },
    // 下拉框设置观点
    async changeViewId(val) {
      let mediaViewList = [
        {
          topicId: this.id,
          topicMsgId: String(val.topicMsgId),
          isView: val.mediaViewId.length == 0 ? 0 : 1,
          mediaViewId: val.mediaViewId.join(","),
        },
      ];
      const res = await settingLook(mediaViewList);
      if (res.data.code == 200) {
        this.$message.success("操作成功！");
        setTimeout(() => {
          this.allMessageList();
        }, 1000);
      } else {
        this.$message.error("操作失败！");
      }
    },
    // 设置观点弹框 确定
    async shureMedia() {
      if (this.checkedCities.length == 0) {
        this.$message.warning("请选择观点！");
      } else {
        let msgId = [];
        let mediaId = [];
        this.checkedCities.forEach((item) => {
          mediaId.push(item.mediaViewId);
        });
        this.checkedCities6.forEach((item) => {
          msgId.push({
            topicMsgId: String(item.topicMsgId),
            isView: 1,
            mediaViewId: mediaId.join(","),
          });
        });
        const res = await settingLook(msgId);
        if (res.data.code == 200) {
          this.$message.success("操作成功！");
        } else {
          this.$message.error("操作失败！");
        }
        this.settingMedia = false;
        this.checkedCities = [];
        setTimeout(() => {
          this.allMessageList();
        }, 1000);
      }
    },
    // 设为样本
    async settingSample(item) {
      item.isSetSample = !item.isSetSample;
      const res = await settingLook([
        {
          topicId: item.topicId,
          topicMsgId: String(item.topicMsgId),
          isSetSample: item.isSetSample ? 1 : 0,
          isView: 1,
          mediaViewId: item.mediaViewId ? item.mediaViewId.join(",") : "",
        },
      ]);
      if (res.data.code == 200) {
        this.$message.success("修改成功！");
      } else {
        this.$message.error("修改失败！");
      }
    },
    //右侧单选
    handleCheckedCitiesChange(value) {
      if (value.length == this.cities2.length) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
    },
    handleCheckedCitiesChange2(value) {
      let checkedCount = value.length;
      this.checkAll2 = checkedCount === this.cities2.length;
      if (this.checkedCities2.length != 0) {
        this.radio2 = {};
      }
    },
    // 复制
    copy(item) {
      this.$publicFun.copyFun(item.copys);
    },
    //复制链接与标题
    quickCopy(msg) {
      let str = "";
      str = "标题：" + msg.title + "\n" + "链接：" + msg.website;
      this.$publicFun.copyFun(str);
    },
    handleCheckAllChange2(val) {
      this.checkedCities2 = val ? this.cities2 : [];
      if (this.checkedCities2.length != 0) {
        this.radio2 = {};
      }
    },

    // 添加媒体观点
    addMedia() {
      this.mediaShow = true;
      this.mediaList();
    },
    // 媒体观点列表
    async mediaList() {
      this.checkedCities = [];
      this.allChecked = false;
      this.loadingDialog = true;
      const res = await mediaList({
        topicId: this.id,
      });
      if (res.data.rows) {
        this.checkedCitiesTable = res.data.rows;
        this.cities2 = res.data.rows;
      }
      this.loadingDialog = false;
    },
    // 编辑媒体观点
    edit(item) {
      this.editName = item.viewName;
      this.editKey = item.keyword;
      this.checkedCitiesTable.forEach((item) => {
        item.editShow = false;
      });
      item.editShow = true;
    },
    // 保存
    async addData(item) {
      let data = {
        viewName: this.editName,
        keyword: this.editKey,
        mediaViewId: item.mediaViewId,
        topicId: this.id,
      };
      if (item.viewName != this.editName) {
        data.ruleState = 0;
      }
      if (item.keyword != this.editKey) {
        data.ruleState = 1;
      }
      if (item.keyword != this.editKey && item.viewName != this.editName) {
        data.ruleState = 2;
      } else if (
        item.viewName == this.editName &&
        item.keyword == this.editKey
      ) {
        item.editShow = false;
        return false;
      }
      const res = await editMedia(data);
      if (res.data.code == 200) {
        this.$message.success("编辑成功！");
      } else {
        this.$message.error("编辑失败！");
      }
      item.editShow = false;
      this.mediaList();
    },
    // 合并观点
    async mergeLook() {
      let list = [];
      if (this.checkedCities.length < 2) {
        this.$message.warning("至少选择两条数据！");
      } else if (this.checkedCities.length > 2) {
        this.$message.warning("一次只能合并两条数据！");
      } else {
        this.checkedCities.forEach((item) => {
          list.push({
            keyword: item.keyword,
            mediaViewId: item.mediaViewId,
            viewName: item.viewName,
          });
        });
        //刚好是两条数据
        const res = await mergeLook({
          one: list[0],
          two: list[1],
        });
        if (res.data.code == 200) {
          this.$message.success("合并成功！");
        }
        this.mediaList();
      }
    },
    // 单次删除媒体观点
    deleteMedia(item) {
      // 代表现在删除的是媒体观点
      // item.message = '这个观点'
      item.keys = "media";

      this.$refs.openDialog.openDialog(item, "媒体观点");
    },
    deleteMedias() {
      let list = [];
      let obj = {};
      if (this.checkedCities.length == 0) {
        this.$message.warning("请选择要删除的观点！");
        return false;
      }
      this.checkedCities.forEach((item) => {
        list.push(item.mediaViewId);
      });
      list = list.join(",");
      obj.mediaViewId = list;
      obj.message = "所选观点";
      this.$refs.openDialog.openDialog(obj, "媒体观点");
    },
    // 子组件的自定义删除媒体观点的方法
    async deleteMediaList(item) {
      const res = await deleteMedia(item.mediaViewId);
      if (res.data.code == 200) {
        this.$message.success("删除成功！");
      } else {
        this.$message.error("删除失败！");
      }
      this.mediaList();
    },

    handleCheckAllChange3(val) {
      this.checkedCities3 = val ? this.cities3 : [];
    },

    handleCheckedCitiesChange3(value) {
      let checkedCount = value.length;
      this.checkAll3 = checkedCount === this.cities3.length;
    },
    handleCheckAllChange4(val) {
      this.checkedCities4 = val ? this.cities4 : [];
    },
    handleCheckedCitiesChange4(value) {
      let checkedCount = value.length;
      this.checkAll4 = checkedCount === this.cities4.length;
    },
    handleCheckAllChange5(val) {
      this.checkedCities5 = val ? this.cities5 : [];
    },
    handleCheckedCitiesChange5(value) {
      let checkedCount = value.length;
      this.checkAll5 = checkedCount === this.cities5.length;
    },
    handleCheckAllChange6(val) {
      this.checkedCities6 = val ? this.cities6 : [];
    },
    handleCheckedCitiesChange6(value) {
      let checkedCount = value.length;
      this.checkAll6 = checkedCount === this.cities6.length;
    },
    // 删除
    deletes(item) {
      item.message = "这条数据";
      this.$refs.openDialog.openDialog(item);
    },
    // 多选删除
    deleteList() {
      let dataList = [];
      let obj = {};
      if (this.cities6.length == 0) {
        this.$message.warning("没有可以删除的数据!");
      } else {
        if (this.checkedCities6.length != 0) {
          if (this.$route.query.topicType == 2) {
            this.checkedCities6.forEach((x) => {
              dataList.push(x.topicOverseasId);
            });
            this.$confirm("是否删除？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                deleteId({ topicOverseasId: dataList.join(",") }).then(
                  (res) => {
                    if (res.data.code == 200) {
                      this.allMessageList();
                      this.$message({ type: "success", message: "删除成功!" });
                    } else {
                      this.$message({ type: "error", message: "删除失败!" });
                    }
                  }
                );
              })
              .catch(() => {
                this.$message({ type: "info", message: "已取消删除" });
              });
          } else {
            this.checkedCities6.forEach((item) => {
              dataList.push(item.topicMsgId);
            });
            dataList = dataList.join(",");
            obj.topicMsgId = dataList;
            obj.message = "选中数据";
            this.$refs.openDialog.openDialog(obj);
          }
        } else {
          this.$message.warning("请选择要删除的数据!");
        }
      }
    },
    // 列表删除
    async deleteFn(item) {
      const res = await deleteMessageList({
        topicId: this.id,
        topicMsgIdList: item.topicMsgId.split(","),
      });
      if (res.data.code == 200) {
        this.$message({
          message: "删除成功！",
          type: "success",
        });
      } else {
        this.$message.error("删除失败！");
      }
      this.deleteFlag = false;

      setTimeout(() => {
        this.allMessageList();
      }, 1000);
      if (this.cities6.length == 1 || this.checkAll6) {
        if (this.tablePage.page > 1) {
          this.tablePage.page = this.tablePage.page - 1;
        }
      }
    },
    // 媒体观点汇总关闭
    mediaClose() {
      this.mediaList();
      this.mediaShow = false;
      setTimeout(() => {
        this.allMessageList();
      }, 1000);
    },
    // 添加
    async pushData() {
      this.value1 = this.value1.trim();
      this.value2 = this.value2.trim();
      if (this.value1 == "" || this.value2 == "") {
        this.$message.warning("请输入完整的内容！");
      } else {
        const res = await addmediaList({
          keyword: this.value2,
          topicId: this.id,
          viewName: this.value1,
        });
        if (res.data.code == 200) {
          this.$message({
            message: "添加成功！",
            type: "success",
          });
        } else {
          this.$message.error("添加失败！");
        }
        this.value1 = "";
        this.value2 = "";
        this.mediaList();
      }
    },
    // 信息详情
    hander(item) {
      if (this.$route.query.topicType == 2) {
        let info = encodeURIComponent(
          JSON.stringify({
            dataId: item.topicOverseasId,
            id: this.$route.query.id,
            topicId: this.$route.query.topicId,
            type: this.$route.query.index,
            topicType: this.$route.query.topicType,
            isDeepUpdate: this.$route.query.isDeepUpdate,
            isPause: this.$route.query.isPause,
          })
        );
        let routeUrl = this.$router.resolve(
          "/topics/index/topicList/topicListDetails/infoView?info=" + info
        );
        window.open(routeUrl.href, "_blank");
      } else {
        let view = item.mediaViewId ? item.mediaViewId.join(",") : "";
        let routeUrl = this.$router.resolve({
          path: "/topics/index/topicList/topicListDetails/getinfoMessage",
          query: {
            info: encodeURIComponent(
              JSON.stringify({
                id: this.$route.query.id,
                topicId: this.$route.query.topicId,
                type: this.$route.query.type,
                topicType: this.$route.query.topicType,
                topicMsgId: String(item.topicMsgId),
                viewId: view,
                pubuic: this.publicType,
              })
            ),
          },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    // 列表部分
    async allMessageList(data) {
      this.checkedCities6 = [];
      this.checkAll6 = false;
      let obj = {
        topicId: this.id,
        organId: this.$store.state.user.organizationId,
      };
      if (data) {
        this.searchList = data;
        this.searchList.topicId = this.id;
        obj = this.searchList;
      } else if (Object.values(this.searchList).length > 0) {
        obj = this.searchList;
      }
      if (this.deleteFlag) {
        this.loadingList = true;
      }
      let res;
      if (this.$route.query.topicType == 2) {
        res = await topicOverList(
          obj,
          `?pageSize=${this.tablePage.pageSize}&pageNum=${this.tablePage.page}`
        );
        this.cities6 = res.data.rows;
        this.tablePage.total = res.data.total;
      } else {
        res = await allMessageList(
          obj,
          `?pageSize=${this.tablePage.pageSize}&pageNum=${this.tablePage.page}`
        );
        if (res.data.rows) {
          this.cities6 = res.data.rows;
          this.tablePage.total = res.data.total;
          this.cities6.forEach((item) => {
            if (item.mediaViewId) {
              item.mediaViewId = item.mediaViewId.split(",");
              item.mediaViewId = item.mediaViewId.map((ite) => {
                return Number(ite);
              });
              item.mediaViewId.forEach((ite, ind) => {
                if (obj.mediaViewIds) {
                  if (ite == obj.mediaViewIds.split(",")[0]) {
                    item.mediaViewId.splice(ind, 1);
                    item.mediaViewId.unshift(ite);
                  }
                }
              });
              item.mediaViewId = Array.from(new Set(item.mediaViewId));
            }
          });
          // this.heightText();
          // wei匹配到的观点id
          let arrList = [];
          let idList = [];
          this.checkedCitiesTable.forEach((ite) => {
            //拿到观点列表
            idList.push(ite.mediaViewId);
          });
          // 将数据和观点ID进行比较  拿出不匹配的
          this.cities6.forEach((item) => {
            if (item.mediaViewId) {
              item.mediaViewId.forEach((ite) => {
                if (idList.indexOf(ite) == -1) {
                  arrList.push(ite);
                }
              });
            }
          });
          // 将未匹配到的观点id 和数组每一项比较 如果有就把对应的删除掉
          arrList.forEach((item) => {
            this.cities6.forEach((ite) => {
              if (ite.mediaViewId) {
                ite.mediaViewId.forEach((it, index) => {
                  if (item == it) {
                    ite.mediaViewId.splice(index, 1);
                  }
                });
              }
            });
          });
        }
      }
      this.loadingList = false;
      this.deleteFlag = true;
      if (this.checkAgain) {
        this.search();
        this.checkAgain = false;
      }
    },
    //导航栏固定
    watchScroll() {
      let clientHeight = null;
      if (document.documentElement.clientWidth >= 2100) {
        clientHeight = 600;
      } else {
        clientHeight = 470;
      }
      let height = Number(this.divHeight) + clientHeight;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //  当滚动超过 90 时，实现吸顶效果
      if (scrollTop > height) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    allSelect(val, type) {
      let arr = ["mediaType", "mediaOwnership", "mediaCamp", "languageType"];
      this[arr[type] + "S"] = val ? this[arr[type]] : [];
    },
    getlabel() {
      topList().then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          this.mediaType = data[0].value.split(",");
          this.mediaType.shift();
          this.mediaOwnership = data[1].value.split(",");
          this.mediaOwnership.shift();
          this.languageType = data[2].value.split(",");
          this.languageType.shift();
          this.mediaCamp = data[3].value.split(",");
          this.mediaCamp.shift();
        }
      });
    },
    selectChange(type) {
      let arr = ["mediaType", "mediaOwnership", "mediaCamp", "languageType"];
      this[arr[type] + "A"] =
        this[arr[type]].length === this[arr[type] + "S"].length;
    },
    lazyFun(node, resolve) {
      if (node.level === 0) {
        list({}).then((res) => {
          let nodes = [];
          if (res.data.code == 200) {
            res.data.data.forEach((x) => {
              nodes.push({
                label: x.placeName,
                value: x.placeName,
                zoneCode: x.zoneCode,
              });
            });
          }
          resolve(nodes);
        });
      } else if (node.level === 1) {
        list({ zoneCode: node.data.zoneCode }).then((res) => {
          let nodes = [];
          nodes.push({ label: "", value: "全选", leaf: true });
          if (res.data.code == 200) {
            res.data.data.forEach((x) => {
              nodes.push({
                label: x.placeName,
                value: x.placeName,
                zoneCode: x.zoneCode,
                leaf: true,
              });
            });
          }
          resolve(nodes);
        });
      } else {
        resolve();
      }
    },
    cascaderAll(node) {
      node.parent.children.forEach((x) => {
        if (x.value != "全选") {
          x.checked = true;
        }
      });
      this.$refs.alls.parentNode.parentNode.children[0].click();
      this.$refs.alls.parentNode.parentNode.children[0].click();
    },
    cascaderUnall(node) {
      node.parent.children.forEach((x) => {
        if (x.value != "全选") {
          x.checked = false;
        }
      });
      this.$refs.alls.parentNode.parentNode.children[0].click();
      this.$refs.alls.parentNode.parentNode.children[0].click();
    },
  },
  created() {
    if (this.$route.query.topicType == 2) {
      this.getlabel();
    } else {
      this.mediaList();
    }
    this.allMessageList();
  },
  mounted() {
    window.addEventListener("scroll", this.watchScroll);
  },
};
</script>

<style lang="scss" scoped>
.M-L {
  margin-left: 20px;
}

::v-deep .el-radio-button--medium .el-radio-button__inner {
  padding: 6px 8px;
}

.radioWay {
  display: flex;
  justify-content: space-evenly;
}

.text {
  color: #333333;
  font-size: 16px;
}

.box3-div4-infor {
  margin-top: 16px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  padding: 16px 24px;

  .box3-div4-infor-checkbox {
    border-bottom: 1px solid #e4e6ec;
    padding-bottom: 16px;
    margin-bottom: 16px;

    .el-select {
      margin-left: 20px;
    }

    .mediaLook {
      cursor: pointer;
      position: absolute;
      right: 0;
    }

    .textState {
      cursor: pointer;
      position: absolute;
      right: 48px;
      font-size: 14px;
      color: #2e59ec;
    }

    .testing {
      position: absolute;
      right: 48px;
      font-size: 14px;
      color: #ff8c19;
    }

    .tested {
      position: absolute;
      right: 48px;
      font-size: 14px;
      color: #02bc7c;
    }

    span {
      font-size: 14px;
      color: #999999;
      margin-right: 40px;
    }

    display: flex;
    align-items: center;

    ::v-deep .el-checkbox {
      margin-right: 40px;
    }
  }

  .box3-div4-infor-seach {
    display: flex;
    justify-content: space-between;

    .box3-div4-infor-seach-left {
      display: flex;
      align-items: center;
    }

    .box3-div4-infor-seach-right {
      ::v-deep .el-button {
        width: 60px;
        height: 32px;
        border-radius: 4px;
        padding: 0;
      }
    }
  }
}

.box3-div4-center {
  margin-top: 16px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  padding-bottom: 20px;

  .analyse-container {
    width: 100%;
    height: 400px;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 16px;
    text-align: center;
  }

  .analyse-container-img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .analyse-container-img p {
    font-size: 14px;
    color: #999999;
  }

  .right-contnet-content-top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e4e6ec;
    padding: 30px 40px;

    .dataNum {
      margin-left: 31px;
      font-size: 16px;
      color: #999999;
    }

    .right-contnet-content-top-right {
      display: flex;
      justify-content: center;
      align-items: center;

      .button-setting {
        position: relative;
        font-size: 14px;

        .button-img {
          position: absolute;
          top: 23%;
          left: 16%;
        }
      }

      .guandian {
        color: #333333;
        height: 32px;
        padding: 0 16px 0 35px;
        border: 1px solid #a3a3a3;
      }

      .export {
        height: 32px;
        padding: 0 16px 0 35px;
        border: 1px solid #9cb0f6;
        color: #2e59ec;
        margin-right: 10px;
      }

      .guandian:focus,
      .guandian:hover {
        background-color: #cccccc;
      }

      .hebing {
        color: #2e59ec;
        height: 32px;
        padding: 0 16px 0 35px;
        border: 1px solid #2e59ec;
      }

      .hebing:focus,
      .hebing:hover {
        background-color: rgb(194, 205, 241);
      }

      .delete {
        cursor: pointer;
        color: #ea3342;
        height: 32px;
        padding: 0 16px 0 35px;
        border: 1px solid #f7969e;
      }

      .delete:focus,
      .delete:hover {
        background-color: rgb(233, 170, 176);
      }

      .box-top-right-btn3 {
        background: #e9f4ff;
        color: #2e59ec;
      }
    }
  }

  .right-contnet-content {
    .right-contnet-content-div:hover {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.1);
    }

    .right-contnet-content-div {
      width: 100%;
      padding: 25px 40px;
      border-bottom: 1px solid #e4e6ec;
      position: relative;
      overflow: hidden;

      ::v-deep .el-tag.el-tag--info:nth-child(1) {
        display: flex;
        align-items: center;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      ::v-deep .el-tag span:nth-child(1) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 78%;
      }

      .right-contnet-content-div-title {
        display: flex;
        justify-content: space-between;

        .right-contnet-content-div-title-left {
          display: flex;
          align-items: center;

          .right-contnet-content-div-title-left-span {
            background: #868bff;
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
            text-align: center;
            padding: 2px 8px;
            margin-left: 5px;
          }

          p {
            padding: 2px 8px;
            font-weight: bold;
            background: #ffedef;
            font-size: 14px;
            color: #ea3342;
            margin-left: 8px;
          }

          h1 {
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            margin-left: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 800px;
          }
        }

        .right-contnet-content-div-title-right {
          font-size: 14px;
          display: flex;
          align-items: center;
        }
      }

      .right-contnet-content-div-content {
        cursor: pointer;
        max-height: 40px;
        width: 100%;
        font-size: 14px;
        color: #444;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin: 16px 0;
        line-height: 20px;
      }

      .right-contnet-content-div-tag {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #888;

          div {
            margin-right: 24px;
          }
        }
      }

      .right-contnet-content-div-operation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;

        .right-contnet-content-div-operation-right {
          display: flex;
          align-items: center;

          div {
            display: flex;
            align-items: center;
            margin-left: 40px;
            font-size: 14px;
            color: #333333;
            cursor: pointer;

            img {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}

.box3-div4-media {
  width: 100%;
  padding-bottom: 25px;
  background: #ffffff;
  border-radius: 4px;
}

.box3-div4-media-top {
  width: 530px;
  display: flex;
  justify-content: space-between;
  padding: 0px 16px 24px 0px;
}

.box3-div4-media-seach {
  display: flex;
  align-items: center;
}

.box3-div4-media-btn {
  display: flex;
  padding: 24px 0px;
}

img {
  margin-right: 5px;
}

.box3-div4-media-btn-left {
  cursor: pointer;
  margin-left: 32px;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #2e59ec;
}

.box3-div4-media-btn-right {
  cursor: pointer;
  margin-left: 32px;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #ea3342;
}

.box3-div4-media-table {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e4e6ec;

  .media-table-title {
    height: 40px;
    background: #f5f6fa;
  }

  .media-table-title span {
    font-size: 14px;
    color: #333333;
    font-weight: 600;
  }

  .media-table-title span:nth-child(1) {
    margin-left: 68px;
    width: 710px;
  }

  .media-table-title span:nth-child(2) {
    text-align: center;
    width: 325px;
  }

  .media-table-title span:nth-child(3) {
    width: 195px;
    text-align: center;
  }

  li {
    width: 100%;
    display: flex;
    line-height: 40px;

    .liDiv1 {
      width: 63px;
      border-right: 1px solid #e4e6ec;
      display: flex;
      justify-content: center;
    }

    .liDiv2 {
      color: #333333;
      width: 600px;
      border-right: 1px solid #e4e6ec;
      padding-left: 24px;
    }

    .liDiv2-box {
      width: 805px;
      display: flex;
      align-items: center;
      border-top: 1px solid #f5f6fa;
    }

    .liDiv3 {
      border-right: 1px solid #e4e6ec;
      width: inherit;
    }

    .liDiv3-box {
      width: 336px;
      border-top: 1px solid #f5f6fa;

      .liDiv3-box-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      .liDiv3-box-text div {
        font-size: 14px;
        text-align: center;
      }

      .liDiv3-box-text-mediaing {
        color: #02bc7c;
      }

      .liDiv3-box-text-delete {
        color: #ea3342;
      }

      .liDiv3-box-text-mediaStop {
        color: #cccccc;
      }
    }

    .liDiv4 {
      padding: 0 30px;
      text-align: center;
      width: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #f5f6fa;

      .shanchu {
        color: #ea3342;
        cursor: pointer;
      }

      .editData {
        color: #2e59ec;
        cursor: pointer;
      }

      .addBc {
        color: #02bc7c;
        cursor: pointer;
      }

      .matching {
        color: #02bc7c;
        cursor: pointer;
      }
    }
  }

  .inputs {
    width: 550px;
  }
}

// .navBarWrap {
//   background-color: #fff;
//   position: fixed;
//   top: 130px;
//   z-index: 111;
//   width: 83%;
// }
.navBarWrap2 {
  background-color: #fff;
  position: fixed;
  top: 135px;
  z-index: 111;
  width: 85.5%;
}

.deleted_info::before {
  content: "原文已删除";
  width: 200px;
  height: 20px;
  background-color: red;
  font-size: 14px;
  position: absolute;
  right: -65px;
  top: -20px;
  color: white;
  text-align: center;
  padding-top: 30px;
  transform: rotate(20deg);
}

.delete_hint {
  position: absolute;
  top: -40px;
  left: 0;
  padding-left: 30px;
}

.delete_inspection {
  position: relative;
}

.zb_topics_full_alls {
  position: absolute;
  background-color: #fff;
  left: 0px;
  top: 0;
  width: 100%;
  z-index: 20;
  height: 100%;
}

.zb_topics_full_alls span {
  margin-left: 20px;
  color: #2e59ec;
  font-size: 14px;
  cursor: pointer;
}
</style>
