<template>
  <div class="allView">
    <!-- 全部观点弹窗 -->
    <!-- 组件 -->

    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="1400px"
      style="height:713px"
    >
      <div slot="title" class="dialog-header">
        <div class="title-box">
          <span class="el-dialog__title">全部观点</span>
          <div :class="['export',downLoad?'disabled':'']" @click="exportLook">
            <span>
              <img src="../../../assets/img/views/download.png" alt />
            </span>
            <span class="outLook">{{downLoad?"正在导出...":"导出网民观点"}}</span>
          </div>
        </div>
      </div>
      <!-- 中间部分 -->
      <div class="dialog-body">
        <div class="radio-box">
          <span style="margin-right:40px">抽样选择</span>
          <el-radio-group v-model="radio" @change="clickChange">
            <el-radio
              v-for="item in oldSamplingList"
              :key="item.sampleId"
              :label="item"
            >{{item.remark+'('+item.sampleName+')'}}</el-radio>
          </el-radio-group>
        </div>

        <div class="box3-div5-media-seach">
          <el-input
            clearable
            style="width: 800px;margin-right: 24px"
            v-model="setLook"
            placeholder="请配置网民观点"
          ></el-input>
          <el-button @click="addViewpoint" type="primary">添加</el-button>
        </div>
        <div v-loading="loadings" style="min-height:100px">
          <div class="input-box" v-if="checkedCitiesTable7.length>0">
            <ul class="box3-div5-media-table">
              <li v-for="(item,inx) in checkedCitiesTable7" :key="inx">
                <div class="liDiv2">
                  <span v-if="item.editShow == false">{{item.viewName}}</span>
                  <el-input
                    clearable
                    style="width:280px"
                    size="small"
                    v-else
                    v-model="editName"
                    placeholder="请输入内容"
                  ></el-input>
                </div>
                <div class="liDiv3">
                  <span v-for="ite in item.list" :key="ite.commentId">
                    <!-- <el-tooltip
                      class="item"
                      effect="dark"
                      :content="ite.showContent"
                      placement="top"
                    >-->
                    <span v-html="ite.showContent"></span>
                    <!-- </el-tooltip> -->
                  </span>
                </div>
                <div
                  class="liDiv4"
                >{{ ( item.viewMatch / item.totalNum) * 100 + "%" }} ({{ item.viewMatch }})</div>
                <div class="liDiv5">
                  <el-button
                    @click="deleteViewpoint(item.viewpointId)"
                    type="text"
                    style="color:#EA3342"
                  >删除</el-button>
                  <el-button v-if="item.editShow == false" @click="edit(item)" type="text">编辑</el-button>
                  <el-button style="color:#02BC7C;" v-else @click="addData(item)" type="text">保存</el-button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 中间部分 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" plain type="primary" @click="colse">关闭</el-button>
      </span>
    </el-dialog>

    <!-- 组件 -->
  </div>
</template>

<script>
import {
  addViewpoint,
  addViewList,
  deleteViewpoint,
  editviewPoint
} from "../../../api/topic/topic";
export default {
  data() {
    return {
      // 控制导出观点样式
      downLoad: false,
      editName: "",
      setLook: "",
      checkedCitiesTable7: [],
      dialogVisible: false,
      radio: {},
      oldSamplingList: [],
      id: 0,
      loadings: false
    };
  },
  methods: {
    // 编辑网民观点
    edit(item) {
      this.editName = item.viewName;
      this.checkedCitiesTable7.forEach(item => {
        item.editShow = false;
      });
      item.editShow = true;
    },
    // 保存
    async addData(item) {
      const res = await editviewPoint({
        viewName: this.editName,
        viewpointId: item.viewpointId
      });
      if (res.data.code == 200) {
        this.$message.success("编辑成功！");
      } else {
        this.$message.error("编辑失败！");
      }
      this.addViewList();
    },
    openDialog(oldSampling, id, radio, item) {
      if (item.title) {
        this.setLook = item.title;
      } else {
        this.setLook = "";
      }
      this.dialogVisible = true;
      this.oldSamplingList = oldSampling;
      this.id = id;
      this.radio = radio;
      this.addViewList();
    },
    clickChange() {
      this.addViewList();
    },
    // 关闭时刷新页面
    colse() {
      this.$emit("getList");
      this.dialogVisible = false;
    },
    async addViewpoint() {
      this.setLook = this.setLook.trim();
      if (this.setLook == "") {
        this.$message.warning("请输入网民观点！");
      } else {
        const res = await addViewpoint({
          topicId: this.id,
          viewName: this.setLook,
          totalNum: this.radio.sampleName,
          sampleId: this.radio.sampleId
        });
        if (res.data.code == 200) {
          this.$message({
            message: "添加成功！",
            type: "success"
          });
        } else {
          this.$message.error("添加失败！");
        }
        this.addViewList();
        this.setLook = "";
      }
    },
    // 网民观点列表
    async addViewList() {
      this.loadings = true;
      const res = await addViewList({
        topicId: this.id,
        totalNum: this.radio.sampleName,
        sampleId: this.radio.sampleId
      });
      this.checkedCitiesTable7 = res.data.rows;
      this.checkedCitiesTable7.forEach(item => {
        item.list.forEach((it, index) => {
          if (index != 0) {
            let reg = new RegExp("@", "g"); //g代表全部
            it.showContent = it.showContent.replace(reg, "<br/>" + "@");
          }
        });
      });
      this.loadings = false;
    },
    // 导出网民观点
    exportLook() {
      this.downLoad = true;
      let obj = {
        url: "api/topic/topicViewpoint/export",
        method: "post",
        data: {
          topicId: this.id,
          totalNum: this.radio.sampleName,
          sampleId: this.radio.sampleId
        },
        responseType: "blob"
      };
      let textName = "网民观点.xlsx";
      this.$parent.$parent.$parent.downloadFun(obj, textName);
      this.downLoad = false;
    },
    // 删除网民观点
    async deleteViewpoint(id) {
      const res = await deleteViewpoint(id);
      if (res.data.code == 200) {
        this.$message({
          message: "删除成功！",
          type: "success"
        });
      } else {
        this.$message.error("删除失败!");
      }
      this.addViewList();
    }
  },
  created() {}
};
</script>

<style lang='scss' scoped>
.box3-div5-media-seach {
  margin-top: 24px;
  display: flex;
  align-items: center;

  ::v-deep .el-button {
    width: 80px;
    height: 36px;
    background: linear-gradient(313deg, #2e59ec 0%, #1a91ff 100%);
    border-radius: 4px;
    padding: 0 24px;
  }
}

.export {
  cursor: pointer;
}

.box3-div5-media-btn {
  display: flex;
  padding: 24px 44px;

  img {
    margin-right: 5px;
  }

  .box3-div5-media-btn-right {
    cursor: pointer;
    margin-left: 32px;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #ea3342;
  }
}

.box3-div5-media-table {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e4e6ec;

  li:nth-child(2n + 1) {
    background: #f5f6fa;
  }

  li {
    width: 100%;
    display: flex;
    line-height: 40px;

    .liDiv2 {
      display: flex;
      align-items: center;
      width: 25%;
      border-right: 1px solid #e4e6ec;
      padding-left: 24px;
    }

    .liDiv3 {
      border-right: 1px solid #e4e6ec;
      width: 50%;
      padding-left: 24px;
    }

    .liDiv4 {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #e4e6ec;
      width: 12%;
    }

    .liDiv5 {
      display: flex;
      justify-content: space-evenly;
      width: 12%;
    }
  }
}

.title-box {
  display: flex;
  align-items: center;

  .el-dialog__title {
    margin-right: 25px;
  }

  .outLook {
    margin-left: 5px;
    font-size: 14px;
    color: #2e59ec;
  }
}

.radio-box {
  display: flex;
  padding: 0 0 35px 0;
  border-bottom: 1px solid #e4e6ec;
}

.input-box {
  margin-top: 25px;
}
</style>
