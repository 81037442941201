<template>
  <div class="allView">
    <!-- 网民 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="1400px"
    >
      <div slot="title" class="dialog-header">
        <div class="title-box">
          <span class="el-dialog__title">设为网民观点</span>
        </div>
      </div>
      <!-- 中间部分 -->
      <div class="dialog-body">
        <div class="radio-box">
          <span style="margin-right:40px">抽样选择</span>
          <el-radio-group v-model="radio" @change="changeRadio">
            <el-radio
              :label="item"
              v-for="item in oldSampling"
              :key="item.sampleId"
            >{{item.remark+'('+item.sampleName+')'}}</el-radio>
          </el-radio-group>
        </div>
        <div class="input-box" v-loading="loadingLook" v-if="checkedCitiesTable7.length>0">
          <ul class="box3-div5-media-table">
            <li v-for="(item,inx) in checkedCitiesTable7" :key="inx">
              <!-- <el-tooltip class="item" effect="dark" :content="item.viewName"> -->
                <div class="liDiv2 media-table-liDiv2s">
                  <el-radio-group v-model="radios">
                    <el-radio :label="item">{{item.viewName}}</el-radio>
                  </el-radio-group>
                </div>
              <!-- </el-tooltip> -->
              <div class="liDiv3">
                <span v-for="ite in item.list" :key="ite.commentId">
                  <!-- <el-tooltip class="item" effect="dark" :content="ite.showContent"> -->
                  <span v-html="ite.showContent"></span>
                  <!-- </el-tooltip> -->
                </span>
              </div>
              <div
                class="liDiv4"
              >{{ (item.viewMatch / item.totalNum) * 100 + "%" }} ({{ item.viewMatch }})</div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 中间部分 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" plain type="primary" @click="close">取消</el-button>
        <el-button size="medium" type="primary" :disabled="Object.keys(radios).length == 0" @click="addData">保存</el-button>
      </span>
    </el-dialog>

    <!-- 组件 -->
  </div>
</template>

<script>
import { addViewList, editView } from "../../../api/topic/topic";
export default {
  data() {
    return {
      checkedCitiesTable7: [],
      dialogVisible: false,
      radio: {},
      oldSampling: [], //抽样样本
      commentList: [], //选中的评论列表
      loadingLook: false,
      id: 0,
      radios: {}
    };
  },
  methods: {
    openDialog(oldSampling, commentList, radio, id) {
      this.radio = radio;
      this.oldSampling = oldSampling;
      this.commentList = commentList;
      this.id = id;
      this.dialogVisible = true;
      this.addViewList();
    },
    close() {
      this.dialogVisible = false;
      this.radios = {};
    },
    //网民观点列表
    async addViewList() {
      this.radios = {};
      this.loadingLook = true;
      const res = await addViewList({
        topicId: this.id,
        totalNum: this.radio.sampleName,
        sampleId: this.radio.sampleId
      });
      if (res.data.rows) {
        this.checkedCitiesTable7 = res.data.rows;
      }
      this.checkedCitiesTable7.forEach(item => {
        item.list.forEach((it, index) => {
          if (index != 0) {
            let reg = new RegExp("@", "g"); //g代表全部
            it.showContent = it.showContent.replace(reg, "<br/>" + "@");
          }
        });
      });
      this.loadingLook = false;
    },
    // 切换
    changeRadio() {
      this.addViewList();
    },
    async addData() {
      if (this.checkedCitiesTable7.length == 0) {
        this.$message.warning("请选择观点");
      } else {
        let list = [];
        this.commentList.forEach(item => {
          list.push(item.commentId);
        });
        const res = await editView({
          list: list,
          sampleId: this.radio.sampleId,
          viewpointId: this.radios.viewpointId
        });
        if (res.data.code == 200) {
          this.$message({
            message: "操作成功！",
            type: "success"
          });
        } else {
          this.$message.error("操作失败！");
        }
        this.$emit("newGetList");
        this.dialogVisible = false;
        this.radios = {};
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.box3-div5-media-seach {
  margin-top: 24px;
  display: flex;
  align-items: center;

  ::v-deep .el-button {
    width: 80px;
    height: 36px;
    background: linear-gradient(313deg, #2e59ec 0%, #1a91ff 100%);
    border-radius: 4px;
    padding: 0 24px;
  }
}

.box3-div5-media-btn {
  display: flex;
  padding: 24px 44px;

  img {
    margin-right: 5px;
  }

  .box3-div5-media-btn-right {
    cursor: pointer;
    margin-left: 32px;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #ea3342;
  }
}

.box3-div5-media-table {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e4e6ec;

  li:nth-child(2n + 1) {
    background: #f5f6fa;
  }

  li {
    width: 100%;
    display: flex;
    line-height: 40px;

    .liDiv2 {
      display: flex;
      align-items: center;
      width: 25%;
      border-right: 1px solid #e4e6ec;
      padding-left: 24px;
    }

    .liDiv3 {
      border-right: 1px solid #e4e6ec;
      width: 60%;
      padding-left: 24px;
    }

    .liDiv4 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15%;
    }
  }
}

.title-box {
  display: flex;
  align-items: center;

  .el-dialog__title {
    margin-right: 25px;
  }
}

.radio-box {
  display: flex;
  align-items: center;
  padding: 0 0 35px 0;
  border-bottom: 1px solid #e4e6ec;
}

.input-box {
  margin-top: 25px;
}
</style>
