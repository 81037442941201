<template>
  <div class="box1">
    <div class="box1-div1">
      <div class="box1-div1-top">
        <img src="../../assets/img/views/monitoringScheme/quanquan.png" alt />
        事件概况
      </div>
      <div class="box1-div1-center">
        <div v-html="incidentOverview"></div>
      </div>
    </div>
    <div class="box1-div2">
      <div class="box1-div2-top">
        <img src="../../assets/img/views/monitoringScheme/quanquan.png" alt />
        信息数量 {{ countList.totalInformation }}（今日新增
        <span style="color: #02bc7c; margin-left: 5px">
          <img src="../../assets/img/views/monitoringScheme/shangsheng.png" alt />
          {{ countList.todayInformation }}
        </span>
        ）
        <span style="font-size: 14px; color: #999999">增量为当日0点到现在</span>
      </div>
      <div class="box1-div2-center">
        <div class="box1-div2-center-left">
          <div class="box1-div2-center-left-title">各平台发布数据</div>
          <ul v-if="infoListData && infoListData.length > 0" class="box1-div2-center-left-table">
            <li v-for="(item, index) in infoListData" :key="index">
              <div class="box1-div2-center-left-table-left">
                {{ item.showname }}
              </div>
              <div class="box1-div2-center-left-table-right" @click="mediaTypeSearch(item)">
                {{ item.totalComments }}
                （
                <img src="../../assets/img/views/monitoringScheme/shangsheng.png" alt />
                <span>{{ item.todayComments }}</span>
                ）
              </div>
            </li>
          </ul>
          <ul class="box1-div2-center-left-table" v-else>
            <li class="box1-div2-center-left-table-noData">
              <p style="text-align: center; width: 100%">暂无数据</p>
            </li>
          </ul>
        </div>
        <!-- 769 -->
        <div class="box1-div2-center-right-box">
          <div class="box1-div2-center-right">
            <div v-if="inforListTotal && inforListTotal.length > 0">
              <div ref="echarts1" id="echarts1"></div>
            </div>
            <div class="analyse-container" v-else>
              <div class="analyse-container-img">
                <img src="../../assets/img/views/analyse-img.png" />
                <p>各环节整体分布</p>
              </div>
            </div>
          </div>
          <div class="box1-div2-center-right">
            <div v-if="inforListToday && inforListToday.length > 0">
              <div style="height: 300px" ref="echartstwo" id="echartstwo"></div>
            </div>
            <!-- inforListToday -->
            <div class="analyse-container" v-else>
              <div class="analyse-container-img">
                <img src="../../assets/img/views/analyse-img.png" />
                <p>各环节今日新增分布</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="topicType == 1 && $store.state.user.scope[2]" class="box1-div2">
      <div class="box1-div2-top">
        <img src="../../assets/img/views/monitoringScheme/quanquan.png" alt />
        评论数量 {{ countList.totalComments }}（今日新增
        <span style="color: #02bc7c; margin-left: 5px">
          <img src="../../assets/img/views/monitoringScheme/shangsheng.png" alt />
          {{ countList.todayComments }}
        </span>
        ）
        <span style="font-size: 14px; color: #999999">增量为当日0点到现在</span>
      </div>
      <div class="box1-div2-center">
        <div class="box1-div2-center-left">
          <div class="box1-div2-center-left-title">各平台发布数据</div>
          <ul v-if="countList.commentList && countList.commentList.length != 0" class="box1-div2-center-left-table">
            <li v-for="(item, index) in countList.commentList" :key="index">
              <div class="box1-div2-center-left-table-left">
                {{ item.showname ? item.showname : "无" }}
              </div>
              <div class="box1-div2-center-left-table-right" @click="commentSearch(item)">
                {{ item.totalComments }}（
                <img src="../../assets/img/views/monitoringScheme/shangsheng.png" alt />
                <span>{{ item.todayComments }}</span>
                ）
              </div>
            </li>
          </ul>
          <ul class="box1-div2-center-left-table" v-else>
            <li class="box1-div2-center-left-table-noData">
              <p style="text-align: center; width: 100%">暂无数据</p>
            </li>
          </ul>
        </div>
        <div class="box1-div2-center-right-box">
          <div class="box1-div2-center-right">
            <div v-if="commentListTotal && commentListTotal.length > 0">
              <div style="height: 250px" ref="echarts2" id="echarts2"></div>
            </div>
            <div class="analyse-container" v-else>
              <div class="analyse-container-img">
                <img src="../../assets/img/views/analyse-img.png" />
                <p>各环节整体分布</p>
              </div>
            </div>
          </div>
          <div class="box1-div2-center-right">
            <div v-if="commentListToday && commentListToday.length > 0">
              <div style="height: 250px" ref="echarts2two" id="echarts2two"></div>
            </div>
            <div class="analyse-container" v-else>
              <div class="analyse-container-img">
                <img src="../../assets/img/views/analyse-img.png" />
                <p>各环节今日新增分布</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box1-div3">
      <div class="box1-div3-top">
        <div class="box1-div3-top-left">
          <img src="../../assets/img/views/monitoringScheme/quanquan.png" alt />
          事件进展曲线
          <div @click="onloadImg('echarts3')" v-show="topicType != 2">
            <img src="../../assets/img/views/monitoringScheme/xiazai.png" alt />
            下载
          </div>
          <div v-if="topicType == 2" id="zb_line_text">
            数据监测期间全网声量最高峰于<span>{{ overEventLineText[0] }}</span>出现，产生<span>{{ overEventLineText[1] }}</span>条信息</div>
          <!-- 设置时间范围 -->
          <el-date-picker v-model="time[0]" @change="dateChange(1)" type="date" value-format="yyyy-MM-dd"
            :picker-options="pickerOptions" placeholder="开始日期" v-show="topicType != 2">
          </el-date-picker>
          <span style="transform:translateX(12px)" v-show="topicType != 2">-</span>
          <el-date-picker v-model="time[1]" @change="dateChange(2)" type="date" value-format="yyyy-MM-dd"
            :picker-options="pickerOptions" placeholder="结束日期" v-show="topicType != 2">
          </el-date-picker>
        </div>
      </div>
      <div id="echarts3" v-if="eventLineList && eventLineList.length > 0 || (topicType == 2 && overEventLine.length > 0)">
      </div>
      <div class="analyse-container" v-else>
        <div class="analyse-container-img">
          <img src="../../assets/img/views/analyse-img.png" />
          <p>暂无数据</p>
        </div>
      </div>
    </div>
    <div v-if="topicType == 1 && $store.state.user.scope[2] && $store.state.user.organizationId != 2652" class="box1-div4">
      <div class="box1-div4-top">
        <img src="../../assets/img/views/monitoringScheme/quanquan.png" alt />
        评论曲线
        <div @click="onloadImg('echarts4')">
          <img src="../../assets/img/views/monitoringScheme/xiazai.png" alt />
          下载
        </div>
      </div>
      <div class="box1-div4-center">
        <div class="box1-div4-center-left">
          <el-table :row-class-name="tableRowClassName" :header-cell-style="{ background: '#F5F6FA', color: '#333333' }"
            ref="multipleTable" :data="tabledata" tooltip-effect="dark" style="width: 100%; border: 1px solid #e4e6ec"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column label="排名" align="center" type="index" width="60"></el-table-column>
            <el-table-column prop="mainkeyword" label="关键词" width="120" align="center"></el-table-column>
            <el-table-column prop="amount" label="数量" align="center"></el-table-column>
          </el-table>
        </div>
        <div id="echarts4"></div>
      </div>
      <!-- <div class="analyse-container" v-else>
        <div class="analyse-container-img">
          <img src="../../assets/img/views/analyse-img.png" />
          <p>暂无数据</p>
        </div>
      </div>-->
    </div>
    <div class="box1-div5">
      <div class="box1-div5-top">
        <img src="../../assets/img/views/monitoringScheme/quanquan.png" alt />
        首发媒体
      </div>
      <!-- 非境外话题 -->
      <div class="box-div" v-if="firstMedias && firstMedias.length > 0 && topicType != 2">
        <div class="box-hader box-div-color">
          <div class="box-div1 box-div-title">时间</div>
          <div class="box-div2 box-div-title">环节</div>
          <div class="box-div2 box-div-title">类型</div>
          <div class="box-div2 box-div-title">级别</div>
          <div class="box-div2 box-div-title">来源</div>
          <div class="box-div2 box-div-title">作者</div>
          <div class="box-div3 box-div-title">标题</div>
        </div>
        <div :class="['box-body', (index + 1) % 2 == 0 ? 'box-div-color' : '']" v-for="(item, index) in firstMedias"
          :key="index">
          <div class="box-div1">
            <span class="box-div-span">{{ item.releaseTime }}</span>
          </div>
          <div class="box-div2">
            <span class="box-div-span">{{ item.mediaLink }}</span>
          </div>
          <div class="box-div2">
            <span class="box-div-span">{{ item.types }}</span>
          </div>
          <div class="box-div2">
            <span class="box-div-span">{{ item.levels }}</span>
          </div>
          <div class="box-div2">
            <span class="box-div-span">{{ item.source }}</span>
          </div>
          <div class="box-div2">
            <span class="box-div-span">{{ item.author }}</span>
          </div>
          <div @click="handerWeb(item.website)" class="box-div3 website">
            <span class="box-div-span">{{ item.title }}</span>
          </div>
        </div>
      </div>
      <!-- 境外话题 -->
      <div class="box-div" v-else-if="firstMedias && firstMedias.length > 0 && topicType == 2">
        <div class="box-hader box-div-color">
          <div class="box-div1 box-div-title">时间</div>
          <div class="box-div2 box-div-title">媒体类型</div>
          <div class="box-div2 box-div-title">媒体归属</div>
          <div class="box-div2 box-div-title">媒体阵营</div>
          <div class="box-div2 box-div-title">语言类型</div>
          <div class="box-div2 box-div-title">信息来源</div>
          <div class="box-div2 box-div-title">作者</div>
          <div class="box-div3 box-div-title">标题</div>
        </div>
        <div :class="['box-body', (index + 1) % 2 == 0 ? 'box-div-color' : '']" v-for="(item, index) in firstMedias"
          :key="index">
          <div class="box-div1">
            <span class="box-div-span">{{ $calcu.timeCycle(new Date(item.releaseTime), "yyyy-MM-dd HH:mm:ss") }}</span>
          </div>
          <div class="box-div2">
            <span class="box-div-span">{{ item.mediaType }}</span>
          </div>
          <div class="box-div2">
            <span class="box-div-span">{{ item.mediaOwnership }}</span>
          </div>
          <div class="box-div2">
            <span class="box-div-span">{{ item.mediaCamp }}</span>
          </div>
          <div class="box-div2">
            <span class="box-div-span">{{ item.languageType }}</span>
          </div>
          <div class="box-div2">
            <span class="box-div-span">{{ item.source }}</span>
          </div>
          <div class="box-div2">
            <span class="box-div-span">{{ item.sourceAuthor }}</span>
          </div>
          <div class="box-div3">
            <span class="box-div-span">{{ item.title }}</span>
          </div>
        </div>
      </div>
      <div class="analyse-container" v-else>
        <div class="analyse-container-img">
          <img src="../../assets/img/views/analyse-img.png" />
          <p>暂无数据</p>
        </div>
      </div>
    </div>
    <div id="topics_wordCloud" v-if="topicType != 2">
      <div class="topics_wordCloud_top">
        <img src="../../assets/img/views/monitoringScheme/quanquan.png" alt />
        关键词云
        <div @click="onloadImg('topics_wordCloud_topleft')">
          <img src="../../assets/img/views/monitoringScheme/xiazai.png" alt />
          下载
        </div>
        <span style="margin-left: auto;margin-right: 20px;" @click="openHotWord"
          v-show="(hotWordData.show.length > 0 || hotWordData.shield.length) && publicType != 1"><i
            class="el-icon-s-tools"></i>热词设置</span>
      </div>
      <div style="width:100%">
        <div id="topics_wordCloud_topleft" v-show="hotWordData.show.length > 0"></div>
        <div class="analyse-container" v-show="hotWordData.show.length == 0">
          <div class="analyse-container-img">
            <img src="../../assets/img/views/analyse-img.png" />
            <p>暂无数据</p>
          </div>
        </div>
        <div class="topics_wordCloud_topright">
          <el-table :data="wordCloudData" stripe :header-cell-style="wordCloudStyle" :cell-style="{ textAlign: 'center' }"
            style="width: 80%;border: 1px solid #e4e6ec" size="small">
            <el-table-column prop="number" label="排名"></el-table-column>
            <el-table-column prop="key" label="关键词"></el-table-column>
            <el-table-column prop="frequency" label="词频数"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog title="热词设置" :visible.sync="hotWordShow" width="700px">
      <div class="topics_hotWord_box">
        <div class="topics_hotWord_button" v-for="x in hotWordSetData.show" :key="x" @click="closeHotWord(x, 1)">
          {{ x }}
          <span class="el-icon-close"></span>
        </div>
      </div>
      <h6 style="margin:20px 0 10px">屏蔽热词</h6>
      <div class="topics_hotWord_box topics_hotWord_shield">
        <div class="topics_hotWord_button" v-for="x in hotWordSetData.shield" :key="x" @click="closeHotWord(x, 2)">
          {{ x }}
          <span class="el-icon-close"></span>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="hotWordShow = false">取 消</el-button>
        <el-button type="primary" @click="saveHotWord">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-wordcloud";
import {
  getInfoAmountCount,
  firstMedia,
  eventLine,
  commentLine,
  frequency,
  getOverseasType,
  topicOverFirstType,
  topicOverProgress
} from "../../api/topic/topic";
import html2canvas from "html2canvas";

export default {
  name: "generalization",
  data() {
    return {
      tabledata: [],
      tableId: "1",
      firstMedias: [],
      // 评论曲线选中的数据
      multipleSelection: [],
      // 存放信息数量he评论数量
      countList: {},
      inforListToday: [], //存echart里面的今日信息数据
      inforListTotal: [], //存echart里面的全部信息数据
      commentListToday: [], //存echart里面的今日评论数据
      commentListTotal: [], //存echart里面的全部评论数据
      eventLineList: [], //事件进展曲线
      eventLineTime: [], //事件进展曲线原始时间
      eventLineTimeShow: [], //事件进展曲线显示时间
      eventLineData: [], //事件进展曲线原始数据
      eventLineDataShow: [], //事件进展曲线显示数据
      timeInterval: "",
      topicId: Number(this.$route.query.topicId),
      id: Number(this.$route.query.id),
      whole: [],
      infoListData: [],
      pickerOptions: this.pickerOptionsObj(),
      time: [],
      minTime: null,
      maxTime: null,
      incidentOverview: "",//事件概况
      wordCloudStyle: {//关键词云样式
        backgroundColor: "#f5f6fa",
        color: "black",
        textAlign: "center"
      },
      wordCloudData: [],//关键词云表格数据
      hotWordShow: false,//热词弹窗展出
      hotWordData: {//热词原始数据
        show: [],
        shield: []
      },
      hotWordSetData: {},//热词修改数据
      overEventLine: [],//境外话题进展曲线
      overEventLineText: ["", 0],
    };
  },
  props: ["topicType", "publicType", "topicData"],
  methods: {
    pickerOptionsObj() {
      let _this = this;
      return {
        disabledDate(time) {
          if (
            time > new Date(_this.minTime.getTime() - 24 * 60 * 60 * 1000) &&
            time <= _this.maxTime
          ) {
            return false;
          } else {
            return true;
          }
        },
      };
    },
    // 首发媒体 跳原文地址
    handerWeb(val) {
      window.open(val);
    },
    // 控制样式的
    tableRowClassName({ row }) {
      let self = this;
      for (let i = 0; i < self.multipleSelection.length; i++) {
        if (row == self.multipleSelection[i]) {
          return "warning-row";
        }
      }
    },
    // 点击切换时的数据
    handleSelectionChange(val) {
      let key = [];
      let time = [];
      let obj = {};
      let arrData = [];
      let color = ["#009BF9", "#FFAE45"];
      this.multipleSelection = val;
      this.$store.state.multipleSelection = val;
      this.multipleSelection.forEach((item, index) => {
        if (item.mainkeyword) {
          obj = {
            name: item.mainkeyword,
            type: "line",
            stack: item.mainkeyword,
            color: color[index],
            smooth: true,
            symbolSize: 5,
            areaStyle: {
              normal: {
                //右，下，左，上
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "#009BF9",
                    },
                    {
                      offset: 1,
                      color: "#ffffff",
                    },
                  ],
                  false
                ),
              },
            },
            data: [],
          };
          arrData.push(obj);
          key.push(item.mainkeyword);
          for (let key in item.map) {
            // item.map[key]对象的值
            time.push(key);
            obj.data.push([key, item.map[key]]);
          }
          obj.data.sort();
        }
      });
      time = time.sort();
      if (this.topicType == 1) {
        if (this.tabledata && this.tabledata.length > 0) {
          setTimeout(() => {
            this.echarts4(key, time, arrData);
          }, 500);
        }
      }
      this.$store.state.dataListTopic.hotWords = "";
      this.$nextTick(() => {
        html2canvas(document.querySelector(".box1-div4-center-left")).then(
          (resolve) => {
            let imgUrl = resolve.toDataURL("image/png"); //此时就得到了dom元素转成了base64的图片
            this.$store.state.dataListTopic.hotWords = imgUrl;
          }
        );
      });
    },
    jump(val) {
      this.tableId = val.id;
    },
    onloadImg(val) {
      if (val == "topics_wordCloud_topleft" && this.hotWordData.show.length == 0) {
        this.$message.warning("暂无数据");
        return;
      }
      if (document.getElementById(val)) {
        let myChart = echarts.init(document.getElementById(val));
        let picInfo = myChart.getDataURL({
          type: "png",
          pixelRatio: 1.5, //放大两倍下载，之后压缩到同等大小展示。解决生成图片在移动端模糊问题
          backgroundColor: "#fff",
        }); //获取到的是一串base64信息
        const elink = document.createElement("a");
        elink.download = "统计图";
        elink.style.display = "none";
        elink.href = picInfo;
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        this.$message.warning("暂无数据");
      }
    },
    echarts1() {
      let myChart = echarts.init(this.$refs.echarts1);
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        title: [
          {
            text: "各环节整体分布",
            left: "50%",
            top: "90%",
            textAlign: "center",
            textStyle: {
              color: "#B8BCC3",
              fontSize: 14,
              fontWeight: "normal",
            },
          },
        ],
        series: [
          {
            name: "全部",
            type: "pie",
            center: ["50%", "40%"],
            radius: 70,
            minAngle: 10,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
            label: {
              formatter: "{b}\n{@整体}",
            },
            data: this.inforListTotal,
          },
        ],
      };
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
    echartstwo() {
      let myChart = echarts.init(this.$refs.echartstwo);
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        title: [
          {
            text: "各环节今日新增分布",
            left: "50%",
            top: "90%",
            textAlign: "center",
            textStyle: {
              color: "#B8BCC3",
              fontSize: 14,
              fontWeight: "normal",
            },
          },
        ],
        series: [
          {
            name: "今天",
            type: "pie",
            center: ["50%", "40%"],
            radius: 70,
            minAngle: 10,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
            label: {
              formatter: "{b}\n{@整体}",
            },
            data: this.inforListToday,
          },
        ],
      };
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
    echarts2() {
      let myChart = echarts.init(this.$refs.echarts2);
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        title: [
          {
            text: "各环节整体分布",
            left: "50%",
            top: "90%",
            textAlign: "center",
            textStyle: {
              color: "#B8BCC3",
              fontSize: 14,
              fontWeight: "normal",
            },
          },
        ],
        label: {
          formatter: "{b}\n{@整体}",
        },
        series: [
          {
            name: "全部",
            type: "pie",
            center: ["50%", "40%"],
            radius: 70,
            minAngle: 10,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
            data: this.commentListTotal,
          },
        ],
      };
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
    echarts2two() {
      let myChart = echarts.init(this.$refs.echarts2two);
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        title: [
          {
            text: "各环节今日新增分布",
            left: "50%",
            top: "90%",
            textAlign: "center",
            textStyle: {
              color: "#B8BCC3",
              fontSize: 14,
              fontWeight: "normal",
            },
          },
        ],
        label: {
          formatter: "{b}\n{@整体}",
        },
        series: [
          {
            name: "今天",
            type: "pie",
            center: ["50%", "40%"],
            radius: 70,
            minAngle: 10,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
            data: this.commentListToday,
          },
        ],
      };
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
    echarts3() {
      let myChart = echarts.init(document.getElementById("echarts3"));
      myChart.clear();
      let chartTime = JSON.parse(JSON.stringify(this.eventLineTimeShow));
      let chartData = JSON.parse(JSON.stringify(this.eventLineDataShow));
      if (this.time[0] && this.time[1] && this.time[0] <= this.time[1]) {
        let max = 0;
        let min = 0;
        let arrTime = [];
        chartTime.forEach((x) => {
          let date = new Date(new Date(new Date(this.time[1]).setDate(new Date(this.time[1]).getDate() + 1)).setHours(0));
          if (
            new Date(this.time[0] + " 00:00:00").getTime() <=
            new Date(x).getTime() &&
            date.getTime() >=
            new Date(x).getTime()
          ) {
            arrTime.push(x);
          }
        });
        chartTime = arrTime;
        chartData.forEach((x) => {
          x.data = x.data.slice(min, max == 0 ? chartTime.length : max);
        });
      }
      let option = {
        grid: {
          left: "6%",
          right: "6%",
          bottom: 50,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: [
          {
            x: "center",
            right: "28%",
            align: "left",
            itemWidth: 18, // 设置宽度class
            itemHeight: 10,
            textStyle: {
              color: "#AAAAAA",
            },
            data: [
              {
                name: "电视(增量)",
              },
              {
                name: "纸媒(增量)",
              },
              {
                name: "网站(增量)",
              },
              {
                name: "微博(增量)",
              },
              {
                name: "微信公众号(增量)",
              },
              {
                name: "移动客户端(增量)",
              },
              {
                name: "互动栏目(增量)",
              },
              {
                name: "视频(增量)",
              },
              {
                name: "总体增量趋势",
              },
            ],
          },
        ],
        dataZoom: [
          {
            type: "inside",
          },
          {
            startValue: chartTime[0],
          },
        ],
        xAxis: {
          type: "category",
          data: chartTime,
          borderColor: "#000",
          axisLine: {
            lineStyle: {
              color: "#000",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            name: "总量",
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#000",
                fontSize: 12,
              },
            },
            boundaryGap: ["0%", "50%"],
            splitLine: {
              lineStyle: {
                color: "#000",
                type: "dashed",
              },
            },
            minInterval: 1, //将刻度的最小间距设置为1
          },
          {
            name: "增量",
            axisLine: {
              lineStyle: {
                color: "#000",
                fontSize: 12,
              },
            },
            alignTicks: true,
            type: "value",
            minInterval: 1, //将刻度的最小间距设置为1
          },
        ],
        // series: this.eventLineData   wholeObj
        series: chartData,
      };
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
    echarts4(key, time, arrData) {
      let myChart = echarts.init(document.getElementById("echarts4"));
      myChart.clear();
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        dataZoom: [
          {
            startValue: time[0],
            left: "10%",
            right: "13%",
          },
          {
            type: "inside",
          },
        ],
        legend: {
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 20,
          x: "45%",
          y: "4%",
          data: key,
        },
        grid: {
          left: "5%",
          right: "7%",
          bottom: "15%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: time,
          },
        ],
        yAxis: [
          {
            type: "value",
            minInterval: 1, //将刻度的最小间距设置为1
          },
        ],
        //数据
        series: arrData,
      };
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
    // 评论曲线
    async commentLine() {
      const res = await commentLine({
        topicId: this.id,
      });
      if (res.data.data) {
        this.tabledata = res.data.data;
        if (this.tabledata.length != 0 && this.$store.state.user.organizationId != 2652) {
          this.multipleSelection = this.tabledata;
          setTimeout(() => {
            this.tabledata.forEach((item) => {
              this.$refs.multipleTable.toggleRowSelection(item);
            });
          }, 500);
        }
      }
    },
    // 信息数量及评论数量
    async getInfoAmountCount() {
      let inforListTodayObj = {};
      let inforListTotalObj = {};
      let commentListTodayObj = {};
      let commentListTotalObj = {};
      this.inforListToday = [];
      this.inforListTotal = [];
      this.commentListToday = [];
      this.commentListTotal = [];
      let dataLists = [];
      let res;
      if (this.topicType == 2) {
        res = await getOverseasType({
          topicId: this.id,
        });
        dataLists = [
          { showname: "新闻网站", totalComments: 0, todayComments: 0, },
          { showname: "社区论坛", totalComments: 0, todayComments: 0, },
          { showname: "社交媒体", totalComments: 0, todayComments: 0, },
          { showname: "机构官网", totalComments: 0, todayComments: 0, },
          { showname: "资讯平台", totalComments: 0, todayComments: 0, },
          { showname: "其他", totalComments: 0, todayComments: 0, },
        ];
      } else {
        res = await getInfoAmountCount({
          topicId: this.id,
          organId: this.$store.state.user.organizationId
        });
        dataLists = [
          { showname: "电视", totalComments: 0, todayComments: 0, },
          { showname: "纸媒", totalComments: 0, todayComments: 0, },
          { showname: "网站", totalComments: 0, todayComments: 0, },
          { showname: "微博", totalComments: 0, todayComments: 0, },
          { showname: "微信公众号", totalComments: 0, todayComments: 0, },
          { showname: "移动客户端", totalComments: 0, todayComments: 0, },
          { showname: "互动栏目", totalComments: 0, todayComments: 0, },
          { showname: "视频", totalComments: 0, todayComments: 0, },
          { showname: "抖音", totalComments: 0, todayComments: 0, },
          { showname: "快手", totalComments: 0, todayComments: 0, },
          { showname: "今日头条", totalComments: 0, todayComments: 0, },
        ];
      }
      if (res.data.data) {
        this.countList = res.data.data;
        if (res.data.data.infoList) {
          dataLists.forEach((item) => {
            res.data.data.infoList.forEach((it) => {
              if (item.showname == it.showname) {
                item.totalComments = it.totalComments;
                item.todayComments = it.todayComments;
              }
            });
          });
          this.infoListData = dataLists;
        }
        this.infoListData.forEach((item) => {
          if (item.totalComments != 0) {
            inforListTotalObj = {
              name: item.showname,
              value: item.totalComments,
            };
            this.inforListTotal.push(inforListTotalObj);
          }
          if (item.todayComments != 0) {
            inforListTodayObj = {
              name: item.showname,
              value: item.todayComments,
            };
            this.inforListToday.push(inforListTodayObj);
          }
        });
        let flagone = false;
        let flagtwo = false;
        if (this.inforListToday.length != 0) {
          this.inforListToday.forEach((item) => {
            if (item.value != 0) {
              flagone = true;
              setTimeout(() => {
                this.echartstwo();
              }, 500);
            }
          });
        }
        if (this.inforListTotal.length != 0) {
          this.inforListTotal.forEach((item) => {
            if (item.value != 0) {
              flagtwo = true;
              setTimeout(() => {
                this.echarts1();
              }, 500);
            }
          });
        }
        if (!flagone) {
          this.inforListToday = [];
        }
        if (!flagtwo) {
          this.inforListTotal = [];
        }
        if (this.countList.commentList) {
          if (this.countList.commentList.length != 0) {
            this.countList.commentList.forEach((item) => {
              commentListTotalObj = {
                name: item.showname,
                value: item.totalComments,
              };
              commentListTodayObj = {
                name: item.showname,
                value: item.todayComments,
              };
              this.commentListToday.push(commentListTodayObj);
              this.commentListTotal.push(commentListTotalObj);
            });
            let flagone = false;
            let flagtwo = false;
            this.commentListToday.forEach((item) => {
              if (item.value != 0) {
                flagone = true;
              }
            });
            this.commentListTotal.forEach((item) => {
              if (item.value != 0) {
                flagtwo = true;
              }
            });
            if (!flagone) {
              this.commentListToday = [];
            }
            if (!flagtwo) {
              this.commentListTotal = [];
            }
          }
        }
      }
      if (this.topicType == 1) {
        if (
          this.countList.commentList &&
          this.countList.commentList.length != 0
        ) {
          setTimeout(() => {
            if (this.commentListToday.length != 0) {
              this.echarts2two();
            }
            if (this.commentListTotal.length != 0) {
              this.echarts2();
            }
          }, 500);
        }
      }
    },
    // 事件进展曲线
    async eventLine() {
      if (this.topicType == 2) {
        let res = await topicOverProgress({
          topicId: this.id,
        });
        if (res.data.code == 200) {
          if (res.data.data.distributeBean) {
            this.overEventLineText[0] = this.$calcu.timeCycle(res.data.data.distributeBean.showTime, "yyyy年M月d日H时");
            this.overEventLineText[1] = res.data.data.distributeBean.todayComments;
          }
          if (res.data.data.list) {
            let data = res.data.data.list;
            let time = [];
            data.forEach(x => {
              time.push(x.showTime);
            });
            time = Array.from(new Set(time));
            time.sort();
            let start = new Date(time[0]);
            let end = new Date(time[time.length - 1]);
            let xAxis = [];
            while (start <= end) {
              xAxis.push(this.$calcu.timeCycle(start, "yyyy-MM-dd HH:mm:ss"));
              start = new Date(start.setHours(start.getHours() + 1));
            }
            let output = {};
            data.forEach(x => {
              if (output[x.showTime]) {
                output[x.showTime] += x.todayComments;
              } else {
                output[x.showTime] = x.todayComments;
              }
            });
            let today = [];
            let total = [];
            xAxis.forEach((x, i) => {
              if (i == 0) {
                total[i] = output[x];
                today[i] = output[x];
              } else if (output[x]) {
                total[i] = total[i - 1] + output[x];
                today[i] = output[x];
              } else {
                total[i] = total[i - 1];
                today[i] = 0;
              }
            });
            let option = {
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                  crossStyle: {
                    color: '#999'
                  }
                }
              },
              grid: {
                top: 75,
                containLabel: true
              },
              toolbox: {
                feature: {
                  dataView: { show: true },
                  magicType: { show: true, type: ['line', 'bar'] },
                  restore: { show: true },
                  saveAsImage: { show: true }
                }
              },
              legend: {
                data: ['日声量数', '舆情总数']
              },
              dataZoom: [
                {
                  type: "inside",
                },
                {
                  startValue: xAxis[0],
                },
              ],
              xAxis: [
                {
                  type: 'category',
                  name: "日期",
                  data: xAxis,
                  axisPointer: {
                    type: 'shadow'
                  }
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  name: '日声量数',
                  axisLabel: {
                    formatter: '{value}'
                  }
                },
                {
                  type: 'value',
                  name: '舆情总数',
                  axisLabel: {
                    formatter: '{value}'
                  },
                }
              ],
              series: [
                {
                  name: '日声量数',
                  type: 'bar',
                  data: today
                },
                {
                  name: '舆情总数',
                  type: 'line',
                  yAxisIndex: 1,
                  data: total,
                  itemStyle: {
                    normal: {
                      color: "#FF509D",
                      barBorderRadius: 0,
                    }
                  }
                }
              ]
            };
            this.overEventLine = [xAxis, today, total];
            this.$nextTick(() => {
              let myChart = echarts.init(document.getElementById("echarts3"));
              myChart.clear();
              option && myChart.setOption(option, true);
            });
          }
        }
      } else {
        // 图例数组
        this.eventLineNames = [];
        // x时间数组
        this.eventLineTime = [];
        // 数据
        this.eventLineData = [];
        let obj = {};
        let res = await eventLine({
          topicId: this.id,
        });
        if (res.data.data) {
          // 接口返回数据
          this.eventLineList = res.data.data;
        }
        for (let i = 0; i < this.eventLineList.length; i++) {
          // 图标上方8个图标
          if (this.eventLineNames.indexOf(this.eventLineList[i].showname) == -1) {
            this.eventLineNames.push(this.eventLineList[i].showname);
            obj = {
              name: this.eventLineList[i].showname,
              type: "bar",
              symbol: "none",
              stack: "正",
              itemStyle: {
                color:
                  this.eventLineList[i].showname == "电视"
                    ? "#1D50FF"
                    : this.eventLineList[i].showname == "纸媒"
                      ? "#819DFF"
                      : this.eventLineList[i].showname == "网站"
                        ? "#1393FF"
                        : this.eventLineList[i].showname == "微博"
                          ? "#6EBCFF"
                          : this.eventLineList[i].showname == "微信公众号"
                            ? "#00B5A2"
                            : this.eventLineList[i].showname == "移动客户端"
                              ? "#85D9D0"
                              : this.eventLineList[i].showname == "互动栏目"
                                ? "#FFAE45"
                                : this.eventLineList[i].showname == "视频"
                                  ? "#FFDCA1"
                                  : "",
              },
              barWidth: 6,
              data: [],
            };
            // 八个对象 柱子
            this.eventLineData.push(obj);
          }
          // x轴
          if (this.eventLineTime.indexOf(this.eventLineList[i].showTime) == -1) {
            this.eventLineTime.push(this.eventLineList[i].showTime);
            this.eventLineTime.sort();
          }
        }
        this.eventLineData.forEach((it) => {
          this.eventLineTime.forEach((item) => {
            let off = false;
            this.eventLineList.forEach((i) => {
              if (item == i.showTime && it.name == i.showname) {
                off = true;
                if (it.data[it.data.length - 1]) {
                  it.data.push(it.data[it.data.length - 1] + i.todayComments);
                } else {
                  it.data.push(i.todayComments);
                }
              }
            });
            if (!off) {
              if (it.data[it.data.length - 1]) {
                it.data.push(it.data[it.data.length - 1]);
              } else {
                it.data.push(0);
              }
            }
          });
        });
        let deepCopyArray = JSON.parse(JSON.stringify(this.eventLineData));
        deepCopyArray.forEach((item) => {
          item.data.unshift(0);
          let arr = this.arraySubtraction(item.data);
          let wholeObj = {
            name: item.name + "(增量)",
            type: "line",
            symbol: "none",
            itemStyle: {
              color: item.itemStyle.color,
            },
            yAxisIndex: 1,
            data: [],
          };
          wholeObj.data = arr;
          this.eventLineData.push(wholeObj);
        });
        //  ezshidf
        let wholeObj = {
          name: "总体增量趋势",
          type: "line",
          symbol: "none",
          itemStyle: {
            color: "#FF509D",
          },
          yAxisIndex: 1,
          data: [],
        };
        this.eventLineTime.forEach((e) => {
          let arr = this.eventLineList.filter((n) => n.showTime == e); //找出相同时间的数组
          let num = 0;
          arr.forEach((i) => {
            num += i.todayComments; //累加相同时间的数据
          });
          wholeObj.data.push(num); //总体趋势data
        });
        this.eventLineData.push(wholeObj);
        this.minTime = new Date(this.eventLineTime[0]);
        this.maxTime = new Date(
          this.eventLineTime[this.eventLineTime.length - 1]
        );
        this.eventLineTimeShow = JSON.parse(JSON.stringify(this.eventLineTime));
        this.eventLineDataShow = JSON.parse(JSON.stringify(this.eventLineData));
        if (this.eventLineTime.length > 0) {
          setTimeout(() => {
            this.echarts3();
          }, 500);
        }
      }
    },
    // 首发媒体
    async firstMedia() {
      let res;
      if (this.topicType == 2) {
        res = await topicOverFirstType({
          topicId: this.id,
        });
      } else {
        res = await firstMedia({
          topicId: this.id,
        });
      }
      if (res.data.data) {
        this.firstMedias = res.data.data;
      }
    },
    mediaTypeSearch(item) {
      //点击数据跳转相关信息页
      if (item.showname == '抖音' || item.showname == '快手' || item.showname == '今日头条') {
        return false
      }
      if (item.totalComments) {
        this.$parent.jump({
          name: "全部信息",
          value: "information",
        });
        this.$nextTick(function () {
          if (this.topicType == 2) {
            this.$parent.$refs.dataAnalysis.$refs.fullDetail.mediaTypeS = [item.showname];
            this.$parent.$refs.dataAnalysis.$refs.fullDetail.checkAgain = true;
          } else {
            this.$parent.$refs.dataAnalysis.$refs.fullDetail.checkedCities3 = [item.showname];
            this.$parent.$refs.dataAnalysis.$refs.fullDetail.checkAgain = true;
          }
        });
      }
    },
    commentSearch(item) {
      if (item.showname == '抖音' || item.showname == '快手' || item.showname == '今日头条') {
        return false
      }
      //点击数据跳转相关评论页
      this.$parent.jump({
        name: "全部信息",
        value: "information",
      });
      this.$nextTick(function () {
        this.$parent.$refs.dataAnalysis.checkedCities5 = [item.showname];
        this.$parent.$refs.dataAnalysis.jump("评论情况");
        this.$parent.$refs.dataAnalysis.checkAgain = true;
      });
    },
    async dateChange(type) {
      if (type == 1 && !this.time[1]) {
        return;
      }
      if (type == 2 && !this.time[0]) {
        return;
      }
      if (this.topicType == 2) {
        let res = await topicOverProgress({
          topicId: this.id,
        });
        if (res.data.code == 200) {
          if (res.data.data.distributeBean) {
            this.overEventLineText[0] = this.$calcu.timeCycle(res.data.data.distributeBean.showTime, "yyyy年M月d日H时");
            this.overEventLineText[1] = res.data.data.distributeBean.todayComments;
          }
          if (res.data.data.list) {
            let data = res.data.data.list;
            let time = [];
            data.forEach(x => {
              time.push(x.showTime);
            });
            time = Array.from(new Set(time));
            time.sort();
            let start = new Date(time[0]);
            let end = new Date(time[time.length - 1]);
            let xAxis = [];
            while (start <= end) {
              xAxis.push(this.$calcu.timeCycle(start, "yyyy-MM-dd HH:mm:ss"));
              start = new Date(start.setHours(start.getHours() + 1));
            }
            let output = {};
            data.forEach(x => {
              if (output[x.showTime]) {
                output[x.showTime] += x.todayComments;
              } else {
                output[x.showTime] = x.todayComments;
              }
            });
            let today = [];
            let total = [];
            xAxis.forEach((x, i) => {
              if (i == 0) {
                total[i] = output[x];
                today[i] = output[x];
              } else if (output[x]) {
                total[i] = total[i - 1] + output[x];
                today[i] = output[x];
              } else {
                total[i] = total[i - 1];
                today[i] = 0;
              }
            });
            let option = {
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                  crossStyle: {
                    color: '#999'
                  }
                }
              },
              grid: {
                top: 75,
                containLabel: true
              },
              toolbox: {
                feature: {
                  dataView: { show: true },
                  magicType: { show: true, type: ['line', 'bar'] },
                  restore: { show: true },
                  saveAsImage: { show: true }
                }
              },
              legend: {
                data: ['日声量数', '舆情总数']
              },
              dataZoom: [
                {
                  type: "inside",
                },
                {
                  startValue: xAxis[0],
                },
              ],
              xAxis: [
                {
                  type: 'category',
                  name: "日期",
                  data: xAxis,
                  axisPointer: {
                    type: 'shadow'
                  }
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  name: '日声量数',
                  axisLabel: {
                    formatter: '{value}'
                  }
                },
                {
                  type: 'value',
                  name: '舆情总数',
                  axisLabel: {
                    formatter: '{value}'
                  },
                }
              ],
              series: [
                {
                  name: '日声量数',
                  type: 'bar',
                  data: today
                },
                {
                  name: '舆情总数',
                  type: 'line',
                  yAxisIndex: 1,
                  data: total,
                  itemStyle: {
                    normal: {
                      color: "#FF509D",
                      barBorderRadius: 0,
                    }
                  }
                }
              ]
            };
            this.overEventLine = [xAxis, today, total];
            this.$nextTick(() => {
              let myChart = echarts.init(document.getElementById("echarts3"));
              myChart.clear();
              option && myChart.setOption(option, true);
            });
          }
        }
      } else {
        // 图例数组
        this.eventLineNames = [];
        // x时间数组
        this.eventLineTime = [];
        // 数据
        this.eventLineData = [];
        let data = {
          topicId: this.id,
          startTime: this.time[0] + " 00:00:00",
          endTime: this.time[1] + " 23:59:59"
        }
        let obj = {};
        let res = await eventLine(data);
        if (res.data.data) {
          // 接口返回数据
          this.eventLineList = res.data.data;
        }
        for (let i = 0; i < this.eventLineList.length; i++) {
          // 图标上方8个图标
          if (this.eventLineNames.indexOf(this.eventLineList[i].showname) == -1) {
            this.eventLineNames.push(this.eventLineList[i].showname);
            obj = {
              name: this.eventLineList[i].showname,
              type: "bar",
              symbol: "none",
              stack: "正",
              itemStyle: {
                color:
                  this.eventLineList[i].showname == "电视"
                    ? "#1D50FF"
                    : this.eventLineList[i].showname == "纸媒"
                      ? "#819DFF"
                      : this.eventLineList[i].showname == "网站"
                        ? "#1393FF"
                        : this.eventLineList[i].showname == "微博"
                          ? "#6EBCFF"
                          : this.eventLineList[i].showname == "微信公众号"
                            ? "#00B5A2"
                            : this.eventLineList[i].showname == "移动客户端"
                              ? "#85D9D0"
                              : this.eventLineList[i].showname == "互动栏目"
                                ? "#FFAE45"
                                : this.eventLineList[i].showname == "视频"
                                  ? "#FFDCA1"
                                  : "",
              },
              barWidth: 6,
              data: [],
            };
            // 八个对象 柱子
            this.eventLineData.push(obj);
          }
          // x轴
          if (this.eventLineTime.indexOf(this.eventLineList[i].showTime) == -1) {
            this.eventLineTime.push(this.eventLineList[i].showTime);
            this.eventLineTime.sort();
          }
        }
        this.eventLineData.forEach((it) => {
          this.eventLineTime.forEach((item) => {
            let off = false;
            this.eventLineList.forEach((i) => {
              if (item == i.showTime && it.name == i.showname) {
                off = true;
                if (it.data[it.data.length - 1]) {
                  it.data.push(it.data[it.data.length - 1] + i.todayComments);
                } else {
                  it.data.push(i.todayComments);
                }
              }
            });
            if (!off) {
              if (it.data[it.data.length - 1]) {
                it.data.push(it.data[it.data.length - 1]);
              } else {
                it.data.push(0);
              }
            }
          });
        });
        let deepCopyArray = JSON.parse(JSON.stringify(this.eventLineData));
        deepCopyArray.forEach((item) => {
          item.data.unshift(0);
          let arr = this.arraySubtraction(item.data);
          let wholeObj = {
            name: item.name + "(增量)",
            type: "line",
            symbol: "none",
            itemStyle: {
              color: item.itemStyle.color,
            },
            yAxisIndex: 1,
            data: [],
          };
          wholeObj.data = arr;
          this.eventLineData.push(wholeObj);
        });
        //  ezshidf
        let wholeObj = {
          name: "总体增量趋势",
          type: "line",
          symbol: "none",
          itemStyle: {
            color: "#FF509D",
          },
          yAxisIndex: 1,
          data: [],
        };
        this.eventLineTime.forEach((e) => {
          let arr = this.eventLineList.filter((n) => n.showTime == e); //找出相同时间的数组
          let num = 0;
          arr.forEach((i) => {
            num += i.todayComments; //累加相同时间的数据
          });
          wholeObj.data.push(num); //总体趋势data
        });
        this.eventLineData.push(wholeObj);
        this.eventLineTimeShow = JSON.parse(JSON.stringify(this.eventLineTime));
        this.eventLineDataShow = JSON.parse(JSON.stringify(this.eventLineData));
        if (this.eventLineTime.length > 0) {
          setTimeout(() => {
            this.echarts3();
          }, 500);
        }
      }
    },
    eventSummary() {
      if (this.topicType == 1 || this.topicType == 0) {
        this.$http({
          url: "api/topic/viewPoint/eventSummary",
          method: "post",
          data: { topicId: this.id, organId: this.$store.state.user.organizationId }
        }).then(res => {
          this.incidentOverview = res.data.msg;
        });
      } else {
        this.$http({
          url: "api/topic/overseas/eventSummary",
          method: "post",
          data: { id: this.id, organId: this.$store.state.user.organizationId }
        }).then(res => {
          this.incidentOverview = res.data.msg;
        });
      }
    },
    echartsWordCloud(data) {
      let option = {
        series: [
          {
            type: "wordCloud",
            gridSize: 16,
            sizeRange: [16, 50],
            rotationRange: [0, 0],
            textStyle: {
              color: function () {
                return 'rgb(' + [
                  Math.round(Math.random() * 255),
                  Math.round(Math.random() * 255),
                  Math.round(Math.random() * 255)
                ].join(',') + ')';
              }
            },
            data: data
          }
        ]
      }
      echarts.init(document.getElementById("topics_wordCloud_topleft")).setOption(option);
    },
    wordCloudCreate() {//关键词云的创建
      frequency({
        topicId: this.id
      }).then(res => {
        if (res && res.data.code == 200) {
          let data = res.data.data;
          let output = [];
          data.forEach((x, index) => {
            if (index < 10) {
              output[index] = {
                number: index + 1,
                key: x.mainkeyword,
                frequency: x.amount
              }
            }
          });
          this.wordCloudData = output;
          this.hotWordData.show = data.map(x => x.mainkeyword);
          this.hotWordData.shield = JSON.parse(res.data.msg).filter(x => { if (x) return true });
          this.hotWordSetData = JSON.parse(JSON.stringify(this.hotWordData));
          let echartsData = data.map(x => { return { value: x.amount, name: x.mainkeyword } });
          this.$nextTick(() => { this.echartsWordCloud(echartsData) });
        }
      });
    },
    openHotWord() {//打开屏蔽热词页面
      this.hotWordSetData = JSON.parse(JSON.stringify(this.hotWordData));
      this.hotWordShow = true;
    },
    saveHotWord() {//保存屏蔽热词页面
      frequency({
        topicId: this.id,
        wordList: this.hotWordSetData.shield.length == 0 ? [""] : this.hotWordSetData.shield
      }).then(res => {
        if (res && res.data.code == 200) {
          this.$message.success("保存成功");
          this.wordCloudCreate();
          this.hotWordShow = false;
        }
      }).catch(err => {
        this.$message.warning(err);
      })
    },
    closeHotWord(x, type) {//点击热词
      switch (type) {
        case 1:
          this.hotWordSetData.shield.push(this.hotWordSetData.show.splice(this.hotWordSetData.show.indexOf(x), 1)[0]);
          break;
        case 2:
          this.hotWordSetData.show.push(this.hotWordSetData.shield.splice(this.hotWordSetData.shield.indexOf(x), 1)[0]);
          break;
      }
    },
    // 计算各平台增量
    arraySubtraction(array) {
      let brr = [];
      for (let i in array) {
        if (i > 0) {
          brr.push(array[i] - array[i - 1]);
        }
      }
      return brr;
    },
  },
  created() {
    this.getInfoAmountCount();//信息数量
    this.firstMedia();//首发媒体
    this.eventLine();//事件进展曲线
    this.eventSummary();//事件概况
  },
  updated() {
    if (this.tabledata.length > 0 && this.$store.state.user.organizationId != 2652) {
      this.$nextTick(() => {
        this.$refs.multipleTable.toggleRowSelection([this.tabledata[0]], true);
      });
    }
  },
  mounted() {
    if (this.topicType == 1) {
      this.commentLine();//评论曲线
    }
    if (this.topicType != 2) {
      this.wordCloudCreate();//关键词云
    }
  },
};
</script>

<style lang="scss" scoped>
.box1 {
  .box1-div1 {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;

    .box1-div1-top {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      padding: 24px 16px;
    }

    .box1-div1-center {
      padding: 0 44px 32px;

      div {
        width: 100%;
        background: #f9f9fc;
        border-radius: 8px;
        padding: 38px 40px;
        font-size: 14px;
        color: #333333;
        line-height: 28px;
      }
    }
  }

  .box1-div2 {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 16px;

    .box1-div2-top {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      padding: 24px 16px;
    }

    .box1-div2-center {
      min-height: 220px;
      display: flex;
      justify-content: space-between;
      padding: 0 44px 24px;

      .box1-div2-center-left {
        width: 50%;

        span {
          color: #02bc7c;
          font-size: 14px;
        }

        .box1-div2-center-left-title {
          height: 24px;
          font-size: 20px;
          font-weight: bold;
          color: #2e59ec;
        }

        .box1-div2-center-left-table {
          font-size: 14px;
          margin-top: 33px;
          width: 100%;
          background: #ffffff;
          border: 1px solid #e4e6ec;
          display: flex;
          flex-wrap: wrap;

          li {
            display: flex;
            flex: 1;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #f5f6fa;

            .box1-div2-center-left-table-left {
              width: 120px;
              text-align: center;
              border-right: solid 2px #fff;
              border-left: solid 2px #fff;
            }

            .box1-div2-center-left-table-right {
              width: 260px;
              text-align: center;
              cursor: pointer;
            }
          }

          li:nth-child(3),
          li:nth-child(4),
          li:nth-child(7),
          li:nth-child(8),
          li:nth-child(11),
          li:nth-child(12) {
            background: #ffffff;
          }

          li:nth-child(1),
          li:nth-child(3),
          li:nth-child(5),
          li:nth-child(7) {
            .box1-div2-center-left-table-left {
              border-left: none;
            }
          }
        }
      }

      .box1-div2-center-right {
        width: 50%;

        #echarts1 {
          width: 100%;
          height: 300px;
        }

        #echarts2 {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .box1-div3 {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 16px;

    .box1-div3-top {
      padding: 24px 16px;
      display: flex;
      justify-content: space-between;

      .box1-div3-top-left {
        display: flex;
        align-items: center;

        div {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
          color: #2e59ec;
          margin-left: 24px;
          cursor: pointer;
        }
      }

      .box1-div3-top-right {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #999999;

        .noSelectdiv {
          padding: 2px 10px;
          border-radius: 4px;
          border: 1px solid #e5e5e5;
          margin-left: 16px;
          cursor: pointer;
          font-size: 14px;
          color: #999999;
        }

        .Selectdiv {
          padding: 2px 10px;
          border-radius: 4px;
          margin-left: 16px;
          cursor: pointer;
          border: 1px solid #2e59ec;
          background: #2e59ec;
          font-size: 14px;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }

    #echarts3 {
      height: 375px;
      width: 100%;
      padding: 24px 40px;
    }
  }

  .box1-div4 {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 16px;

    .box1-div4-top {
      display: flex;
      align-items: center;
      padding: 24px 16px;

      div {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        color: #2e59ec;
        margin-left: 24px;
        cursor: pointer;
      }
    }

    .box1-div4-center {
      display: flex;

      .box1-div4-center-left {
        width: 492px;
        background: #ffffff;
        padding: 40px;

        ::v-deep .el-table .warning-row {
          color: #2e59ec !important;
        }

        ::v-deep .has-gutter .el-checkbox {
          display: none !important;
        }
      }

      #echarts4 {
        height: 340px;
        width: 100%;
      }
    }
  }

  .box1-div5 {
    width: 100%;
    /* height: 255px; */
    background: #ffffff;
    border-radius: 4px;
    margin-top: 16px;
    padding-bottom: 24px;

    .box1-div5-top {
      display: flex;
      align-items: center;
      padding: 24px 16px;

      div {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        color: #2e59ec;
        margin-left: 24px;
        cursor: pointer;
      }
    }

    .box-div {
      margin-left: 37px;
      width: 1556px;
      border: 1px solid rgb(228, 230, 236);

      .box-hader {
        color: #333333;
        font-size: 14px;
        display: flex;
      }

      .box-body {
        color: #333333;
        font-size: 14px;
        display: flex;
      }
    }

    .box-div1 {
      border: 2px solid #fff;
      text-align: center;
      width: 240px;
    }

    .box-div2 {
      border-right: 2px solid #fff;
      text-align: center;
      width: 180px;
    }

    .box-div3 {
      text-align: center;
      width: 584px;
    }

    .box-div-color {
      background: #f5f6fa;
    }
  }
}

.box1-div4-center .el-table::before {
  left: 0;
  bottom: 0;
  height: 0;
}

.box-div-span {
  padding: 10px 0;
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  line-height: 2;
}

.box-div-title {
  height: 40px;
  line-height: 40px;
}

.analyse-container {
  width: 100%;
  height: 250px;
  background: #ffffff;
  border-radius: 4px;
  /* margin-top: 16px; */
  text-align: center;
}

.analyse-container-img {
  position: relative;
  top: 40%;
  transform: translateY(-50%);
}

.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}

.box1-div2-center-right-box {
  width: 769px;
  display: flex;
}

.website {
  cursor: pointer;
}

.website:hover {
  color: #2e59ec;
}

.box1-div2-center-left-table-noData {
  display: flex;
  flex: 1;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #f5f6fa;
}

#topics_wordCloud {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  padding-bottom: 24px;
  overflow: hidden;

  .analyse-container {
    width: 50%;
    float: left;
  }
}

.topics_wordCloud_top {
  display: flex;
  align-items: center;
  padding: 24px 16px;
}

.topics_wordCloud_top span {
  font-size: 14px;
  color: #2e59ec;
  cursor: pointer;
  font-weight: bold;
}

.topics_wordCloud_top div {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #2e59ec;
  cursor: pointer;
  font-weight: bold;
  margin-left: 25px;
}

.topics_wordCloud_top span i {
  margin-right: 3px;
  font-weight: bold;
  font-size: 12px;
  color: #2e59ec;
}

#topics_wordCloud_topleft {
  width: 50%;
  height: 400px;
  float: left;
}

.topics_wordCloud_topright {
  width: 50%;
  float: right;
}

.topics_hotWord_box {
  display: flex;
  flex-wrap: wrap;
  user-select: none;
}

.topics_hotWord_button {
  margin: 5px;
  padding: 0 15px;
  height: 35px;
  background-color: #eff7ff;
  border-radius: 3px;
  text-align: center;
  line-height: 35px;
  font-size: 13px;
  color: #446bef;
}

.el-icon-close::before {
  color: #446bef;
}

.el-icon-close:hover::before {
  color: white;
}

.el-icon-close {
  border-radius: 50%;
  padding: 1px;
  font-size: 14px;
  cursor: pointer;
}

.el-icon-close:hover {
  background-color: #409EFF;
}

.topics_hotWord_shield {
  .topics_hotWord_button {
    background-color: #fff3f4;
    color: #ea3342;
  }

  .el-icon-close:hover {
    background-color: #F56C6C;
  }

  .el-icon-close::before {
    color: #ea3342;
  }

  .el-icon-close:hover::before {
    color: white;
  }
}

#zb_line_text {
  font-size: 14px;
  cursor: auto;
  font-weight: normal;
  color: #999;
}

#zb_line_text span {
  color: #2e59ec;
}
</style>
