<template>
  <div class="allView">
    <!-- 网民 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="1400px"
    >
      <div slot="title" class="dialog-header">
        <div class="title-box">
          <span class="el-dialog__title">设置突出样本</span>
        </div>
      </div>
      <!-- 中间部分 -->
      <div class="dialog-body">
        <div v-loading="loading" class="input-box">
          <ul class="box3-div5-media-table">
            <li v-for="item in lookList" :key="item.viewpointId">
              <div class="liDiv2 media-table-liDiv2s">
                <el-radio-group v-model="radios">
                  <el-radio :label="item">{{item.viewName}}</el-radio>
                </el-radio-group>
              </div>
              <div class="liDiv3">
                <span v-html="ite.showContent" v-for="ite in item.list" :key="ite.commentId"></span>
              </div>
              <div
                class="liDiv4"
              >{{(item.list.length/radioList.sampleName)*100+'%'}} ({{ item.list.length}})</div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 中间部分 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" plain type="primary" @click="dialogVisible = false">取消</el-button>
        <el-button size="medium" :disabled="Object.keys(radios).length == 0" type="primary" @click="addData">保存</el-button>
      </span>
    </el-dialog>

    <!-- 组件 -->
  </div>
</template>

<script>
import {
  addViewList,
  editComment,
  editNumComment
} from "../../../api/topic/topic";
export default {
  data() {
    return {
      dialogVisible: false,
      radios: {},
      // 网民观点列表
      lookList: [],
      radioList: {},
      loading: false,
      // 当前评论item
      commentItem: {},
      list: [], //用来存放批量选择的commentId,
      type: "1"
    };
  },
  methods: {
    // 打开弹框
    openDialog(radio, commentItem, type) {
      this.dialogVisible = true;
      this.radioList = radio;
      this.type = type;
      if (type == 1) {
        //单个选择
        this.commentItem = commentItem;
      } else {
        this.list = commentItem;
      }
      this.addViewList();
    },
    // 网民观点列表
    async addViewList() {
      this.radios = {};
      this.loading = true;
      const res = await addViewList({
        topicId: this.radioList.topicId,
        totalNum: this.radioList.sampleName,
        sampleId: this.radioList.sampleId
      });
      if (res.data.rows) {
        this.lookList = res.data.rows;
        this.lookList.forEach(item => {
          item.list.forEach((it, index) => {
            if (index != 0) {
              let reg = new RegExp("@", "g"); //g代表全部
              it.showContent = it.showContent.replace(reg, "<br/>" + "@");
            }
          });
        });
      }
      this.loading = false;
    },
    // 保存突出样本
    async addData() {
      let data = {
        viewpointId: this.radios.viewpointId,
        isSetSample: 1,
        sampleId: this.radioList.sampleId
      };
      let res;
      if (this.type == 1) {
        data.commentId = this.commentItem.commentId;
        res = await editComment(data);
      } else {
        data.list = this.list;
        res = await editNumComment(data);
      }

      if (res.data.code == 200) {
        this.$message({
          message: "设置成功！",
          type: "success"
        });
      } else {
        this.$message.error("设置失败！");
      }
      this.dialogVisible = false;
      this.radios = {};
      this.$emit("getListHight");
    }
  },
  created() {}
};
</script>

<style lang='scss' scoped>
.box3-div5-media-btn {
  display: flex;
  padding: 24px 44px;

  img {
    margin-right: 5px;
  }

  .box3-div5-media-btn-right {
    cursor: pointer;
    margin-left: 32px;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #ea3342;
  }
}

.box3-div5-media-table {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e4e6ec;

  li:nth-child(2n + 1) {
    background: #f5f6fa;
  }

  li {
    width: 100%;
    display: flex;
    line-height: 40px;

    .liDiv2 {
      display: flex;
      align-items: center;
      width: 25%;
      border-right: 1px solid #e4e6ec;
      padding-left: 24px;
      .el-radio-group {
        width: 100%;
      }
      .el-radio__label {
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }

    .liDiv3 {
      border-right: 1px solid #e4e6ec;
      width: 60%;
      padding-left: 24px;
    }

    .liDiv4 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15%;
    }
  }
}
.title-box {
  display: flex;
  align-items: center;
  .el-dialog__title {
    margin-right: 25px;
  }
}
</style>