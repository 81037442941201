<template>
  <div class="box2 analyse">
    <div class="box2-div1">
      <div class="box2-div1-top">
        <img src="../../assets/img/views/monitoringScheme/quanquan.png" alt />
        媒体热力
      </div>
      <div style="padding:0 40px">
        <ul class="box2-div1-tag">
          <li v-for="(item, inx) in tag" :key="inx" @click="jump(item)" :class="num == item ? 'Selectli' : 'NoSelect'">
            {{
            item }}
          </li>
        </ul>
      </div>
      <div id="mapEcharts"></div>
      <img class="map" src="../../assets/img/views/map.png" />
    </div>
    <div id="zb_topics_focus">
      <div class="zb_topics_title">
        <img src="../../assets/img/views/monitoringScheme/quanquan.png" alt />
        重点媒体
        <span>数据监测期间，引起媒体跟踪报告<i>{{ focusMediaData.data[0] }}</i>家，其中央级<i>{{ focusMediaData.data[1] }}</i>家，省级媒体<i>{{
            focusMediaData.data[2] }}</i>家，市级媒体<i>{{ focusMediaData.data[3] }}</i>家，其他媒体<i>{{ focusMediaData.data[4]
            }}</i>家。共计<i>{{ focusMediaData.num }}</i>篇相关报导。</span>
      </div>
      <div v-if="focusMediaData.show">
        <div id="zb_topics_media">
          <div style="background-color:#fff0f6" @click="getFocusMedia(undefined)">
            <div class="zb_topics_focus_num">
              <h1>{{ focusMediaData.data[0] }}</h1>
              <span>全部媒体</span>
            </div>
            <div id="zb_topics_focus_echarts1" class="zb_topics_focus_echarts"></div>
          </div>
          <div style="background-color:#e7fcfa" @click="getFocusMedia('央级')">
            <div class="zb_topics_focus_num">
              <h1>{{ focusMediaData.data[1] }}</h1>
              <span>央级媒体</span>
            </div>
            <div id="zb_topics_focus_echarts2" class="zb_topics_focus_echarts"></div>
          </div>
          <div style="background-color:#fef3e0" @click="getFocusMedia('省级')">
            <div class="zb_topics_focus_num">
              <h1>{{ focusMediaData.data[2] }}</h1>
              <span>省级媒体</span>
            </div>
            <div id="zb_topics_focus_echarts3" class="zb_topics_focus_echarts"></div>
          </div>
          <div style="background-color:#e5eeff" @click="getFocusMedia('市级')">
            <div class="zb_topics_focus_num">
              <h1>{{ focusMediaData.data[3] }}</h1>
              <span>市级媒体</span>
            </div>
            <div id="zb_topics_focus_echarts4" class="zb_topics_focus_echarts"></div>
          </div>
          <div style="background-color:#f4e8ff" @click="getFocusMedia('其他')">
            <div class="zb_topics_focus_num">
              <h1>{{ focusMediaData.data[4] }}</h1>
              <span>其他媒体</span>
            </div>
            <div id="zb_topics_focus_echarts5" class="zb_topics_focus_echarts"></div>
          </div>
        </div>
        <table id="zb_topics_focus_table">
          <tr style="background-color:#f5f6fa;font-weight: bold;color: #111111;">
            <td>媒体类型</td>
            <td>媒体名称</td>
            <td>舆情数</td>
          </tr>
          <tr v-for="x, i in focusMediaData.table" :key="i">
            <td>{{ x.levels }}媒体</td>
            <td>{{ x.media }}</td>
            <td>{{ x.total }}</td>
          </tr>
          <tr v-show="focusMediaData.table.length == 0">
            <td colspan="3">暂无数据</td>
          </tr>
        </table>
      </div>
      <div class="analyse-container" v-else>
        <div class="analyse-container-img">
          <img src="../../assets/img/views/analyse-img.png" />
          <p>暂无数据</p>
        </div>
      </div>
    </div>
    <!-- 信息地域 -->
    <div class="zb_topics_box">
      <div class="zb_topics_title">
        <img src="../../assets/img/views/monitoringScheme/quanquan.png" alt />
        信息地域
        <span>信息发布地域分布</span>
      </div>
      <div id="infoMap"></div>
      <img class="map" src="../../assets/img/views/map.png" />
    </div>
    <!-- 热榜分布 -->
    <div class="zb_topics_box">
      <div class="zb_topics_title">
        <img src="../../assets/img/views/monitoringScheme/quanquan.png" alt />
        热榜分布
      </div>
      <div style="padding: 0 16px">
        <el-table v-loading="loadingTable" :data="rstableData" stripe :header-cell-style="headerStyle"
          :cell-style="cellStyle" style="width: 100%; text-align: center; border: 1px solid #e4e6ec">
          <el-table-column label="热榜平台">
            <template slot-scope="scope">
              <div style="float:left;padding-left: 30%; width: 100%;display: flex;"> <img width="24px" height="24px"
                  style="margin-right:10px" :src="hotTypeImgList[scope.row.hotType]"> {{ hotTypeList[scope.row.hotType]
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="最高排名">
            <template slot-scope="scope">
              <p> {{ scope.row.ranking ? scope.row.ranking : 'top' }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="title" label="热榜标题"></el-table-column>
          <el-table-column label="最高热度">
            <template slot-scope="scope">
              <p> {{ scope.row.ranking ? formatNumber(scope.row.clicks) : "置顶热榜" }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="StartTime" label="上榜时间"></el-table-column>
          <el-table-column label="退榜时间">
            <template slot-scope="scope">
              <p> {{ scope.row.endTime ? scope.row.endTime : '暂未退榜' }}</p>
            </template>
          </el-table-column>
          <el-table-column label="持续时间">
            <template slot-scope="scope">
              <p> {{ toHourMinute(scope.row.continuedTime) }}</p>
            </template>
          </el-table-column>
        </el-table>
        <!-- 页码-->
        <div class="block">
          <span class="total" style="left:8.5%">
            共{{ tablePage.total }}条, 当前显示第{{
            tablePage.page * tablePage.pageSize - tablePage.pageSize + 1
          }}-{{
              tablePage.page * tablePage.pageSize > tablePage.total
                ? tablePage.total
                : tablePage.page * tablePage.pageSize
            }}条
          </span>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="tablePage.page" :page-sizes="tablePage.pageSizes" :page-size="tablePage.pageSize"
            layout="sizes, prev, pager, next,jumper" :total="tablePage.total"></el-pagination>
        </div>
      </div>
    </div>
    <!-- <div class="box2-div2">
      <div class="box2-div2-top">
        <img src="../../assets/img/views/monitoringScheme/quanquan.png" alt />
        （原创）重点微博传播
      </div>
      <div class="box2-div2-center" v-if="tabledata && tabledata.length>0">
        <div class="box2-div2-center-left">
          <ul>
            <li>
              <div class="li-left"></div>
              <div class="li-center">微博名称</div>
              <div class="li-center">转发数量</div>
              <div class="li-right">内容</div>
            </li>
            <li v-for="(val,inx) in tabledata.slice(0,5)" :key="inx">
              <div class="li-left1">
                <el-radio @change="changeRedio" v-model="radio1" :label="val">{{'转发最高第'+(inx+1) }}</el-radio>
              </div>
              <div class="li-center1">{{val.name}}</div>
              <div class="li-center1">{{val.forwardCount}}</div>
              <div class="li-right1">{{val.content}}</div>
            </li>
          </ul>
        </div>
        <div id="echarts2"></div>
      </div>

      <div class="analyse-container" v-else>
        <div class="analyse-container-img">
          <img src="../../assets/img/views/analyse-img.png" />
          <p>暂无数据</p>
        </div>
      </div>
    </div>-->
    <!-- 媒体观点 -->
    <div class="box2-div3" v-if="$store.state.user.organizationId != 2652">
      <div class="box2-div2-top">
        <img src="../../assets/img/views/monitoringScheme/quanquan.png" alt />
        媒体观点分析
      </div>
      <div v-loading="dataListLoading">
        <div class="box2-div2-center" v-show="dataList && dataList.length > 0">
          <div id="mediafx" class="box2-div2-center-left" style="padding: 0 60px 0px 45px;">
            <ul>
              <li style="padding-left:0">
                <div class="mediaView-div1"></div>
                <div class="mediaView-div2">数据量</div>
                <div class="mediaView-div3">数据占比</div>
              </li>
              <li class="box2-div2-center-left-box" v-for="(item, inx) in dataList" :key="inx">
                <div class="box2-div2-center-left-text mediaView-div4">
                  <el-tooltip class="item" effect="dark" :content="item.viewName" placement="top">
                    <el-radio-group @change="changeRadio" v-model="radio">
                      <el-radio :label="item">{{ item.viewName }}</el-radio>
                    </el-radio-group>
                  </el-tooltip>
                </div>
                <div class="mediaView-div5">{{ item.useCount }}</div>
                <div v-if="item.count != 0" class="mediaView-div6">
                  {{ item.useCount ? ((item.useCount / Number(item.count)) * 100).toFixed(2) + '%' : '无' }}</div>
                <div v-else class="mediaView-div6">无</div>
              </li>
            </ul>
          </div>
          <div id="echarts3-my"></div>
        </div>
        <div class="analyse-container" v-show="!dataList && dataList.length <= 0">
          <div class="analyse-container-img">
            <img src="../../assets/img/views/analyse-img.png" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 网民观点 -->
    <div v-if="topicType == 1 && $store.state.user.scope[2] && $store.state.user.organizationId != 2652"
      class="box2-div2">
      <div class="box2-div2-top" style="justify-content: space-between;">
        <div class="img-box">
          <img src="../../assets/img/views/monitoringScheme/quanquan.png" alt />
          网民观点分析
        </div>
        <el-select style="width: 280px;" v-model="sampling" placeholder="请选择" value-key="sampleId"
          @change="optionsChange">
          <el-option v-for="item in options" :key="item.sampleId"
            :label="item.createTime + ' ' + item.remark + '(' + item.sampleName + ')'" :value="item"></el-option>
        </el-select>
      </div>
      <div v-loading="veiwListLoading">
        <div class="box2-div2-center" v-if="viewPointList && viewPointList.length != 0">
          <!-- 观点列表 -->
          <div id="netizenView" class="box2-div2-center-left"
            style="width: 496px;padding: 0;margin-bottom: 25px;margin-left: 44px;">
            <div class="viewPoint-left-text">
              <span class="viewPoint_text" style="float: left;">{{ samplingName }}</span>
              <span class="viewPoint_text" style="float: right;">样本总数：{{ samplingCount }}</span>
            </div>
            <ul style="width: 100%;">
              <li>
                <div class="li-left" style="width: 294px;"></div>
                <div class="li-center">数据量</div>
                <div class="li-centerNum">数据占比</div>
              </li>
              <li v-for="(val, inx) in viewPointList" :key="inx">
                <div class="li-left1" style="width: 294px;text-align: left;padding-left: 18px;">
                  <el-tooltip class="item" effect="dark" :content="val.viewName" placement="top">
                    <el-radio @change="radioChange" class="redioText" v-model="radio2" :label="val">{{ val.viewName
                      }}</el-radio>
                  </el-tooltip>
                </div>
                <div class="li-center1">{{ val.viewMatch }}</div>
                <div class="li-center2">{{ val.viewMatch ? ((val.viewMatch / val.totalNum) * 100).toFixed(2) + '%' :
            '0%'
                  }}</div>
              </li>
            </ul>
          </div>
          <!-- 右边的评论列表 -->
          <div class="loadingContent-box" v-loading="loadingContent">
            <div v-if="commitList && commitList.length != 0" class="viewPoint-right">
              <div class="viewPoint-right-font">突出样本 ({{ commitList.length }})</div>
              <div class="viewPoint-right-content">
                <div class="viewPoint-div" v-for="item in commitList" :key="item.commentId">
                  <div class="viewPoint-top">
                    <div class="viewPoint-tag">{{ item.mediaLink }}</div>
                    <span class="viewPoint-top-text">@{{ item.nickname }}:</span>
                    <span class="viewPoint-top-font">{{ item.content }}</span>
                  </div>
                  <div class="viewPoint-right-center">
                    <span class="viewPoint-right-content-text">{{ item.source }} |:</span>
                    <span class="viewPoint-right-content-font">
                      <a :href="item.website" target="_blank">{{ item.title }}</a>
                    </span>
                  </div>
                  <div class="viewPoint-right-bottom">
                    <span class="viewPoint-right-bottom-text">网民观点: {{ item.viewName }}</span>
                    <span class="viewPoint-right-bottom-font">{{ item.commentTime }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="analyse-container" style="height:100%" v-else>
              <div class="analyse-container-img">
                <img src="../../assets/img/views/analyse-img.png" />
                <p>暂无数据</p>
              </div>
            </div>
          </div>
        </div>
        <div class="analyse-container" v-else style="height:250px">
          <div class="analyse-container-img">
            <img src="../../assets/img/views/analyse-img.png" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import * as echarts from "echarts";
import map from "../../assets/js/China1.json";
import {
  microBlog,
  viewPoint,
  mediaHot,
  topicSampleList,
  addViewList,
  commentList,
  topicHostSearch,
  ascription
} from "../../api/topic/topic";
export default {
  name: "analysis",
  data() {
    return {
      loadingTable: false,
      //页数
      tablePage: {
        pageSizes: [10, 20, 30, 40, 50],
        page: 1,
        pageSize: 10,
        total: 0,
      },
      hotTypeList: {
        1: '百度',
        2: '今日头条(全国)',
        3: '今日头条(本地)',
        4: '知乎',
        5: '快手',
        6: '新浪',
        7: '抖音(热榜)',
        8: '抖音(社会)',
        9: '抖音(本地)',
        10: '哔哩哔哩'
      },
      hotTypeImgList: {
        1: require('.././../assets/img/views/meiti/baidu.png'),
        2: require('.././../assets/img/views/meiti/toutiao.png'),
        3: require('.././../assets/img/views/meiti/toutiao.png'),
        4: require('.././../assets/img/views/meiti/zhihu.png'),
        5: require('.././../assets/img/views/meiti/kuaishou.png'),
        6: require('.././../assets/img/views/meiti/weibo.png'),
        7: require('.././../assets/img/views/meiti/douyin.png'),
        8: require('.././../assets/img/views/meiti/douyin.png'),
        9: require('.././../assets/img/views/meiti/douyin.png'),
        10: require('.././../assets/img/views/meiti/bzhan.png'),
      },
      headerStyle: {
        background: "#F6F7FA",
        textAlign: "center",
        fontSize: "16px",
        color: "#333",
      },
      cellStyle: {
        textAlign: "center",
      },
      rstableData: [],
      tag: [
        "全部",
        "电视",
        "纸媒",
        "网站",
        "微博",
        "微信公众号",
        "移动客户端",
        "互动栏目",
        "视频"
      ],
      // 网民观点分析的loading
      veiwListLoading: false,
      loadingContent: false,
      num: "全部",
      tabledata: [],
      radio: {},
      radio1: "1",
      dataList: [],
      myChart: "",
      radio2: {},
      viewPointList: [],
      //   抽样选择下拉框
      options: [
        {
          sampleId: 269,
          sampleName: 50,
          remark: "抽样一",
          createTime: "2021-12-08 11:33:04",
          topicId: 9,
          isDel: 0
        }
      ],
      //  抽样选择绑定
      sampling: "",
      //   抽样名字
      samplingName: "",
      //   抽样总数
      samplingCount: 0,
      //   抽样id
      samplingiD: "",
      //   评论列表
      commitList: [],
      // 当前网民观点的样本数量
      pageSize: 0,
      // 媒体观点分析加载状态
      dataListLoading: false,
      //重点媒体数据
      focusMediaData: {
        show: false,
        data: [0, 0, 0, 0, 0],
        table: [],
        num: 0
      }
    };
  },
  props: ["id", "topicType"],
  created() {
    // this.microBlog();
    this.mediaHot();
    this.topicSampleList();
    this.getFocus();
    this.topicHostSearch()
  },
  mounted() {
    this.createdInfoMap();
    this.viewPoint();
  },
  methods: {
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.tablePage.page = 1;
      this.topicHostSearch();
    },
    handleCurrentChange(val) {
      this.tablePage.page = val;
      this.topicHostSearch();
    },
    // 计算万单位
    formatNumber(num) {
      num = Number(num);
      if (num == 0) {
        return num + '';
      } else
        if (num > 1 && num < 10000) {
          return num + '';
        } else {
          return (num / 10000).toFixed(2) + '万';
        }
    },
    toHourMinute(time) {
      if (time !== "0" && time !== "" && time !== null) {
        let Hour = parseInt(time / 60 / 60000);
        let Minute = parseInt(((time / 60 / 60000) - Hour) * 60);
        return (
          (Hour.length < 2 ? "0" + Hour : Hour) + "小时" +
          (Minute.length < 2 ? "0" + Minute : Minute) + "分钟");
      } else {
        return "";
      }
    },
    // 热搜分布
    async topicHostSearch() {
      this.loadingTable = true
      let { pageSize, page } = this.tablePage
      const Res = await topicHostSearch({
        id: this.id,
        pageNum: page,
        pageSize
      })
      this.rstableData = Res.data.rows
      this.tablePage.total = Res.data.total;
      this.loadingTable = false
    },
    // 重点微博
    async microBlog() {
      const res = await microBlog({
        topicId: this.id
      });
      this.tabledata = res.data.data;
      this.radio1 = this.tabledata[0];
      if (this.tabledata && this.tabledata.length > 0) {
        setTimeout(() => {
          this.echarts2(this.radio1);
        }, 500);
      }
    },
    changeRedio(val) {
      this.echarts2(val);
    },
    jump(item) {
      this.num = item;
      this.mediaHot(item);
    },
    // 媒体热力图
    async mediaHot(item) {
      // let mapDataList =[];
      const res = await mediaHot({
        topicId: this.id,
        mediaLink: item == "全部" ? "" : item
      });
      if (res != undefined) {
        let MapDate = [];
        res.data.data.forEach(item => {
          if (item.mediaLink != "其他" && item.mediaLink != "全国") {
            item.name = item.mediaLink;
            item.value = item.provinceSum;
            MapDate.push(item);
          }
        });
        MapDate.sort((a, b) => {
          return a.provinceSum - b.provinceSum;
        });
        this.GetMap(MapDate);
      }
    },
    // 观点分析
    async viewPoint() {
      this.dataListLoading = true;
      const res = await viewPoint({
        topicId: this.id
      });
      if (res != undefined) {
        if (res.data.data && res.data.data.length != 0) {
          this.dataList = res.data.data;
          this.radio = this.dataList[0];
          this.changeRadio();
        }
      }
      this.dataListLoading = false;
    },
    changeRadio() {
      this.$store.state.radio = this.radio;
      let dataEchart = [];
      if (this.radio.list.length != 0) {
        this.radio.list.forEach(ite => {
          let obj = {
            category: ite.levels,
            name: ite.source + " - " + ite.levels,
            itemStyle: {
              shadowColor:
                ite.levels == "全国"
                  ? "rgba(255, 85, 117, 0.21)"
                  : ite.levels == "省级"
                    ? "rgba(255, 146, 83, 0.17)"
                    : ite.levels == "市级"
                      ? "rgba(25, 201, 183, 0.21)"
                      : "rgba(92, 146, 255, 0.28)",
              shadowBlur: 10
            },
            symbolSize:
              ite.levels == "全国"
                ? 96
                : ite.levels == "省级"
                  ? 76
                  : ite.levels == "市级"
                    ? 56
                    : 36
          };
          dataEchart.push(obj);
        });
      }
      this.echarts3(dataEchart);
      this.$store.state.dataListTopic.viewAnalysis = "";
      this.$nextTick(() => {
        html2canvas(document.querySelector("#mediafx")).then(resolve => {
          let imgUrl = resolve.toDataURL("image/png");
          this.$store.state.dataListTopic.viewAnalysis = imgUrl;
        });
      });
    },
    GetMap(mapData) {
      let data = {
        region: [],
        value: [],
        average: [],
      };
      let sum = 0;
      mapData.forEach(x => {
        data.region.push(x.name);
        data.value.push(x.value);
        sum += x.value;
      });
      data.region = data.region.reverse();
      data.value = data.value.reverse();
      data.value.forEach(() => {
        data.average.push(sum / data.value.length);
      });
      if (data.region.length >= 10) {
        data.region.splice(10);
      } else {
        while (data.region.length < 10) {
          data.region.push("");
        }
      }
      echarts.registerMap("China", map);
      let myChart = echarts.init(document.getElementById("mapEcharts"));
      let option = {
        title: [
          {
            show: true,
            text: 'TOP 10 排行榜',
            right: 40,
            textStyle: { color: '#666666', fontSize: 12 }
          }
        ],
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(255, 255, 255, 0.98)",
          borderWidth: 0,
          formatter: function (params) {
            if (params.data == undefined) {
              return "";
            }
            let str = `<div>
            <p style="width:200px;display:flex;justify-content: space-between;">
            <span style="font-size: 14px;font-weight: 550;color: #2E59EC;">${params.data.name}</span>
            <span style="font-size: 14px;font-weight: 550;color: #2E59EC;">${params.data.value}</span>
            </p></div>`;
            return str;
          }
        },
        legend: { show: false },
        grid: {
          show: true,
          containLabel: false,
          right: 40,
          top: 50,
          bottom: 30,
          width: '20%',
        },
        visualMap: {
          type: 'continuous',
          min: 0,
          max: mapData[mapData.length - 1] && mapData[mapData.length - 1].value ? mapData[mapData.length - 1].value : 1,
          text: ['多', '少'],
          seriesIndex: [0, 2],
          dimension: 0,
          realtime: false,
          left: 50,
          bottom: 50,
          itemWidth: 18,
          itemHeight: 100,
          calculable: true,
          inRange: {
            color: [
              "#e6ecfc",
              "#addbff",
              "#5cb8ff",
              "#6fa5ff",
              "#3779ff",
              "#3b64f1"
            ]
          },
          outOfRange: {
            color: ['#eeeeee'],
          },
        },
        toolbox: {
          show: false,
        },
        xAxis: [{
          type: 'value',
          position: 'top',
          inside: false,
          axisLabel: { show: false },
          splitLine: { show: false },
          margin: 10,
        }],
        yAxis: [{
          type: 'category',
          boundaryGap: true,
          axisLine: { show: false },
          axisLabel: { align: 'right', margin: 10, showMaxLabel: true },
          inverse: true,
          data: data.region,
        }],
        series: [
          {
            name: '占比',
            type: 'map',
            mapType: 'China',
            left: '100',
            width: '60%',
            mapValueCalculation: 'sum',
            zoom: 1,
            top: 10,
            selectedMode: false,
            showLegendSymbol: false,
            label: {
              normal: { show: true, color: "#fff", fontSize: 9 },
              emphasis: { show: true, color: "#fff" },
            },
            itemStyle: {
              normal: { areaColor: "#e6ecfc", borderColor: "#fff" },
              emphasis: { areaColor: '#3b64f1', borderWidth: 2 },
            },
            data: mapData,
          },
          {
            name: '背景',
            type: 'bar',
            roam: false,
            visualMap: false,
            itemStyle: { color: '#eeeeee', opacity: 0.5 },
            label: { show: false },
            emphasis: {
              itemStyle: { color: '#eeeeee', opacity: 0.5 },
              label: { show: false },
            },
            silent: true,
            barWidth: 18,
            barGap: '-100%',
            data: data.average,
            animation: false,
          },
          {
            name: '占比',
            type: 'bar',
            roam: false,
            visualMap: false,
            barWidth: 18,
            label: {
              normal: { show: true, fontSize: 12, position: 'insideLeft' },
              emphasis: { show: true, },
            },
            tooltip: {
              show: false
            },
            data: data.value,
          },
        ],
      };
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
    echarts2(val) {
      let weiboData = val;
      if (
        this.myChart != null &&
        this.myChart != "" &&
        this.myChart != undefined
      ) {
        this.myChart.dispose();
      }
      this.myChart = echarts.init(document.getElementById("echarts2"));
      var colors = ["#FFAA54", "#3A7FFF", "#82A1DD"];
      var getdata = function getData() {
        let data = {
          children: []
        };
        if (weiboData) {
          if (weiboData.name) {
            data.name = weiboData.name;
          }
          if (weiboData.forwardCount) {
            data.value = weiboData.forwardCount;
          }
          if (weiboData.list.length != 0) {
            for (let i = 0; i < weiboData.list.length; i++) {
              let obj = {
                name: weiboData.list[i].name,
                value: weiboData.list[i].forwardCount,
                children: []
              };
              for (let j = 0; j < weiboData.list[i].list.length; j++) {
                let obj2 = {
                  name: weiboData.list[i].list[j].name,
                  value: weiboData.list[i].list[j].forwardCount
                };

                obj.children.push(obj2);
              }

              data.children.push(obj);
            }
          }
        }

        let arr = [];
        arr.push(data);
        //
        arr = handle(arr, 0);
        return arr;
      };
      var handle = function handleData(data, index, color = "#FFAA54") {
        //index标识第几层
        return data.map((item, index2) => {
          //计算出颜色
          if (index == 1) {
            color = colors.find((item, eq) => eq == index2 % 10);
          }
          // 设置节点大小
          if (index === 0 || index === 1) {
            item.label = {
              position: "inside"
              //   rotate: 0,
              //   borderRadius: "50%",
            };
          }
          // 设置label大小
          switch (index) {
            case 0:
              item.symbolSize = 50;
              break;
            case 1:
              item.symbolSize = 30;
              break;
            default:
              item.symbolSize = 20;
              break;
          }
          // 设置线条颜色
          item.lineStyle = {
            color: color
          };

          if (item.children) {
            //存在子节点
            item.itemStyle = {
              borderColor: color,
              color: color
            };
            item.children = handle(item.children, index + 1, color);
          } else {
            //不存在
            item.itemStyle = {
              color: "transparent",
              borderColor: color
            };
          }
          return item;
        });
      };

      var option = {
        type: "tree",
        backgroundColor: "#FFFFFF",

        // tooltip: { //提示框
        //     trigger: "item",
        //     triggerOn: "mousemove",
        //     backgroundColor: "rgba(1,70,86,1)",
        //     borderColor: "rgba(0,246,255,1)",
        //     borderWidth: 0.5,
        //     textStyle: {
        //         fontSize: 10
        //     }
        // },
        series: [
          {
            type: "tree",
            emphasis: {
              scale: true
            }, //hoverAnimation已弃用，hover样式
            data: getdata(),
            top: 0,
            bottom: 20,
            left: 0,
            right: 0,
            layout: "radial",
            symbol: "circle",
            symbolSize: 15,
            nodePadding: 15,
            animationDurationUpdate: 750,
            expandAndCollapse: true, //子树折叠和展开的交互，默认打开
            initialTreeDepth: 5,
            roam: false, //是否开启鼠标缩放和平移漫游。scale/move/true
            focusNodeAdjacency: true,
            itemStyle: {
              borderWidth: 10
            },
            label: {
              //标签样式
              color: "#fff",
              fontSize: 10,
              fontFamily: "SourceHanSansCN",
              position: "inside",
              rotate: 0
            },
            lineStyle: {
              width: 1,
              curveness: 0.5
            }
          }
        ]
      };

      this.myChart.setOption(option);
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
    echarts3(dataEchart) {
      console.dir(dataEchart)
      let myChart = echarts.init(document.getElementById("echarts3-my"));
      var option = {
        animationDurationUpdate: function (idx) {
          // 越往后的数据延迟越大
          return idx * 100;
        },
        animationEasingUpdate: "bounceIn",
        color: ["#FF5575", "#FF965A", "#19C9B7", "#3A7FFF", "#3A7FFF"],
        series: [
          {
            type: "graph",
            layout: "force",
            roam: true, //放大缩小
            force: {
              repulsion: 300
            },
            data: dataEchart,
            categories: [
              {
                name: "全国"
              },
              {
                name: "省级"
              },
              {
                name: "市级"
              },
              {
                name: "其他"
              },
              {
                name: "区县级"
              }
            ],
            label: {
              show: true,
              // formatter: function(val) {
              //   var strs = val.data.name.split("");
              //   var str = "";
              //   for (var i = 0, s; (s = strs[i++]); ) {
              //     str += s;
              //     if (!(i % 5)) str += "\n";
              //   }
              //   return str;
              // },
              color: "black",
              fontSize: "12px"
            },
            labelLayout: {
              hideOverlap: true
            },
            scaleLimit: {
              min: 0.4,
              max: 2
            }
          }
        ]
      };
      setTimeout(() => {
        myChart.setOption(option);
      }, 500);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    async topicSampleList() {
      const res = await topicSampleList({
        topicId: this.id
      });
      if (res.data.rows) {
        this.options = res.data.rows;
        this.sampling = res.data.rows[0];
        if (res.data.rows.length != 0) {
          this.optionsChange(res.data.rows[0]);
        }
      }
    },
    // 选择抽样的时候改变事件 切换观点列表
    async optionsChange(val) {
      this.veiwListLoading = true;
      this.$store.state.sampling = this.sampling;
      this.samplingName = val.createTime + " " + val.remark;
      this.samplingCount = val.sampleName;
      this.samplingiD = val.sampleId;
      const res = await addViewList({
        sampleId: val.sampleId,
        topicId: this.id,
        totalNum: val.sampleName
      });
      if (res.data.rows) {
        this.viewPointList = res.data.rows;
        if (res.data.rows.length != 0) {
          this.radio2 = res.data.rows[0];
          this.radioChange(res.data.rows[0]);
        }
      }
      this.veiwListLoading = false;
    },
    // 选择观点的时候 切换右边评论列表
    async radioChange(val) {
      this.$store.state.radio2 = val;
      this.loadingContent = true;
      this.pageSize = val.totalNum;
      let data = {
        topicId: this.id,
        sampleId: this.samplingiD,
        viewpointIds: val.viewpointId,
        isSetSample: 1
      };
      const res = await commentList(
        data,
        `?pageNum=1&pageSize=${this.pageSize}`
      );
      if (res.data.rows && res.data.rows.length != 0) {
        this.commitList = res.data.rows;
        // this.viewPointList.viewpointId
        this.commitList.forEach(item => {
          this.viewPointList.forEach(it => {
            if (item.viewpointId == it.viewpointId) {
              item.viewName = it.viewName;
            }
          });
        });
      } else {
        this.commitList = [];
      }
      this.loadingContent = false;
      this.$store.state.dataListTopic.netizensOne = "";
      this.$store.state.dataListTopic.sampleOne = "";
      this.$nextTick(() => {
        html2canvas(document.querySelector("#netizenView")).then(resolve => {
          let imgUrl = resolve.toDataURL("image/png");
          this.$store.state.dataListTopic.netizensOne = imgUrl;
          // viewPoint-right
        });
        if (this.commitList.length != 0) {
          html2canvas(document.querySelector(".viewPoint-right")).then(
            resolve => {
              let imgUrl = resolve.toDataURL("image/png");
              this.$store.state.dataListTopic.sampleOne = imgUrl;
            }
          );
        }
      });
    },
    getFocus() {//获取重点媒体饼图数据
      this.$http({
        method: "get",
        url: "api/topic/viewPoint/keyMedia",
        params: {
          topicId: this.id
        }
      }).then(res => {
        if (res.data.code == 200) {
          let data = res.data.data;
          data.forEach(x => {
            switch (x.levels) {
              case "全部数据":
                this.focusMediaData.data[0] = x.total;
                break;
              case "央级":
                this.focusMediaData.data[1] = x.total;
                break;
              case "省级":
                this.focusMediaData.data[2] = x.total;
                break;
              case "市级":
                this.focusMediaData.data[3] = x.total;
                break;
              case "总数据":
                this.focusMediaData.num = x.total;
                break;
            }
          });
          if (this.focusMediaData.data[0] > 0) {
            this.focusMediaData.show = true;
            this.focusMediaData.data[4] = this.focusMediaData.data[0] - this.focusMediaData.data[1] - this.focusMediaData.data[2] - this.focusMediaData.data[3];
            this.getFocusMedia();
            this.$nextTick(() => {
              this.focusMediaData.data.forEach((x, i) => {
                if (i != 0) {
                  this.creatEcharts(i, Math.round(x / this.focusMediaData.data[0] * 100));
                }
              })
            });
          }
        }
      });
    },
    getFocusMedia(val) {//获取重点媒体表格数据
      let data = {
        topicId: this.id
      }
      if (val != undefined) {
        data.levels = val;
      }
      this.$http({
        method: "post",
        url: "api/topic/viewPoint/distribution",
        data: data
      }).then(res => {
        if (res.data.code == 200) {
          this.focusMediaData.table = res.data.data;
          if (val != undefined) {
            this.focusMediaData.table.forEach(x => {
              x.levels = val;
            });
          }
        }
      });
    },
    creatEcharts(id, value) {
      let data = {}
      switch (id) {
        // case 0:
        // data = {
        //   color:"#fb3381",
        //   value:value,
        //   id:"zb_topics_focus_echarts1"
        // }
        // break;
        case 1:
          data = {
            color: "#00b5a2",
            value: value,
            id: "zb_topics_focus_echarts2"
          }
          break;
        case 2:
          data = {
            color: "#ffa30d",
            value: value,
            id: "zb_topics_focus_echarts3"
          }
          break;
        case 3:
          data = {
            color: "#315ffd",
            value: value,
            id: "zb_topics_focus_echarts4"
          }
          break;
        case 4:
          data = {
            color: "#a33ffb",
            value: value,
            id: "zb_topics_focus_echarts5"
          }
          break;
      }
      let myChart = echarts.init(document.getElementById(data.id));
      let option = {
        polar: {
          radius: ["70%", "80%"],
        },
        title: [
          {
            text: data.value + "%",
            x: "center",
            y: "center",
            textStyle: {
              fontSize: "20",
              color: data.color,
            },
          },
        ],
        angleAxis: {
          max: 100,
          show: false,
          axisLabel: {
            show: false,
          }
        },
        radiusAxis: {
          type: "category",
          show: false,
          axisLabel: {
            show: false
          }
        },
        series: [
          {
            type: "bar",
            roundCap: true,
            barWidth: 60,
            showBackground: true,
            backgroundStyle: {
              color: "white",
            },
            data: [data.value],
            coordinateSystem: "polar",
            itemStyle: {
              normal: {
                color: data.color
              },
            },
          }
        ]
      }
      myChart.setOption(option);
    },
    //生成信息地域地图
    createdInfoMap() {
      ascription({ topicId: this.id + "" }).then(res => {
        let data = {
          region: [],
          value: [],
          average: [],
        };
        let mapData = [];
        let sum = 0;
        let value = res.data.data;
        let shi = [
          "北京市",
          "天津市",
          "上海市",
          "重庆市"
        ];
        if (res.data.code == 200) {
          value.forEach(x => {
            let j = false;
            let k = false;
            shi.forEach(y => {
              if (y.indexOf(x.mediaLink) != -1) {
                j = true;
                k = y === x.mediaLink;
                x.mediaLink = k ? x.mediaLink : x.mediaLink + "市";
              }
            })
            if (j) {
              if (mapData[x.mediaLink]) {
                mapData[x.mediaLink].value += x.count;
              } else {
                mapData.push({
                  name: x.mediaLink,
                  value: x.count
                })
              }
            } else if (x.mediaLink.indexOf("省") != -1) {
              let judge = true;
              mapData.forEach(y => {
                if (x.mediaLink == y.name) {
                  judge = false;
                  y.value += x.count;
                }
              })
              if (judge) {
                mapData.push({
                  name: x.mediaLink,
                  value: x.count
                })
              }
            } else {
              let judge = true;
              mapData.forEach(y => {
                if ((x.mediaLink + "省") == y.name) {
                  judge = false;
                  y.value += x.count;
                }
              })
              if (judge) {
                mapData.push({
                  name: x.mediaLink + "省",
                  value: x.count
                })
              }
            }
          });
          mapData.sort(function (a, b) { return b.value - a.value })
          value.forEach(x => {
            data.region.push(x.province);
            data.value.push(x.count);
            sum += x.count;
          });
          data.value.forEach(() => {
            data.average.push(sum / data.value.length);
          });
          if (data.region.length >= 10) {
            data.region.splice(10);
          } else {
            while (data.region.length < 10) {
              data.region.push("");
            }
          }
        }
        mapData.forEach(x => {
          if (x.name === "新疆省") {
            x.name = "新疆自治区";
          }
          if (x.name === "宁夏省") {
            x.name = "宁夏自治区";
          }
          if (x.name === "西藏省") {
            x.name = "西藏自治区";
          }
          if (x.name === "广西省") {
            x.name = "广西自治区";
          }
          if (x.name === "内蒙古省") {
            x.name = "内蒙古自治区";
          }
        })
        echarts.registerMap("China", map);
        let myChart = echarts.init(document.getElementById("infoMap"));
        let option = {
          title: [
            {
              show: true,
              text: 'TOP 10 排行榜',
              right: '40',
              textStyle: { color: '#666666', fontSize: 12 }
            }
          ],
          tooltip: {
            trigger: "item",
            backgroundColor: "rgba(255, 255, 255, 0.98)",
            borderWidth: 0,
            formatter: function (params) {
              if (params.data == undefined) {
                return "";
              }
              let str = `<div>
              <p style="width:200px;display:flex;justify-content: space-between;">
              <span style="font-size: 14px;font-weight: 550;color: #2E59EC;">${params.data.name}</span>
              <span style="font-size: 14px;font-weight: 550;color: #2E59EC;">${params.data.value}</span>
              </p></div>`;
              return str;
            }
          },
          legend: { show: false },
          grid: {
            show: true,
            containLabel: false,
            right: 40,
            top: 50,
            bottom: 30,
            width: '20%',
          },
          visualMap: {
            type: 'continuous',
            min: 0,
            max: mapData.length > 0 ? mapData[0].value : 1,
            text: ['多', '少'],
            seriesIndex: [0, 2],
            dimension: 0,
            realtime: false,
            left: 50,
            bottom: 50,
            itemWidth: 18,
            itemHeight: 100,
            calculable: true,
            inRange: {
              color: [
                "#e6ecfc",
                "#addbff",
                "#5cb8ff",
                "#6fa5ff",
                "#3779ff",
                "#3b64f1"
              ]
            },
            outOfRange: {
              color: ['#eeeeee'],
            },
          },
          toolbox: {
            show: false,
          },
          xAxis: [{
            type: 'value',
            position: 'top',
            inside: false,
            axisLabel: { show: false },
            splitLine: { show: false },
            margin: 10,
          }],
          yAxis: [{
            type: 'category',
            boundaryGap: true,
            axisLine: { show: false },
            axisLabel: { align: 'right', margin: 10, showMaxLabel: true },
            inverse: true,
            data: data.region,
          }],
          series: [
            {
              name: '占比',
              type: 'map',
              mapType: 'China',
              left: '100',
              width: '60%',
              mapValueCalculation: 'sum',
              zoom: 1,
              top: 10,
              selectedMode: false,
              showLegendSymbol: false,
              label: {
                normal: { show: true, color: "#fff", fontSize: 9 },
                emphasis: { show: true, color: "#fff" },
              },
              itemStyle: {
                normal: { areaColor: "#e6ecfc", borderColor: "#fff" },
                emphasis: { areaColor: '#3b64f1', borderWidth: 2 },
              },
              data: mapData,
            },
            {
              name: '背景',
              type: 'bar',
              roam: false,
              visualMap: false,
              itemStyle: { color: '#eeeeee', opacity: 0.5 },
              label: { show: false },
              emphasis: {
                itemStyle: { color: '#eeeeee', opacity: 0.5 },
                label: { show: false },
              },
              silent: true,
              barWidth: 18,
              barGap: '-100%',
              data: data.average,
              animation: false,
            },
            {
              name: '占比',
              type: 'bar',
              roam: false,
              visualMap: false,
              barWidth: 18,
              label: {
                normal: { show: true, fontSize: 12, position: 'insideLeft' },
                emphasis: { show: true, },
              },
              tooltip: {
                show: false
              },
              data: data.value,
            },
          ],
        };
        myChart.setOption(option);
        window.onresize = function () {
          myChart.resize();
        };
      })
    }
  }
};
</script>

<style lang='scss' scoped>
.box2 {
  .box2-div1 {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    position: relative;

    .box2-div1-top {
      display: flex;
      align-items: center;
      padding: 24px 16px;

      div {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        color: #2e59ec;
        margin-left: 24px;
        cursor: pointer;
      }
    }

    .box2-div1-tag {
      width: 100%;
      height: 54px;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      font-size: 16px;
      color: #333333;
      margin-bottom: 24px;
      border-bottom: 1px solid #e4e6ec;

      .Selectli {
        line-height: 54px;
        margin-right: 80px;
        color: #2e59ec;
        border-bottom: 2px solid #2e59ec;
        cursor: pointer;
        font-weight: bold;
      }

      .NoSelect {
        line-height: 54px;
        margin-right: 80px;
        cursor: pointer;
      }
    }

    #mapEcharts {
      width: 100%;
      height: 800px;
    }
  }

  .box2-div2 {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 16px;

    .box2-div2-top {
      display: flex;
      align-items: center;
      padding: 24px 16px;
    }

    .img-box {
      display: flex;
      align-items: center;
    }

    .box2-div2-center {
      display: flex;

      .box2-div2-center-left {
        background: #ffffff;
        padding-left: 44px;
        padding-bottom: 25px;
        width: 868px;

        ul {
          background: #ffffff;
          width: 100%;
          border: 1px solid #e4e6ec;

          li {
            display: flex;
            height: 40px;
            line-height: 40px;
            text-align: center;
            cursor: pointer;

            .li-left {
              width: 161px;
              background: #f5f6fa;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              border-right: 1px solid #e4e6ec;
              font-size: 14px;
              font-weight: bold;
              color: #333333;
            }

            .li-center {
              width: 120px;
              background: #f5f6fa;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              border-right: 1px solid #e4e6ec;
              font-size: 14px;
              font-weight: bold;
              color: #333333;
            }

            .li-centerNum {
              width: 80px;
              font-size: 14px;
              color: #333333;
              font-weight: bold;
              background: #f5f6fa;
            }

            .redioText {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .li-right {
              width: 430px;
              background: #f5f6fa;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding: 0 32px;
              font-size: 14px;
              font-weight: bold;
              color: #333333;
            }

            .li-left1 {
              width: 161px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              border-right: 1px solid #e4e6ec;
              padding-right: 8px;
              font-size: 14px;
              color: #333333;
            }

            .li-left1 .el-radio {
              width: 100%;
            }

            .li-left1 .el-radio .el-radio__label {
              width: 100%;
            }

            .li-center1 {
              width: 120px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              border-right: 1px solid #e4e6ec;
              font-size: 14px;
              color: #333333;
            }

            .li-center2 {
              width: 80px;
              font-size: 14px;
              color: #333333;
            }

            .li-right1 {
              width: 430px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding: 0 32px;
              font-size: 14px;
              color: #333333;
            }
          }

          .itemLi {
            color: #2e59ec;
          }
        }
      }

      #echarts2 {
        height: 230px;
        width: 100%;
      }
    }
  }

  .box2-div3 {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 16px;
    padding-bottom: 30px;

    .box2-div2-top {
      display: flex;
      align-items: center;
      padding: 24px 16px;
    }

    .box2-div2-center {
      display: flex;

      .box2-div2-center-left {
        padding: 0 60px 0px 120px;

        .el-radio-group {
          width: 100%;
        }

        .el-radio {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        ul {
          border-top: 1px solid #e4e6ec;
          border-left: 1px solid #e4e6ec;
          border-bottom: 1px solid #e4e6ec;
          background: #ffffff;

          li {
            display: flex;
            align-items: center;
            padding: 0 0 0 18px;
            height: 40px;
            line-height: 40px;
            border-right: 1px solid #e4e6ec;
          }
        }
      }

      #echarts3-my {
        width: 60%;
        height: 320px;
      }
    }
  }
}

.analyse-container {
  width: 100%;
  height: 250px;
  background: #ffffff;
  border-radius: 4px;
  /* margin-top: 16px; */
  text-align: center;
}

.analyse-container-img {
  position: relative;
  top: 40%;
  transform: translateY(-50%);
}

.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}

.viewPoint-left-text {
  width: 100%;
  overflow: hidden;
  margin-bottom: 16px;
}

.viewPoint_text {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.viewPoint-right-content {
  width: 1040px;
  max-height: 475px;
  overflow-y: auto;
  border: 1px solid #e4e6ec;
  padding: 20px;
  margin-top: 16px;
  padding-bottom: 0;
}

.viewPoint-div {
  width: 100%;
  border-bottom: 1px solid #e4e6ec;
  margin-bottom: 20px;
}

.viewPoint-tag,
.viewPoint-top-text,
.viewPoint-top-font {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.viewPoint-tag {
  min-width: 72px;
  height: 24px;
  background: #36cbcb;
  text-align: center;
  line-height: 24px;
  color: #fff;
  font-size: 14px;
}

.viewPoint-top-text {
  color: #333;
  font-size: 14px;
}

.viewPoint-top-font {
  color: #333;
  font-size: 14px;
}

.viewPoint-right-center {
  margin: 16px 0;
}

.viewPoint-right-content-text {
  font-size: 16px;
  color: #666666;
  margin-right: 8px;
}

.viewPoint-right-content-font {
  font-size: 16px;
  color: #333;
}

.viewPoint-right-content-font:hover {
  color: #2e59ec;
  cursor: pointer;
}

.viewPoint-right-bottom {
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.viewPoint-right-bottom-text {
  color: #b8bcc3;
  font-size: 14px;
  float: left;
}

.viewPoint-right-bottom-font {
  color: #b8bcc3;
  font-size: 14px;
  float: right;
}

.viewPoint-right {
  margin-left: 30px;
  margin-bottom: 20px;
}

.viewPoint-right-font {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.mediaView-div1 {
  width: 294px;
  height: 40px;
  border-right: 1px solid rgb(228, 230, 236);
  background: #f5f6fa;
}

.mediaView-div2 {
  width: 120px;
  height: 40px;
  border-right: 1px solid rgb(228, 230, 236);
  background: #f5f6fa;
  text-align: center;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
}

.mediaView-div3 {
  height: 40px;
  background: #f5f6fa;
  text-align: center;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  width: 80px;
}

.mediaView-div4 {
  width: 276px;
  border-right: 1px solid #e4e6ec;
}

.mediaView-div5 {
  width: 120px;
  border-right: 1px solid #e4e6ec;
  text-align: center;
}

.mediaView-div6 {
  width: 80px;
  text-align: center;
}

.loadingContent-box {
  min-width: 1000px;
  display: flex;
  justify-content: center;
}

.zb_topics_title {
  display: flex;
  align-items: center;
  padding: 24px 16px;
}

.zb_topics_title span {
  font-size: 14px;
  color: #7e7e7e;
  vertical-align: bottom;
  margin-left: 20px;
}

.zb_topics_title i {
  color: #2e59ec;
  font-style: normal;
}

#zb_topics_focus {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  padding-bottom: 30px;
}

#zb_topics_media {
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
}

#zb_topics_media>div {
  width: 300px;
  height: 120px;
  display: flex;
  justify-content: space-around;
  border-radius: 7px;
  cursor: pointer;
}

.zb_topics_focus_echarts {
  width: 120px;
  height: 120px;
}

.zb_topics_focus_num h1 {
  margin-top: 30px;
}

.zb_topics_focus_num span {
  font-size: 15px;
  margin-top: 15px;
}

#zb_topics_focus_table {
  width: calc(100% - 48px);
  margin: 25px auto 0;
  border: 1px solid #e4e6ec;
  border-collapse: collapse;
  table-layout: fixed;
  text-align: center;
  font-size: 14px;
  color: #373737;
}

#zb_topics_focus_table tr,
#zb_topics_focus_table td,
#zb_topics_focus_table th {
  border-left: 1px solid #e4e6ec;
  border-right: 1px solid #e4e6ec;
}

#zb_topics_focus_table tr {
  height: 40px;
}

#zb_topics_focus_table tr:hover {
  background-color: #f5f6fa;
}

#infoMap {
  width: 100%;
  height: 800px;
}

.zb_topics_box {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  padding-bottom: 30px;
  position: relative;
}

.map {
  position: absolute;
  bottom: 100px;
  right: 600px;
}
</style>
